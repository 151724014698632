import React, { useMemo } from "react";
import RichTextEditor from "./RichTextEditor";
import GroupeForm from "../../_Common/GroupeForm";
import { customSection } from "../../../types";
import { Translate } from "../../../utils/lang/translate";
import { DeletSvg } from "../_Common/IconSvg";

interface Props {
  sections: customSection[];
  addSection: () => void;
  deleteSection: (key: number) => void;
  editSectionContent: (content: string, key: number) => void;
  editSectionTitle: (title: string, key: number) => void;
}

const SectionList = ({
  sections,
  addSection,
  deleteSection,
  editSectionContent,
  editSectionTitle,
}: Props) => {
  const sectionsDisplay = useMemo(
    () =>
      sections.map((section, key) => (
        <div key={key} className="SectionList-section RlPs">
          <div style={{ width: "calc(100% - 27px)" }}>
            <GroupeForm
              id={`section-title-${key}`}
              name={`section-title-${key}`}
              placeholder={Translate("shop", "sectionPlaceholder")}
              text={Translate("shop", "sectionTitle")}
              type="text"
              value={section.title}
              workfun={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                const { value } = event.target as HTMLInputElement;
                editSectionTitle(value, key);
              }}
              stclass={!section.title ? "borderError" : ""}
              required
            />
          </div>
          <div
            className="SectionList-delete"
            onClick={() => {
              deleteSection(key);
            }}
          >
            {DeletSvg}
          </div>
          <RichTextEditor
            title={Translate("shop", "content")}
            id={`section-content-${key}`}
            value={section.content}
            setValue={(content) => editSectionContent(content, key)}
            isError={!section.content}
          />
        </div>
      )),
    [sections]
  );
  return (
    <div className="SectionList">
      {sectionsDisplay}
      {sections.length < 2 && (
        <div className="addOptionButton" onClick={addSection}>
          + {Translate("shop", "addSection")}
        </div>
      )}
    </div>
  );
};

export default SectionList;

import React, { useMemo, useState } from "react";
import GroupeForm from "../../_Common/GroupeForm";
import { CloseSvg } from "../../StorePages/_Common/IconSvg";
import Button from "../../_Common/Button";
import { PricingOption, WilayaPricing } from "../../../types";
import { Translate } from "../../../utils/lang/translate";

interface Props {
  pricingOption: PricingOption;
  setPricingOption: (newPricingOption: PricingOption) => void;
  defaultPricing: WilayaPricing[];
  onClose: () => void;
  loading?: boolean;
  disabled?: boolean;
  onSave: () => void;
}

const PricingOptionForm: React.FC<Props> = ({
  pricingOption,
  setPricingOption,
  defaultPricing,
  onClose,
  loading,
  disabled,
  onSave,
}) => {
  const handleNameChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { value } = e.target as HTMLInputElement;
    setPricingOption({ ...pricingOption, name: value });
  };

  const handlePricingChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    wilayaNumber: number
  ) => {
    if (pricingOption.pricings) {
      const { value } = e.target as HTMLInputElement;
      if (value !== "") {
        let isOptionFound = false;
        let newPricings = pricingOption.pricings.map((pr) => {
          if (pr.wilaya === wilayaNumber) {
            isOptionFound = true;
            return { ...pr, price: value };
          }
          return pr;
        });
        if (!isOptionFound)
          newPricings.push({ wilaya: wilayaNumber, price: value });
        setPricingOption({
          ...pricingOption,
          pricings: newPricings,
        });
      } else {
        setPricingOption({
          ...pricingOption,
          pricings: pricingOption.pricings.filter(
            (pr) => pr.wilaya !== wilayaNumber
          ),
        });
      }
    }
  };

  const tbody_elem = useMemo(() => {
    return defaultPricing.map((pr) => {
      const price = pricingOption.pricings?.find(
        (pr2) => pr2.wilaya === pr.wilaya
      )?.price;
      return [
        pr.wilaya > 58 ? pr.wilaya - 58 : pr.wilaya,
        Translate("wilayas", pr.wilaya),
        parseInt(pr.price),
        <GroupeForm
          id={`pricing-${pr.wilaya}`}
          name={`pricing-${pr.wilaya}`}
          type="number"
          min={0}
          value={price ? parseInt(price) : undefined}
          workfun={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            handlePricingChange(e, pr.wilaya);
          }}
          required
        />,
      ];
    });
  }, [defaultPricing, pricingOption]);

  return (
    <>
      <div className="PricingOptionForm">
        <div className="head">
          <h3>{Translate("shop", "pricingOptionFormTitle")}</h3>
          <div
            onClick={onClose}
            style={{
              cursor: "pointer",
              pointerEvents: loading ? "none" : "auto",
              opacity: loading ? "0.6" : "1",
              transform: "scale(0.8)",
            }}
          >
            {CloseSvg}
          </div>
        </div>
        <div className="body">
          <GroupeForm
            id="name"
            name="name"
            placeholder={Translate("shop", "pricingOptionName")}
            text={Translate("shop", "pricingOptionName")}
            type="text"
            value={pricingOption.name}
            workfun={handleNameChange}
            required
          />
          <div className="tableField">
            <p>{Translate("shop", "pricing")}</p>
            <div className="table">
              <div className="thead">
                <div className="tr">
                  <div className="th">{Translate("shop", "wilayaCode")}</div>
                  <div className="th">{Translate("shop", "location")}</div>
                  <div className="th">
                    {Translate("shop", "defaultPricing")}
                  </div>
                  <div className="th">{Translate("shop", "yourPricing")}</div>
                </div>
              </div>
              <div className="tbody">
                {tbody_elem.map((row, key) => (
                  <div className="tr" key={key}>
                    {row.map((column, key) => (
                      <div className="th" key={key}>
                        {column}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="button-container">
            <Button
              BtnText={Translate("shop", "save")}
              style={{ width: "200px" }}
              disabled={disabled}
              onClick={() => {
                if (!disabled) onSave();
              }}
            />
          </div>
        </div>
      </div>
      <div className="PricingOptionForm-overlay" />
    </>
  );
};

export default PricingOptionForm;

import { commonConstants } from "../constants";
import { RequestService, FunctionService } from "../services";
import Cookies from "js-cookie";
const token = Cookies.get("auth");
const store_id = Cookies.get("c_store");
const lang = Cookies.get("lang");

export const commonActions = {
  Getwilayas,
  Getcommunes,
  GetWarehouse,
  GetVehiculeTypes,
  ClearStore,
  GetDelivery,
  GetCountry,
  GetInfoMaystro,
};

function GetCountry() {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_COUNTRY_REQUEST));
    RequestService.GetRequest("shared/countries/")
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, commonConstants.GET_COUNTRY_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_COUNTRY_FAILURE
          )
        );
      });
  };
}

function Getwilayas(country_id = 1) {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_WILAYAS_REQUEST));
    RequestService.GetRequest(`shared/wilayas/?country_id=${country_id}`, {})
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, commonConstants.GET_WILAYAS_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_WILAYAS_FAILURE
          )
        );
      });
  };
}

function Getcommunes(params) {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_COMMUNE_REQUEST));
    RequestService.GetRequest(`shared/communes/${params}`, {})
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, commonConstants.GET_COMMUNE_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_COMMUNE_FAILURE
          )
        );
      });
  };
}
function GetWarehouse() {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_WAREHOUSE_REQUEST));
    RequestService.GetRequest("stores/add_warehouse/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            commonConstants.GET_WAREHOUSE_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_WAREHOUSE_FAILURE
          )
        );
      });
  };
}
function GetVehiculeTypes() {
  return (dispatch) => {
    dispatch(
      FunctionService.request(commonConstants.GET_VEHICULETYPES_REQUEST)
    );
    RequestService.GetRequest("shared/vehicle_types/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            commonConstants.GET_VEHICULETYPES_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_VEHICULETYPES_FAILURE
          )
        );
      });
  };
}
function ClearStore(DeletConst) {
  return (dispatch) => {
    dispatch(clear(DeletConst));
  };
  function clear(DeletConst) {
    return {
      type: DeletConst,
      payload: {},
    };
  }
}

function GetDelivery(commune, express) {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_DELIVERY_REQUEST));
    let url =
      "stores/delivery_price/?commune=" + commune + "&express=" + express;
    RequestService.GetRequest(url, { Authorization: "Token " + token })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, commonConstants.GET_DELIVERY_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, commonConstants.GET_DELIVERY_FAILURE)
        );
      });
  };
}

function GetInfoMaystro(country_id) {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_INFO_REQUEST));
    RequestService.GetRequest(
      "shared/maystro_informations/?country_id=" + country_id
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, commonConstants.GET_INFO_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, commonConstants.GET_INFO_FAILURE)
        );
      });
  };
}

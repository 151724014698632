import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import {
  DataShop,
  LandingPageSettings,
  UpdateDataShopSettings,
} from "../../../../types";
import { Translate } from "../../../../utils/lang/translate";
import useExitPrompt from "../../../../utils/useExitPrompt";
import Button from "../../../_Common/Button";
import RichTextEditor from "../../ShopPage/RichTextEditor";
import { Event } from "../../_Common";

interface Props {
  dataShop: DataShop;
  UpdateLandingPageSettings: (
    landingPageSettings: LandingPageSettings,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const LandingPageCongratulation = ({
  dataShop,
  UpdateLandingPageSettings,
  SendAlert,
}: Props) => {
  const [content, setContent] = useState<string | undefined>(
    dataShop.landingPageSettings?.congratulation_message
  );
  const [isChange, setIsChange] = useExitPrompt(false);

  const handleChange = (newContent: string) => {
    setContent(newContent);
    setIsChange(true);
  };
  const handleSave = () => {
    const updatedLandingPageSettings: LandingPageSettings = {
      ...dataShop.landingPageSettings!,
      congratulation_message: content,
    };
    UpdateLandingPageSettings(updatedLandingPageSettings, dataShop, () => {
      setIsChange(false);
      SendAlert("41", Translate("shop", "changesSaved"));
    });
  };
  return (
    <>
      <Prompt when={isChange} message={Translate("alert", "prompt")} />
      <div className="LandingPageCongratulation">
        <h3>{Translate("shop", "customYourCongratulation")}</h3>
        <div style={{ marginTop: "20px" }}>
          <RichTextEditor
            title={Translate("shop", "congratulationMessage")}
            id="content"
            value={content ?? ""}
            setValue={handleChange}
          />
        </div>
        <Button
          clName="button"
          BtnText={Translate("shop", "save")}
          onClick={() => {
            Event("SHOP", "SAVE_LANDING_PAGE_CONGRATULATION", "CLICK_EVENT");
            handleSave();
          }}
          style={{
            backgroundColor: "#008AFF",
            color: "white",
            fontSize: "16px",
            width: "140px",
          }}
          disabled={!isChange}
        />
      </div>
    </>
  );
};

export default LandingPageCongratulation;

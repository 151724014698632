import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PricingOptionForm from "./PricingOptionForm";
import DeliveryPricingTable from "./DeliveryPricingTable";
import DeliveryPricingOptionSelector from "./DeliveryPricingOptionSelector";
import {
  DataShop,
  PricingOption,
  PricingOptionWithoutId,
} from "../../../types";
import { alertActions } from "../../../actions";
import { shopActions } from "../../../actions";
import { Translate } from "../../../utils/lang/translate";
import Button from "../../_Common/Button";
import { initGAPg } from "../_Common";
import ConfirmationAlert from "../../_Common/ConfirmationAlert";
import ContainerPage from "../_Common/ContainerPage";
import TableLoader from "../_Common/TableLoader";
import { Event } from "../_Common";
import "./DeliveryPricing.scss";
import { DEFAULT_PRICING_OPTION } from "../../../constants";
import { Link } from "react-router-dom";

interface Props {
  history: any;
  GetShopInfoWithPricingDetails: () => void;
  CreatePricingOption: (
    newPricingOption: PricingOptionWithoutId,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  UpdatePricingOption: (
    patchedPricingOption: PricingOption,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  DeletePricingOption: (
    deleteId: string,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  dataShop: DataShop;
  shopLoading: boolean;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const ITEMS_PER_PAGE = 10;

const INITIAL_OPTION: PricingOption = {
  id: "",
  name: "",
  pricings: [],
};

const DeliveryPricingPage: React.FC<Props> = ({
  history,
  GetShopInfoWithPricingDetails,
  CreatePricingOption,
  UpdatePricingOption,
  DeletePricingOption,
  dataShop,
  shopLoading,
  SendAlert,
}) => {
  const [pricingOptions, setPricingOptions] = useState<PricingOption[]>([]);
  const [bodyTable, setBodyTable] = useState<any[]>([]);
  const [selectedPricingOptionId, setSelectedPricingOptionId] = useState<
    string | null
  >(null);
  const [page, setPage] = useState(1);
  const [idDelete, setIdDelete] = useState<string | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [pricingOptionForm, setPricingOptionForm] = useState(INITIAL_OPTION);

  useEffect(() => {
    initGAPg();
    GetShopInfoWithPricingDetails();
  }, []);

  useEffect(() => {
    if (!shopLoading && dataShop && !dataShop.published)
      history.push("/shop/settings");
    if (!shopLoading && dataShop?.pricingOptions?.every((pr) => pr.pricings)) {
      setPricingOptions(dataShop.pricingOptions);
      setSelectedPricingOptionId(dataShop.pricingOptions[0].id);
    }
  }, [dataShop]);

  useEffect(() => {
    if (selectedPricingOptionId) {
      const selectedPricingOption = pricingOptions.find(
        (pricingGroup) => pricingGroup.id === selectedPricingOptionId
      );
      if (selectedPricingOption?.pricings) {
        setBodyTable(
          selectedPricingOption.pricings.map((pr) => [
            pr.wilaya > 58 ? pr.wilaya - 58 : pr.wilaya,
            Translate("wilayas", pr.wilaya),
            parseInt(pr.price),
          ])
        );
        setPage(1);
      }
    }
  }, [selectedPricingOptionId, pricingOptions]);

  const successCallback = () => {
    SendAlert("41", Translate("shop", "changesSaved"));
  };

  const handleDelete = (deleteGroupId: string | null) => {
    if (dataShop && deleteGroupId) {
      DeletePricingOption(deleteGroupId, dataShop, successCallback);
      setIdDelete(null);
    }
  };

  const handleFormSave = () => {
    if (pricingOptionForm.pricings) {
      const theId = pricingOptionForm.id;
      if (theId === "") {
        Event("SHOP", "CREATE_PRICING_OPTION_SAVE", "CLICK_EVENT");
        const newPricingOption: PricingOptionWithoutId = {
          name: pricingOptionForm.name,
          pricings: pricingOptionForm.pricings,
        };
        CreatePricingOption(newPricingOption, dataShop, successCallback);
      } else {
        Event("SHOP", "EDIT_PRICING_OPTION_SAVE", "CLICK_EVENT");
        UpdatePricingOption(pricingOptionForm, dataShop, successCallback);
      }
      setIsFormOpen(false);
    }
  };

  const handleModifyFormOpening = (pr: PricingOption) => {
    Event("SHOP", "EDIT_PRICING_OPTION_BUTTON", "CLICK_EVENT");
    setPricingOptionForm(pr);
    setIsFormOpen(true);
  };

  const handleTopButtonClick = () => {
    Event("SHOP", "CREATE_PRICING_OPTION_BUTTON", "CLICK_EVENT");
    if (pricingOptionForm.id !== "") setPricingOptionForm(INITIAL_OPTION);
    setIsFormOpen(true);
  };

  const tableItemsCount = bodyTable.length;
  const slicedBodyTable = useMemo(
    () => bodyTable.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE),
    [bodyTable, page]
  );
  const isFormSavingDisabled = useMemo(
    () =>
      pricingOptionForm.name === "" ||
      pricingOptions.some(
        (pr) =>
          pr.name === pricingOptionForm.name && pr.id !== pricingOptionForm.id
      ),
    [pricingOptions, pricingOptionForm.name]
  );

  const defaultPricingOption = useMemo(
    () =>
      dataShop?.pricingOptions?.find((pr) => pr.id === DEFAULT_PRICING_OPTION)
        ?.pricings,
    [dataShop]
  );

  return (
    <ContainerPage
      page_title={
        <div>
          <span className="StOpcVal">{Translate("titles", "shop")}</span>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="CrsPoi">{Translate("shop", "deliveryPricing")}</span>
        </div>
      }
      data_top={
        !shopLoading &&
        pricingOptions.length < 10 && (
          <Button
            clName="bundle-button"
            BtnText={Translate("shop", "createPricingOption")}
            onClick={handleTopButtonClick}
            style={{
              backgroundColor: "#008AFF",
              color: "white",
              fontSize: "16px",
            }}
          />
        )
      }
    >
      {shopLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : (
        <div className="DeliveryPricingPage">
          <div>
            <DeliveryPricingOptionSelector
              pricingOptions={pricingOptions}
              selectedId={selectedPricingOptionId}
              handleSelectorClick={setSelectedPricingOptionId}
              handleDeleteButton={setIdDelete}
              handleModifyButton={handleModifyFormOpening}
            />
          </div>
          <div>
            <DeliveryPricingTable
              tbody_elem={slicedBodyTable}
              count={tableItemsCount}
              page={page}
              setPage={setPage}
              itemsPerPage={ITEMS_PER_PAGE}
            />
          </div>
          {idDelete && (
            <ConfirmationAlert
              title={Translate("shop", "deletePricingOption")}
              text={Translate("shop", "deletePricingOptionConfirmation")}
              onClose={() => {
                Event("SHOP", "DELETE_PRICING_OPTION_CANCEL", "CLICK_EVENT");
                setIdDelete(null);
              }}
              onConfirm={() => {
                Event("SHOP", "DELETE_PRICING_OPTION_CONFIRM", "CLICK_EVENT");
                handleDelete(idDelete);
              }}
              loading={shopLoading}
              customConfirmText={Translate("profile", "delete")}
              customCloseText={Translate("profile", "cancel")}
              customConfirmStyle={{ backgroundColor: "#FF4F4F" }}
              customCloseStyle={{
                backgroundColor: "var(--bg-select)",
                border: "none",
              }}
            />
          )}
          {isFormOpen && defaultPricingOption && (
            <PricingOptionForm
              pricingOption={pricingOptionForm}
              setPricingOption={setPricingOptionForm}
              defaultPricing={defaultPricingOption}
              onClose={() => {
                setIsFormOpen(false);
              }}
              loading={shopLoading}
              disabled={isFormSavingDisabled}
              onSave={handleFormSave}
            />
          )}
        </div>
      )}
    </ContainerPage>
  );
};

function mapState(state: any) {
  const { dataShop, shopLoading } = state.shop;
  return {
    dataShop,
    shopLoading,
  };
}

const actionCreators = {
  GetShopInfoWithPricingDetails: shopActions.GetShopInfoWithPricingDetails,
  CreatePricingOption: shopActions.CreatePricingOption,
  UpdatePricingOption: shopActions.UpdatePricingOption,
  DeletePricingOption: shopActions.DeletePricingOption,
  DeleteBundle: shopActions.DeleteBundle,
  SendAlert: alertActions.SendAlert,
};

export default connect(mapState, actionCreators)(DeliveryPricingPage);

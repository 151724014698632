import React from "react";
import Table from "../_Common/Table";
import { Translate } from "../../../utils/lang/translate";
import PaginationBar from "../_Common/PaginationBar";

const DeliveryPricingTable: React.FC<{
  tbody_elem: any[];
  count: number;
  page: number;
  itemsPerPage?: number;
  setPage: (newPage: number) => void;
}> = ({ tbody_elem, count, page, setPage, itemsPerPage = 10 }) => {
  return (
    <>
      <Table
        thead_elem={[
          Translate("shop", "wilayaCode"),
          Translate("shop", "location"),
          Translate("shop", "pricing"),
        ]}
        tbody_elem={tbody_elem}
      />
      {count > itemsPerPage && (
        <div role="pagination" className="lytWdp SmMarS1">
          <div className="InFlx flxDrc FlWd RlPs AlgnItm">
            <span>
              {Translate("orders", "showing") +
                " " +
                (page * itemsPerPage - (itemsPerPage - 1)) +
                " - " +
                (page * itemsPerPage < count ? page * itemsPerPage : count) +
                " " +
                Translate("orders", "of") +
                " " +
                count}
            </span>
            <PaginationBar
              className="StAutMr"
              NbPage={Math.ceil(count / itemsPerPage)}
              currentPage={page}
              ChangePage={(newPage: number) => setPage(newPage)}
              blockNext={page * itemsPerPage < count}
              blockPrev={page !== 1}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DeliveryPricingTable;

import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../actions";
import GroupeForm from "../../_Common/GroupeForm";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import Loader from "../../_Common/Loader";
import HeadLog from "../_Common/HeadLog";
import ContainerLog from "../_Common/ContainerLog";
import Button from "../../_Common/Button";
import { Link, Redirect } from "react-router-dom";
import { Translate } from "../../../utils/lang/translate";
import Cookies from "js-cookie";
import { Event, initGAPg } from "../../StorePages/_Common";
import {
  eye_hide_password_Icon,
  eye_show_password_Icon,
} from "../../StorePages/_Common/IconSvg";
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      remember: false,
      submited: false,
      errors: {
        username: "",
        password: "",
      },
      passwordShown: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangecheck = this.handleChangecheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clickForgotPassword = this.clickForgotPassword.bind(this);
    this.gotToSignUpPage = this.gotToSignUpPage.bind(this);
    this.togglepassword = this.togglepassword.bind(this);
  }
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "login");
    initGAPg();
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleChangecheck(e) {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
    Event("LOGIN", "USE_REMEMBER_ME", !checked ? "UNCHECK" : "CHECK");
  }
  clickForgotPassword(e) {
    Event("LOGIN", "FORGOT_PASSWORD", "CLICK_EVENT");
  }
  gotToSignUpPage(e) {
    Event("LOGIN", "DONT_HAVE_ACCOUNT", "CLICK_EVENT");
  }
  handleSubmit(e) {
    e.preventDefault();
    let valid = true,
      err = {};
    Event("LOGIN", "SUBMIT_LOGIN_INFOS", "CLICK_EVENT");
    if (!this.state.username) {
      err.username = Translate("error", "e16");
      valid = false;
    }
    if (!this.state.password) {
      err.password = Translate("error", "e17");
      valid = false;
    }
    this.setState({ errors: err });
    if (!valid) {
      Event("LOGIN", "LOGIN_INFOS_ERRORS", `[${Object.keys(err).join(",")}]`);
      return;
    }
    let user = { username: this.state.username, password: this.state.password };
    this.setState({
      submited: true,
    });
    this.props.Login(user);
  }
  togglepassword() {
    this.setState({ passwordShown: !this.state.passwordShown });
  }
  render() {
    const { loginIn, data } = this.props;
    const { submited, remember } = this.state;
    if (submited && loginIn === "1") {
      if ((data.is_enabled && data.is_verfied) || data.member_store_id) {
        var date = new Date();
        date.setDate(date.getDate() + 365);
        Cookies.set("auth", data.token, {
          expires: remember ? date : "",
        });
        Cookies.set("c_store", data.store_id, {
          expires: remember ? date : "",
        });
        window.location = "/";
      } else {
        if (!data.is_verfied) {
          return <Redirect to="/succes/register" />;
        }
      }
    }
    return (
      <ContainerLog>
        <HeadLog
          titleHead={Translate("auths", "login")}
          descHead={Translate("auths", "logintext")}
        />
        {submited && loginIn === "2" ? (
          <div style={{ color: "red" }}>{Translate("error", "e18")}</div>
        ) : (
          ""
        )}
        <form method="post" onSubmit={this.handleSubmit} className="StMarMx">
          <GroupeForm
            id={"Username"}
            name={"username"}
            placeholder={Translate("auths", "username")}
            text={Translate("auths", "username")}
            type={"text"}
            workfun={this.handleChange}
            error={this.state.errors.username}
          />
          <GroupeForm
            id={"Password"}
            name={"password"}
            placeholder={Translate("auths", "password")}
            text={Translate("auths", "password")}
            onClick={this.togglepassword}
            type={this.state.passwordShown ? "text" : "Password"}
            more={
              <Link
                tabIndex="-1"
                to="/password/reset"
                onClick={this.clickForgotPassword}
              >
                {Translate("auths", "forgetpassword")}
              </Link>
            }
            workfun={this.handleChange}
            error={this.state.errors.password}
            icon={
              this.state.passwordShown
                ? eye_hide_password_Icon
                : eye_show_password_Icon
            }
          />
          <CheckBoxForm
            id={"RememberPasword"}
            name={"remember"}
            text={Translate("auths", "rememberpassword")}
            workfun={this.handleChangecheck}
          />
          <div className="SmtGrp MrAot StMarMx">
            <Button BtnText={Translate("auths", "signin")} />
          </div>
        </form>
        <div className="StOpcVal">
          <div className="StMrg">
            <span className="StWdDst">
              {Translate("auths", "donthaveaccount")}
            </span>
            <Link
              className="blueapp stbold StWdDst"
              to="/register"
              onClick={this.gotToSignUpPage}
            >
              {Translate("auths", "createaccount")}
            </Link>
          </div>
          {/* <div className="StMrg">
                        <span>
                            {Translate("auths","signsocial")}
                        </span>
                    </div> */}
        </div>
        {loginIn === "0" && <Loader styl={{ borderRadius: "24px" }} />}
      </ContainerLog>
    );
  }
}
function mapState(state) {
  const { loginIn, data } = state.authentication;
  return { loginIn, data };
}

const actionCreators = {
  Login: userActions.Login,
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };

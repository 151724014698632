import React from "react";
import { connect } from "react-redux";
import { Translate, FormatDate } from "../../../utils/lang/translate";
import { commonActions, alertActions } from "../../../actions";
import fileDownload from "js-file-download";
import XLSX from "xlsx";
const TypeFile = {
  xls: "biff8",
  xlsx: "xlsx",
  csv: "csv",
};
class ListenerFile extends React.Component {
  componentWillReceiveProps(nextProps) {
    const { Exporting, ExportData, ExportWorkFunc } = nextProps;
    if (Exporting === "1") {
      if (ExportData.file) this.DownloadFile(ExportData);
      else if (ExportWorkFunc) ExportWorkFunc(ExportData.data);
      else this.prinXls(ExportData);
      this.props.ClearStore("CLEAR_LISTENER");
    }
    if (Exporting === "2") {
      this.props.ClearStore("CLEAR_LISTENER");
      this.props.SendAlert("50", Translate("alert", "fildexp"), "a06");
    }
  }
  DownloadFile(dataExport) {
    let { fileType, file } = dataExport;
    const todayDate = new Date().toISOString();
    const filename =
      "oreders_list_export_in_" + todayDate + "_file." + fileType;
    fileDownload(file, filename);
    this.props.SendAlert("41", Translate("alert", "orderexscc"), "a05");
  }
  prinXls(dataExport) {
    var xlsHeader = [
      Translate("product", "id"),
      Translate("product", "source"),
      Translate("orders", "customername"),
      Translate("orders", "customerphonenumber"),
      Translate("orders", "totalprice"),
      Translate("orders", "quantity"),
      Translate("titles", "product"),
      Translate("product", "wilaya"),
      Translate("orders", "district"),
      Translate("orders", "creationdate"),
    ];

    var DataFile = dataExport.data.list,
      fileType = dataExport.fileType;
    var createXLSLFormatObj = [];

    createXLSLFormatObj.push(xlsHeader);
    for (var i = 0; i < DataFile.length; i++) {
      let Element = DataFile[i];
      let products = Element.products;
      let length = products.length;
      createXLSLFormatObj.push([
        Element.display_id,
        Element.source,
        Element.customer_name,
        Element.customer_phone,
        Element.product_price,
        products[0] ? products[0].quantity : "",
        products[0] ? products[0].logistical_description : "",
        Element.wilaya,
        Element.commune,
        FormatDate(Element.ordered_at),
      ]);
      for (var l = 1; l < length; l++) {
        createXLSLFormatObj.push([
          ,
          ,
          ,
          ,
          ,
          products[l].quantity,
          products[l].logistical_description,
          ,
          ,
          ,
          ,
          ,
          ,
          ,
          ,
        ]);
      }
    }
    var todayDate = new Date().toISOString();
    var filename = "oreders_list_export_in_" + todayDate + "_file." + fileType;
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
    var wscols = [
      { width: 8.2 },
      { width: 25 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 8 },
      { width: 15 },
      { width: 15 },
      { width: 18 },
      { width: 18 },
      { width: 11 },
      { width: 18 },
      { width: 18 },
      { width: 18 },
      { width: 18 },
      { width: 30 },
    ];
    ws["!cols"] = wscols;
    XLSX.utils.book_append_sheet(wb, ws, "Orders list");

    XLSX.writeFile(wb, filename, { bookType: TypeFile[fileType] });
    this.props.SendAlert("41", Translate("alert", "orderexscc"), "a05");
  }

  render() {
    return "";
  }
}
function mapState(state) {
  const { Exporting, ExportData, ExportWorkFunc } = state.listener;
  return { Exporting, ExportData, ExportWorkFunc };
}
const actionCreators = {
  ClearStore: commonActions.ClearStore,
  SendAlert: alertActions.SendAlert,
};

const connectedListenerFile = connect(mapState, actionCreators)(ListenerFile);
export { connectedListenerFile as ListenerFile };

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { shopActions } from "../../../../actions";
import { DataShop } from "../../../../types";
import { Translate } from "../../../../utils/lang/translate";
import { LandingPageSvg } from "../../SideBar/SideBarSvg";
import { Event } from "../../_Common";
import ContainerPage from "../../_Common/ContainerPage";
import { EmptyScreen } from "../../_Common/EmptyScreen";
import { ShopSvg, SimpleFlechRightSvg } from "../../_Common/IconSvg";
import TableLoader from "../../_Common/TableLoader";
import "./ShopSettings.scss";

interface Props {
  dataShop: DataShop;
  shopLoading: boolean;
  GetShopInfo: () => void;
}

const ShopSettingMain: React.FC<Props> = ({
  dataShop,
  shopLoading,
  GetShopInfo,
}) => {
  useEffect(() => {
    GetShopInfo();
  }, []);

  return (
    <ContainerPage
      page_title={
        <div>
          <span className="StOpcVal">{Translate("titles", "shop")}</span>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="CrsPoi">{Translate("titles", "settings")}</span>
        </div>
      }
    >
      {shopLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : !dataShop?.published ? (
        <EmptyScreen
          PictShow="/assets/img/no_shop.png"
          titleGlb={Translate("shop", "noShopTitle")}
          actionBtns={[
            {
              text: Translate("shop", "setupShop"),
              to: "/shop/settings/store",
              event: () => {
                Event("SHOP", `CONFIGURE_SHOP_CALL_ACTION`, "CLICK_EVENT");
              },
            },
          ]}
        />
      ) : (
        <div className="ShopSettingMain">
          <div className="links">
            <Link
              to="/shop/settings/store"
              className="link"
              onClick={() => {
                Event("SHOP", `MAIN_SETTINGS_SHOP_BUTTON`, "CLICK_EVENT");
              }}
            >
              <div className="icon">{ShopSvg}</div>
              <div className="texts">
                <h2>{Translate("titles", "shop")}</h2>
                <p>{Translate("shop", "viewManageShop")}</p>
              </div>
              <div className="arrow">{SimpleFlechRightSvg}</div>
            </Link>
            <Link
              to="/shop/settings/header"
              className="link"
              onClick={() => {
                Event(
                  "SHOP",
                  `MAIN_SETTINGS_LANDING_PAGE_BUTTON`,
                  "CLICK_EVENT"
                );
              }}
            >
              <div className="icon">{LandingPageSvg}</div>
              <div className="texts">
                <h2>{Translate("shop", "landingPage")}</h2>
                <p>{Translate("shop", "viewManageLandingPage")}</p>
              </div>
              <div className="arrow">{SimpleFlechRightSvg}</div>
            </Link>
          </div>
        </div>
      )}
    </ContainerPage>
  );
};

function mapState(state: any) {
  const { shopLoading, dataShop, shopError } = state.shop;
  return { shopLoading, dataShop, shopError };
}

const actionCreators = {
  GetShopInfo: shopActions.GetShopInfo,
  UpdateDataShop: shopActions.UpdateDataShop,
  UploadDataShopLogo: shopActions.UploadDataShopLogo,
};

export default connect(mapState, actionCreators)(ShopSettingMain);

import React, { useMemo } from "react";
import ReactTooltip from "react-tooltip";

export default function GroupeBtn(props) {
  let wth = "calc(32px * " + props.data_btn.length + " )";

  return (
    <div className="StBrdRdSc MrAot" style={{ width: wth }}>
      {Array.isArray(props.data_btn) &&
        props.data_btn.map((elem, key) => {
          return (
            <div
              key={key}
              className={"Inlflx stBdp CrsPoi " + elem.type}
              data-tip
              data-for={`${elem.tooltip}-tooltip-${key}`}
              onClick={elem.action}
            >
              {elem.svgbtn}
              {elem.tooltip && (
                <ReactTooltip id={`${elem.tooltip}-tooltip-${key}`}>
                  {elem.tooltip}
                </ReactTooltip>
              )}
            </div>
          );
        })}
    </div>
  );
}

import React from "react";

import RewardsIcon from "../../../utils/img/Vector.png";
import ProductIcon from "../../../utils/img/drop/Product.svg";
import receiveProductIcon from "../../../utils/img/drop/receiveProd.svg";
import takenProductIcon from "../../../utils/img/drop/takenProd.svg";
import StockIcon from "../../../utils/img/drop/Stock.svg";
import flecheIcon from "../../../utils/img/drop/fleshHaut.svg";
import upLight from "../../../utils/img/drop/up.svg";
import flecheBasIcon from "../../../utils/img/drop/fleshBas.svg";
import downLight from "../../../utils/img/drop/down.svg";

import StockIconBlack from "../../../utils/img/drop/StockIcon.svg";
import ProductIconBlack from "../../../utils/img/drop/productIcon.svg";
import receiveProductIconBlack from "../../../utils/img/drop/receivedProducts.svg";
import takenProductIconBlack from "../../../utils/img/drop/takenProducts.svg";

export const DashboardSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.948 19.95"
    className="StSvgCl"
  >
    <g transform="translate(-2.056 -1.995)">
      <path
        d="M7.5,8.33H.83A.831.831,0,0,1,0,7.5V.83A.831.831,0,0,1,.83,0a7.509,7.509,0,0,1,7.5,7.5A.832.832,0,0,1,7.5,8.33ZM1.66,1.73V6.67H6.6A5.786,5.786,0,0,0,4.952,3.378,5.79,5.79,0,0,0,1.66,1.73Z"
        transform="translate(13.67 2)"
      />
      <path
        d="M19.024,10.005h-8.15a.91.91,0,0,1-.91-.91V.925a.92.92,0,0,0-1.02-.92,10,10,0,1,0,11,11,.92.92,0,0,0-.92-1Z"
        transform="translate(2.056 1.995)"
      />
    </g>
  </svg>
);
export const OrdersSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className="StSvgCl"
  >
    <g transform="translate(1.415 3.241)">
      <path
        d="M30.653,14.53H42.4a1.665,1.665,0,1,0,0-3.33H30.653a1.665,1.665,0,1,0,0,3.33Z"
        transform="translate(-22.984 -11.2)"
      />
      <path
        d="M42.4,42.627H30.653a1.666,1.666,0,1,0,0,3.333H42.4a1.666,1.666,0,0,0,0-3.333Z"
        transform="translate(-22.984 -35.87)"
      />
      <path
        d="M42.4,74.062H30.653a1.666,1.666,0,1,0,0,3.332H42.4a1.666,1.666,0,0,0,0-3.332Z"
        transform="translate(-22.984 -60.546)"
      />
      <path
        d="M3.815,11.2H2.733a1.665,1.665,0,1,0,0,3.33H3.815a1.665,1.665,0,1,0,0-3.33Z"
        transform="translate(-1.067 -11.201)"
      />
      <path
        d="M3.815,42.627H2.733a1.666,1.666,0,1,0,0,3.333H3.815a1.666,1.666,0,0,0,0-3.333Z"
        transform="translate(-1.067 -35.87)"
      />
      <path
        d="M3.815,74.062H2.733a1.666,1.666,0,1,0,0,3.332H3.815a1.666,1.666,0,0,0,0-3.332Z"
        transform="translate(-1.067 -60.546)"
      />
    </g>
  </svg>
);
export const UntOrdersSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="StSvgCl"
    viewBox="0 0 19.334 22.887"
  >
    <path
      d="M100.515,71.332h-.283c-.361,0-.667-.05-.705-.411v-.006c-.811-7.549-5.566-7.694-5.566-9.471s4.761-1.922,5.566-9.471v-.006c.039-.361.344-.411.705-.411h.283a.8.8,0,0,0,.8-.739.779.779,0,0,0-.778-.817H82.783a.8.8,0,0,0-.8.739.779.779,0,0,0,.778.817h.306c.361,0,.667.05.706.411v.006c.811,7.549,5.566,7.694,5.566,9.471s-4.761,1.922-5.566,9.471v.006c-.039.361-.344.411-.706.411h-.283a.8.8,0,0,0-.8.739.779.779,0,0,0,.778.817h17.776a.775.775,0,0,0,.778-.817A.8.8,0,0,0,100.515,71.332ZM86.5,69.432c1.594-4.433,4.422-3.894,4.422-5.644V60.7c0-1.078-2.044-1.828-3.322-3.577a.435.435,0,0,1,.361-.689h7.383a.433.433,0,0,1,.372.678c-1.255,1.755-3.339,2.511-3.339,3.589v3.089c0,1.733,2.716,1.261,4.433,5.644.156.394.039.889-.383.889H86.883C86.455,70.321,86.355,69.832,86.5,69.432Z"
      transform="translate(-81.982 -50)"
    />
  </svg>
);
export const ProductsSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="StSvgCl"
    viewBox="0 0 10.861 12.471"
  >
    <path
      d="M15.192,3.8,12.985,5.064l5.4,3.129v2.264l-.954.551V8.777L11.975,5.644,9.762,6.915v6.217l5.43,3.135,5.431-3.135V6.915Zm-1.667,8.455-1.441-.832a.615.615,0,0,1-.25-.489c0-.189.113-.28.25-.2l1.441.832a.615.615,0,0,1,.25.489C13.775,12.24,13.662,12.33,13.525,12.251Z"
      transform="translate(-9.762 -3.796)"
    />
  </svg>
);
export const PaymentsSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="StSvgCl"
    viewBox="0 0 23.601 18.42"
    style={{ transform: "scale(0.8)" }}
  >
    <g transform="translate(-2.84 -4.58)">
      <g transform="translate(2.84 4.58)">
        <path
          d="M26.783,44a3.16,3.16,0,0,1-5.633,0H9V54.937H26.928a4.023,4.023,0,0,1,2.219-6.524V44Zm-7.709,8.922a3.454,3.454,0,1,1,3.454-3.454A3.454,3.454,0,0,1,19.074,52.922Z"
          transform="translate(-9 -36.517)"
        />
        <ellipse
          cx="2"
          cy="2.5"
          rx="2"
          ry="2.5"
          transform="translate(19.16 13.42)"
        />
        <ellipse
          cx="2"
          cy="1.5"
          rx="2"
          ry="1.5"
          transform="translate(13.16 4.42)"
        />
        <g transform="translate(2.683)">
          <path
            d="M33.772,24.044h3.454v5.8a4.034,4.034,0,0,1,.5.105l1.511-8.372L19.413,18l-1.091,6.044H27.44a3.166,3.166,0,0,1,6.332,0Z"
            transform="translate(-18.322 -18)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const PickupsSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="StSvgCl"
    viewBox="0 0 29 29"
  >
    <g transform="translate(-2676 -55.269)">
      <path
        className="b"
        d="M16146.472,1938.041h-.78v-8.911h2.472a3.9,3.9,0,0,1,3.9,3.9v4.228a.78.78,0,0,1-.779.781h-1.562a1.626,1.626,0,1,1-3.252,0Zm-15.284,0h-2.406a.781.781,0,0,1-.781-.781v-14.5a.781.781,0,0,1,.781-.781h14.5a.781.781,0,0,1,.78.781v15.285h-9.626a1.626,1.626,0,1,1-3.252,0Z"
        transform="translate(-13448.813 -1860.212)"
      />
    </g>
  </svg>
);
export const ConfirmationSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="StSvgCl"
    viewBox="0 0 40 40"
  >
    <g transform="translate(-4248 -3846)">
      <path
        d="M250.666-253.96a19.525,19.525,0,0,0,8.494,8.494L262-248.3a1.282,1.282,0,0,1,1.315-.309,14.7,14.7,0,0,0,4.6.735,1.293,1.293,0,0,1,1.289,1.289v4.5a1.293,1.293,0,0,1-1.289,1.289A21.909,21.909,0,0,1,246-262.711,1.293,1.293,0,0,1,247.289-264H251.8a1.293,1.293,0,0,1,1.289,1.289,14.642,14.642,0,0,0,.735,4.6,1.294,1.294,0,0,1-.322,1.315l-2.836,2.836Z"
        transform="translate(4010.4 4118.4)"
      />
    </g>
  </svg>
);
export const SettingsSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="StSvgCl"
    viewBox="0 0 30 30"
  >
    <path
      d="M24.411,10.884a.8.8,0,0,0-.787-.6,2.7,2.7,0,0,1-1.851-4.705.68.68,0,0,0,.074-.923,12.116,12.116,0,0,0-1.94-1.959.681.681,0,0,0-.931.075,2.822,2.822,0,0,1-3.054.686A2.72,2.72,0,0,1,14.27.8a.679.679,0,0,0-.6-.715A12.224,12.224,0,0,0,10.915.074a.681.681,0,0,0-.606.7A2.724,2.724,0,0,1,8.632,3.387,2.83,2.83,0,0,1,5.6,2.7a.683.683,0,0,0-.924-.078A12.16,12.16,0,0,0,2.7,4.578a.681.681,0,0,0,.073.931,2.713,2.713,0,0,1,.685,3.055A2.831,2.831,0,0,1,.79,10.218a.666.666,0,0,0-.707.6A12.265,12.265,0,0,0,.077,13.6a.81.81,0,0,0,.8.6A2.685,2.685,0,0,1,3.4,15.884a2.723,2.723,0,0,1-.685,3.028.681.681,0,0,0-.074.923,12.16,12.16,0,0,0,1.937,1.96.681.681,0,0,0,.932-.074,2.818,2.818,0,0,1,3.052-.687,2.715,2.715,0,0,1,1.656,2.657.68.68,0,0,0,.6.715,12.188,12.188,0,0,0,2.755.007.681.681,0,0,0,.606-.7A2.721,2.721,0,0,1,15.855,21.1a2.825,2.825,0,0,1,3.032.69.684.684,0,0,0,.924.077,12.189,12.189,0,0,0,1.981-1.958.68.68,0,0,0-.073-.931,2.713,2.713,0,0,1-.686-3.054,2.741,2.741,0,0,1,2.5-1.66l.151,0a.681.681,0,0,0,.717-.6A12.258,12.258,0,0,0,24.411,10.884ZM12.263,16.354a4.084,4.084,0,1,1,4.084-4.084A4.088,4.088,0,0,1,12.263,16.354Z"
      transform="translate(2.914 2.914)"
    />
  </svg>
);
export const IssuesSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="StSvgCl"
    viewBox="0 0 30 30"
  >
    <g transform="translate(-4748 -3796)">
      <path
        d="M644-286h22l-11-19-11,19Zm12-3h-2v-2h2v2Zm0-4h-2v-4h2v4Z"
        transform="translate(4108 4106)"
      />
    </g>
  </svg>
);
export const BackupSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="StSvgCl"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <g>
          <path
            d="M368.404,361.413h-54.52V207.682c0-9.864-7.997-17.861-17.861-17.861h-80.05c-9.864,0-17.861,7.997-17.861,17.861
				v153.731h-54.52c-15.788,0-23.802,19.073-12.766,30.352l112.405,114.868c6.995,7.148,18.521,7.162,25.53,0l112.405-114.868
				C392.21,380.481,384.183,361.413,368.404,361.413z"
          />
          <path
            d="M401.439,71.31L335.36,5.231C332.01,1.881,327.468,0,322.731,0H123.189c-9.864,0-17.861,7.997-17.861,17.861v265.621
				c0,9.864,7.997,17.861,17.861,17.861h42.531c3.288,0,5.954-2.665,5.954-5.953V177.915c0-6.576,5.331-11.907,11.907-11.907
				h144.838c6.576,0,11.907,5.331,11.907,11.907v117.474c0,3.289,2.666,5.953,5.953,5.953h42.531
				c9.864,0,17.861-7.997,17.861-17.861V83.939C406.67,79.202,404.788,74.659,401.439,71.31z M298.815,94.206h-85.632
				c-6.576,0-11.907-5.331-11.907-11.907c0-6.576,5.331-11.907,11.907-11.907h85.632c6.576,0,11.907,5.331,11.907,11.907
				C310.722,88.875,305.391,94.206,298.815,94.206z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const ShopSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g id="Dev" transform="translate(-4098 -3746)">
      <rect
        id="Base"
        width="30"
        height="30"
        transform="translate(4098 3746)"
        fill="none"
      />
      <path
        id="Store"
        d="M34.019,14.053l1.876,4.818a3.664,3.664,0,0,1,.556,1.927,3.575,3.575,0,0,1-.471,1.784V32.2a.591.591,0,0,1-.591.591h-17.6A.593.593,0,0,1,17.2,32.2V22.581a3.634,3.634,0,0,1,.089-3.714L19.143,14.1a.594.594,0,0,1,.568-.425H33.47v0A.588.588,0,0,1,34.019,14.053ZM25.285,31.608c.046-1.212-.027-2.614-.027-3.791a1.626,1.626,0,1,0-3.247,0v3.791Zm9.671-11.9-1.888-4.853H30.3v4.853Zm-5.838-4.853h-5.1v4.853h5.1V14.856Zm-6.285,0H20.112L18.22,19.709h4.614V14.856ZM35.269,20.89h-5a2.509,2.509,0,0,0,4.641,1.189A2.466,2.466,0,0,0,35.269,20.89Zm-6.189,0H24.1a2.507,2.507,0,0,0,4.266,1.66A2.38,2.38,0,0,0,29.081,20.89Zm-6.177,0H17.911a2.515,2.515,0,0,0,4.278,1.66A2.344,2.344,0,0,0,22.9,20.89Zm10.073,5.266H29v2.208h3.977Z"
        transform="translate(4086.41 3737.768)"
        className="fillSvg"
      />
    </g>
  </svg>
);
export const RewardsSvg = (
  <img src={RewardsIcon} alt="iconRewards" width="20px" height="20px" />
);

export const StockSvg = (
  <img src={StockIcon} alt="iconStock" width="20px" height="20px" />
);

export const ProductSvg = (
  <img src={ProductIcon} alt="iconProduct" width="20px" height="20px" />
);

export const ReceiveProdSvg = (
  <img src={receiveProductIcon} alt="iconReceive" width="24px" height="24px" />
);

export const TakenProdSvg = (
  <img src={takenProductIcon} alt="iconTaken" width="24px" height="24px" />
);

export const fleshStockSvg = (
  <img src={flecheIcon} alt="iconProductflesh" width="12px" height="12px" />
);

export const upLightSVG = (
  <img src={upLight} alt="iconProductflesh" width="18px" height="18px" />
);
export const fleshBashSvg = (
  <img src={flecheBasIcon} alt="iconFlesh" width="12px" height="12px" />
);
export const downLightSVG = (
  <img src={downLight} alt="iconFlesh" width="18px" height="18px" />
);

export const StockLightSvg = (
  <img src={StockIconBlack} alt="iconStock" width="20px" height="20px" />
);

export const ProductLightSvg = (
  <img src={ProductIconBlack} alt="iconProduct" width="20px" height="20px" />
);

export const ReceiveProdLightSvg = (
  <img
    src={receiveProductIconBlack}
    alt="iconReceive"
    width="24px"
    height="24px"
  />
);

export const TakenProdLightSvg = (
  <img src={takenProductIconBlack} alt="iconTaken" width="24px" height="24px" />
);

export const BundleSvg = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 7.62012H6C5.4 7.62012 5 8.02012 5 8.62012V11.1201H12.5C13.1 11.1201 13.5 11.5201 13.5 12.1201V19.6201H16C16.6 19.6201 17 19.2201 17 18.6201V8.62012C17 8.02012 16.6 7.62012 16 7.62012ZM11 12.6201H3C2.4 12.6201 2 13.0201 2 13.6201V21.6201C2 22.2201 2.4 22.6201 3 22.6201H11C11.6 22.6201 12 22.2201 12 21.6201V13.6201C12 13.0201 11.6 12.6201 11 12.6201ZM21 2.62012H9C8.4 2.62012 8 3.02012 8 3.62012V6.12012H17.5C18.1 6.12012 18.5 6.52012 18.5 7.12012V16.6201H21C21.6 16.6201 22 16.2201 22 15.6201V3.62012C22 3.02012 21.6 2.62012 21 2.62012Z"
      className="fillSvg"
    />
  </svg>
);

export const DeliveryPricingSvg = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_465_1417)">
      <path
        d="M17.9244 19.2905H17.2795V11.9159H19.325C20.181 11.9159 21.002 12.256 21.6073 12.8613C22.2126 13.4665 22.5529 14.2875 22.5529 15.1435V18.6425C22.553 18.7273 22.5359 18.8112 22.5036 18.8895C22.4713 18.9679 22.4237 19.0391 22.3638 19.0992C22.3039 19.1592 22.2327 19.2068 22.1545 19.2394C22.0762 19.2719 21.9927 19.2887 21.908 19.2888H20.6149C20.6149 19.6457 20.4736 19.988 20.2213 20.2403C19.9689 20.4927 19.6261 20.6345 19.2692 20.6345C18.9123 20.6345 18.5704 20.4927 18.318 20.2403C18.0656 19.988 17.9236 19.6457 17.9236 19.2888L17.9244 19.2905ZM5.27624 19.2905H3.28486C3.11344 19.2905 2.94864 19.2224 2.82742 19.1012C2.70621 18.98 2.63831 18.8156 2.63831 18.6442V6.64415C2.63831 6.47273 2.70621 6.30833 2.82742 6.18712C2.94864 6.06591 3.11344 5.9978 3.28486 5.9978H15.2849C15.4561 5.99802 15.6205 6.06623 15.7415 6.18742C15.8625 6.30861 15.9298 6.47287 15.9298 6.64415V19.2938H7.96347C7.96347 19.6507 7.82224 19.9929 7.56988 20.2453C7.31752 20.4976 6.97472 20.6395 6.61783 20.6395C6.26094 20.6395 5.91895 20.4976 5.66659 20.2453C5.41423 19.9929 5.2722 19.6507 5.2722 19.2938L5.27624 19.2905Z"
        className="fillSvg"
      />
    </g>
    <defs>
      <clipPath id="clip0_465_1417">
        <rect
          width="24"
          height="24"
          className="fillSvg"
          transform="translate(0 0.620117)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const LandingPageSvg = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8143 4.93945H4.18554C3.47517 4.93945 2.88 5.51549 2.88 6.24498V19.013C2.88 19.7234 3.45604 20.3185 4.18554 20.3185H19.8335C20.5439 20.3185 21.1391 19.7425 21.1391 19.013L21.1392 6.24498C21.12 5.51549 20.544 4.93945 19.8145 4.93945H19.8143ZM8.48634 6.03392C8.81272 6.03392 9.0815 6.30271 9.0815 6.62909C9.0815 6.95546 8.81271 7.22425 8.48634 7.22425C8.15996 7.22425 7.89117 6.95546 7.89117 6.62909C7.89117 6.30271 8.15996 6.03392 8.48634 6.03392ZM6.66237 6.03392C6.98875 6.03392 7.25754 6.30271 7.25754 6.62909C7.25754 6.95546 6.98874 7.22425 6.66237 7.22425C6.33599 7.22425 6.0672 6.95546 6.0672 6.62909C6.0672 6.30271 6.31671 6.03392 6.66237 6.03392ZM4.8191 6.03392C5.14548 6.03392 5.41427 6.30271 5.41427 6.62909C5.41427 6.95546 5.14547 7.22425 4.8191 7.22425C4.49272 7.22425 4.22394 6.95546 4.22394 6.62909C4.22394 6.30271 4.49273 6.03392 4.8191 6.03392ZM20.0063 18.9939C20.0063 19.0899 19.9296 19.186 19.8142 19.186L4.18545 19.1858C4.08942 19.1858 3.99339 19.1091 3.99339 18.9938L3.99353 8.49139H20.0255L20.0254 18.9938L20.0063 18.9939Z"
      className="fillSvg"
    />
    <path
      d="M12.5183 15.4994H17.4911C17.8751 15.4994 18.2015 15.173 18.2015 14.789V10.7378C18.2015 10.3539 17.8751 10.0275 17.4911 10.0275L12.5183 10.0273C12.1344 10.0273 11.808 10.3537 11.808 10.7377V14.7889C11.8078 15.173 12.1344 15.4994 12.5183 15.4994V15.4994ZM16.3967 11.1794C16.6464 11.1794 16.8383 11.3715 16.8383 11.621C16.8383 11.8706 16.6463 12.0625 16.3967 12.0625C16.1471 12.0625 15.9552 11.8705 15.9552 11.621C15.936 11.3715 16.1472 11.1794 16.3967 11.1794ZM13.056 14.0786L14.3615 12.005C14.4 11.9474 14.496 11.9282 14.5343 12.005L15.3407 13.0801C15.3792 13.1377 15.4559 13.1377 15.5135 13.0993L15.859 12.7729C15.9166 12.7345 15.9935 12.7345 16.0318 12.7921L16.9534 14.0785C17.0109 14.1552 16.9534 14.2512 16.8573 14.2512L13.1521 14.2515C13.0752 14.2515 13.0176 14.1555 13.056 14.0786L13.056 14.0786Z"
      className="fillSvg"
    />
    <path
      d="M6.29751 11.1409H10.2335C10.5407 11.1409 10.7902 10.8913 10.7902 10.5842C10.7902 10.277 10.5406 10.0275 10.2335 10.0275L6.29751 10.0273C5.99028 10.0273 5.74078 10.277 5.74078 10.5841C5.74078 10.8914 5.99029 11.1409 6.29751 11.1409V11.1409Z"
      className="fillSvg"
    />
    <path
      d="M6.29751 13.3109H10.2335C10.5407 13.3109 10.7902 13.0612 10.7902 12.7541C10.7902 12.4469 10.5406 12.1974 10.2335 12.1974L6.29751 12.1973C5.99028 12.1973 5.74078 12.4469 5.74078 12.754C5.74078 13.0612 5.99029 13.3109 6.29751 13.3109V13.3109Z"
      className="fillSvg"
    />
    <path
      d="M6.29751 15.4993H10.2335C10.5407 15.4993 10.7902 15.2497 10.7902 14.9426C10.7902 14.6354 10.5406 14.3859 10.2335 14.3859L6.29751 14.3857C5.99028 14.3857 5.74078 14.6354 5.74078 14.9425C5.74078 15.2497 5.99029 15.4993 6.29751 15.4993V15.4993Z"
      className="fillSvg"
    />
    <path
      d="M6.29751 17.6693H17.7023C18.0095 17.6693 18.259 17.4196 18.259 17.1125C18.259 16.8053 18.0094 16.5558 17.7023 16.5558L6.29751 16.5557C5.99028 16.5557 5.74078 16.8053 5.74078 17.1124C5.74078 17.4196 5.99029 17.6693 6.29751 17.6693V17.6693Z"
      className="fillSvg"
    />
  </svg>
);

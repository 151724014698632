import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, Prompt } from "react-router-dom";
import { alertActions, productActions, shopActions } from "../../../actions";
import { DEFAULT_PRICING_OPTION } from "../../../constants";
import {
  customSection,
  DataShop,
  DeletedShopPhoto,
  ProductShop,
  ProductShopPhoto,
} from "../../../types";
import { convertDescriptionToSlug, priceChecker } from "../../../utils/helpers";
import { Translate } from "../../../utils/lang/translate";
import useExitPrompt from "../../../utils/useExitPrompt";
import Button from "../../_Common/Button";
import GroupeForm from "../../_Common/GroupeForm";
import { Event, Select } from "../_Common";
import ContainerPage from "../_Common/ContainerPage";
import TableLoader from "../_Common/TableLoader";
import ImagesList from "./ImagesList";
import RichTextEditor from "./RichTextEditor";
import ProductPricingOptionsFields from "./ProductPricingOptionsFields";
import "./ShopPage.scss";
import TagInput from "../../_Common/TagInput";
import { Switch } from "@mui/material";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import { LoadingSmallSvg } from "../_Common/IconSvg";
import SectionList from "./SectionList";

const INITIAL_OPTION = {
  name: "Option name",
  quantity: 1,
  price: 0,
  reduced_price: null,
  id: "",
};

const INITIAL_PRODUCT_FORM: ProductShop = {
  title: "",
  slug: "",
  options: [{ ...INITIAL_OPTION }],
  categories: [],
  description: "",
  how_to_use: "",
  images: [],
  specifications: "",
  product_id: "",
  display_id: "",
  published: true,
  id: "",
  landing_page: "",
  lang: "ar",
  rate: "5",
  pricing_option: DEFAULT_PRICING_OPTION,
  template_details: [{ custom_sections: [] }],
};

const slugRegex = new RegExp("^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$");

const languages = [
  ["ar", Translate("profile", "ar")],
  ["fr", Translate("profile", "fr")],
  ["en", Translate("profile", "en")],
];

interface ImageToUpload {
  imageFile: File;
  id: string;
}

interface Product {
  title: string;
  id: string;
}

interface Props {
  dtStoreinfo: any;
  GetAllProduct: any;
  GetShopInfoWithPricing: () => void;
  dataShop: DataShop;
  shopError: boolean;
  shopLoading: boolean;
  productShopPhoto: ProductShopPhoto;
  deletedShopPhoto: DeletedShopPhoto;
  ModifyProductShop: (
    dataShop: DataShop,
    productShop: ProductShop,
    successCallback?: () => void
  ) => void;
  CreateProductShop: (
    productShop: ProductShop,
    newImages: File[],
    data: DataShop,
    setProgress?: (percentage: number | null) => void,
    successCallback?: () => void
  ) => void;
  DeletePhotoProductShop: (
    imageId: string,
    productId: string,
    successCallback?: () => void
  ) => void;
  UploadPhotoProductShop: (
    newImage: File,
    productId: string,
    setProgress?: (percentage: number | null) => void,
    successCallback?: () => void
  ) => void;
  data: any;
  match: any;
  ProductFinded: any;
  history: any;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const LandingPageForm: React.FC<Props> = ({
  GetAllProduct,
  GetShopInfoWithPricing,
  dataShop,
  shopLoading,
  productShopPhoto,
  deletedShopPhoto,
  ModifyProductShop,
  CreateProductShop,
  UploadPhotoProductShop,
  DeletePhotoProductShop,
  data,
  match,
  ProductFinded,
  history,
  SendAlert,
}) => {
  const { idprod } = match.params;
  const [productList, setProductList] = useState<
    { title: string; id: string }[]
  >([]);
  const [product, setProduct] = useState<Product | null>(null);
  const [productForm, setProductForm] = useState(INITIAL_PRODUCT_FORM);
  const [imagesToUpload, setImagesToUpload] = useState<ImageToUpload[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isChange, setIsChange] = useExitPrompt(false);
  const [isModify, setIsModify] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);

  useEffect(() => {
    document.title =
      "Maystro Delivery - " + Translate("titles", "addProductShop");
    GetShopInfoWithPricing();
    return () => {
      setIsChange(false);
    };
  }, []);

  useEffect(() => {
    if (
      !shopLoading &&
      dataShop?.pricingOptions &&
      Array.isArray(dataShop.products)
    ) {
      if (!dataShop.published) history.push("/shop/settings/");
      if (idprod === "add") {
        GetAllProduct();
        return;
      }

      const productDataShop = dataShop.products.find(
        (prod) => prod.display_id.toString() === idprod.toString()
      );
      if (!productDataShop) {
        history.push("/shop/landing-page");
        return;
      }

      const pricing_option =
        productDataShop.pricing_option ?? DEFAULT_PRICING_OPTION;
      setProductForm({
        ...productForm,
        ...productDataShop,
        pricing_option,
        display_id: idprod,
      });
      setIsModify(true);
      setIsLoading(false);
    }
  }, [dataShop]);

  useEffect(() => {
    if (
      ProductFinded === "2" &&
      data?.list &&
      Array.isArray(data.list) &&
      dataShop
    ) {
      const productsWithoutLandingPage = data.list.filter((prod: any) =>
        dataShop.products.every((shopProd) => shopProd.product_id !== prod.id)
      );
      const list = productsWithoutLandingPage.map((prod: any) => ({
        id: prod.id,
        title: prod.logistical_description,
      }));
      setProductList(list);
      setIsLoading(false);
    }
  }, [data, dataShop]);

  useEffect(() => {
    if (
      productShopPhoto?.url &&
      productShopPhoto.productId === productForm.id
    ) {
      setProductForm({
        ...productForm,
        images: [
          ...productForm.images,
          {
            url: productShopPhoto.url,
            id: productShopPhoto.id,
            order: productForm.images.length,
          },
        ],
      });
    }
  }, [productShopPhoto]);

  useEffect(() => {
    if (
      deletedShopPhoto?.imageId &&
      deletedShopPhoto.productId === productForm.id
    ) {
      setProductForm({
        ...productForm,
        images: productForm.images
          .filter((img) => img.id !== deletedShopPhoto.imageId)
          .map((img, key) => {
            return { ...img, order: key };
          }),
      });
    }
  }, [deletedShopPhoto]);

  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    key?: number
  ) => {
    const { value, name } = event.target as HTMLInputElement;
    if (typeof key !== "number")
      setProductForm({ ...productForm, [name]: value });
    else
      setProductForm({
        ...productForm,
        options: productForm.options.map((option, i) => {
          if (i === key)
            return { ...option, [name]: value !== "" ? value : null };
          return option;
        }),
      });
    setIsChange(true);
  };

  const handleRichTextChange = (content: string, name: string) => {
    if (name in productForm) {
      setProductForm({ ...productForm, [name]: content });
      setIsChange(true);
    }
  };

  const addSection = () => {
    setProductForm({
      ...productForm,
      template_details: [
        {
          custom_sections: [
            ...productForm.template_details[0].custom_sections,
            { title: "", content: "" },
          ],
        },
      ],
    });
    setIsChange(true);
  };

  const deleteSection = (key: number) => {
    const updatedSectionList =
      productForm.template_details[0].custom_sections.filter(
        (section, k) => k !== key
      );
    setProductForm({
      ...productForm,
      template_details: [
        {
          custom_sections: updatedSectionList,
        },
      ],
    });
    setIsChange(true);
  };

  const editSectionContent = (content: string, key: number) => {
    const updatedSectionList =
      productForm.template_details[0].custom_sections.map((section, k) =>
        k === key ? { ...section, content } : section
      );
    setProductForm({
      ...productForm,
      template_details: [
        {
          custom_sections: updatedSectionList,
        },
      ],
    });
    setIsChange(true);
  };

  const editSectionTitle = (title: string, key: number) => {
    const updatedSectionList =
      productForm.template_details[0].custom_sections.map((section, k) =>
        k === key ? { ...section, title } : section
      );
    setProductForm({
      ...productForm,
      template_details: [
        {
          custom_sections: updatedSectionList,
        },
      ],
    });
    setIsChange(true);
  };

  const handleSlugChange = (event: any) => {
    event.preventDefault();
    const { key } = event;
    if (key) {
      if (key === "Backspace" && productForm.slug.length > 0) {
        setProductForm({
          ...productForm,
          slug: productForm.slug.slice(0, productForm.slug.length - 1),
        });
        setIsChange(true);
      }
      if (/^[a-z0-9-]$/.test(key) && productForm.slug.length < 30) {
        setProductForm({ ...productForm, slug: productForm.slug.concat(key) });
        setIsChange(true);
      }
    }
  };

  const handleChangeCategories = (newCategories: string[], name: string) => {
    setProductForm({ ...productForm, categories: newCategories });
    setIsChange(true);
  };

  const handleAddOption: () => void = () => {
    if (productForm.options.length < 10) {
      setProductForm({
        ...productForm,
        options: [...productForm.options, { ...INITIAL_OPTION }],
      });
      setIsChange(true);
    }
  };

  const handleDeleteOption: (key: number) => void = (key) => {
    if (productForm.options.length > 1) {
      setProductForm({
        ...productForm,
        options: productForm.options.filter((option, i) => key !== i),
      });
      setIsChange(true);
    }
  };

  const addImage = (newImage: File) => {
    if (
      productForm.images.length <= 5 &&
      !productForm.images.some(
        (img) => img.id === newImage.name + newImage.size
      )
    ) {
      if (isModify) {
        UploadPhotoProductShop(newImage, productForm.id, setProgress, () => {
          setIsChange(true);
        });
      } else {
        setProductForm({
          ...productForm,
          images: [
            ...productForm.images,
            {
              url: URL.createObjectURL(newImage),
              id: newImage.name + newImage.size,
              order: productForm.images.length,
            },
          ],
        });
        setImagesToUpload([
          ...imagesToUpload,
          {
            imageFile: newImage,
            id: newImage.name + newImage.size,
          },
        ]);
        setIsChange(true);
      }
    }
  };

  const removeImage = (imageId: string) => {
    if (isModify) {
      DeletePhotoProductShop(imageId, productForm.id, () => {
        setIsChange(true);
      });
    } else {
      setProductForm({
        ...productForm,
        images: productForm.images
          .filter((img) => imageId !== img.id)
          .map((img, key) => {
            return { ...img, order: key };
          }),
      });
      setImagesToUpload(imagesToUpload.filter((img) => imageId !== img.id));
      setIsChange(true);
    }
  };

  const handleImagePositionChange = (
    position: number,
    direction: "left" | "right"
  ) => {
    if (direction === "left" && position > 0) {
      const imagesLeftPart = productForm.images.slice(0, position - 1);
      const imagesRightPart = productForm.images.slice(
        position + 1,
        productForm.images.length
      );
      setProductForm({
        ...productForm,
        images: [
          ...imagesLeftPart,
          productForm.images[position],
          productForm.images[position - 1],
          ...imagesRightPart,
        ].map((img, key) => {
          return { ...img, order: key };
        }),
      });
      setIsChange(true);
    }
    if (direction === "right" && position < productForm.images.length - 1) {
      const imagesLeftPart = productForm.images.slice(0, position);
      const imagesRightPart = productForm.images.slice(
        position + 2,
        productForm.images.length
      );
      setProductForm({
        ...productForm,
        images: [
          ...imagesLeftPart,
          productForm.images[position + 1],
          productForm.images[position],
          ...imagesRightPart,
        ].map((img, key) => {
          return { ...img, order: key };
        }),
      });
      setIsChange(true);
    }
  };

  const handleIsVisible = () => {
    setProductForm({ ...productForm, published: !productForm.published });
    setIsChange(true);
  };

  const handleProductChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { value } = e.target as HTMLInputElement;
    const selectedProd = productList.find((prod) => prod.title === value);
    if (selectedProd) {
      const slug = convertDescriptionToSlug(selectedProd!.title);
      setProductForm({
        ...productForm,
        title: selectedProd!.title,
        product_id: selectedProd!.id,
        slug,
      });
      setProduct(selectedProd);
      setIsChange(true);
    } else {
      setProduct({ title: value, id: product?.id ?? "" });
    }
  };

  const handleLang = (option: any) => {
    const value = option[0];
    if (value === "ar" || value === "fr" || value === "en") {
      setProductForm({ ...productForm, lang: option[0] });
      setIsChange(true);
    }
  };

  const handleRate = (option: any) => {
    const value = option[0];
    if (value > 0 && value <= 5) {
      setProductForm({ ...productForm, rate: value });
      setIsChange(true);
    }
  };

  const handlePricingOption = (option: any) => {
    setProductForm({ ...productForm, pricing_option: option[0] });
    setIsChange(true);
  };

  const handleSave = () => {
    const pricing_option =
      productForm.pricing_option !== DEFAULT_PRICING_OPTION
        ? productForm.pricing_option
        : null;
    const newProduct = { ...productForm, pricing_option };
    if (isModify) {
      Event("SHOP", "MODIFY_SHOP_PRODUCT", "CLICK_EVENT");
      ModifyProductShop(dataShop, newProduct, () => {
        setIsChange(false);
        SendAlert("41", Translate("shop", "changesSaved"));
        history.push("/shop/landing-page");
      });
    } else {
      Event("SHOP", "CREATE_SHOP_PRODUCT", "CLICK_EVENT");
      const images = imagesToUpload.map((elem) => elem.imageFile);
      CreateProductShop(newProduct, images, dataShop, setProgress, () => {
        setImagesToUpload([]);
        setIsChange(false);
        history.push("/shop/landing-page");
      });
    }
  };

  const deliveryPricingOption = useMemo(
    () =>
      dataShop?.pricingOptions?.map((pr) => [
        pr.id,
        pr.id === DEFAULT_PRICING_OPTION
          ? Translate("shop", "defaultPricingOption")
          : pr.name,
      ]),
    [dataShop]
  );

  const deliveryPricingOptionValue = useMemo(
    () =>
      deliveryPricingOption?.find(
        (arr) => arr[0] === productForm.pricing_option
      ),
    [deliveryPricingOption, productForm]
  );

  const IsSaveButtonDisabled =
    shopLoading ||
    !(productForm.display_id || productForm.product_id) ||
    !productForm.title ||
    !slugRegex.test(productForm.slug) ||
    !productForm.options.every(
      (option) =>
        option.name &&
        option.quantity &&
        priceChecker(option.price, option.reduced_price)
    ) ||
    productForm.images.length < 1 ||
    !productForm.description ||
    !productForm.pricing_option ||
    productForm.template_details[0]?.custom_sections.some(
      (section: customSection) => !section.title || !section.content
    ) ||
    !isChange;

  return (
    <ContainerPage
      page_title={
        <div>
          <span className="StOpcVal">{Translate("titles", "shop")}</span>
          <div className="Inlflx StWdDst StOpcVal">
            {Translate("orders", "flech")}
          </div>
          <Link
            to={"/shop/landing-page"}
            onClick={() => {
              Event(
                "SHOP",
                `BREADCRUMB_FROM_LANDING_PAGE_FORM_TO_LANDING_PAGE`,
                "CLICK_EVENT"
              );
            }}
          >
            <span className="StOpcVal">{Translate("shop", "landingPage")}</span>
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="CrsPoi">
            {Translate(
              "shop",
              idprod === "add" ? "createLandingPage" : "editLandingPage"
            )}
          </span>
        </div>
      }
      data_top={
        !isLoading && (
          <Button
            clName="bundle-button"
            BtnText={shopLoading ? LoadingSmallSvg : Translate("shop", "save")}
            onClick={() => {
              if (!IsSaveButtonDisabled) {
                handleSave();
              }
            }}
            style={{
              backgroundColor: "#008AFF",
              color: "white",
              fontSize: "16px",
              width: "150px",
            }}
            disabled={IsSaveButtonDisabled}
          />
        )
      }
    >
      {isLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : (
        <>
          <Prompt when={isChange} message={Translate("alert", "prompt")} />
          <div className="LandingPageForm">
            <div>
              <ContainerPage
                subContainer
                page_title={Translate("product", "productdetails")}
                small_title={true}
                withOvrl
                componentClass="landingPage-container hAuto"
              >
                <div
                  className={`grid marginBottom1 ${
                    idprod === "add" ? "grid-1-1-1" : "grid-1-1"
                  }`}
                >
                  {idprod === "add" && (
                    <SelectFormSearch
                      id="product"
                      name="product"
                      index="title"
                      option={productList}
                      text={Translate("product", "product")}
                      workfun={handleProductChange}
                      placeholder={Translate(
                        "shop",
                        "selectedProductPlaceholder"
                      )}
                      value={product?.title}
                      stclass={!productForm.product_id ? "borderError" : ""}
                      required
                    />
                  )}
                  <GroupeForm
                    id="title"
                    name="title"
                    placeholder={Translate("shop", "titlePlaceHolder")}
                    text={Translate("shop", "title")}
                    type="text"
                    value={productForm.title}
                    workfun={handleChange}
                    stclass={!productForm.title ? "borderError" : ""}
                    required
                  />
                  <div className="Flwd InFlx flxDrc AlgnItm RlPs">
                    <GroupeForm
                      id="slug"
                      name="slug"
                      placeholder={Translate("shop", "slugPlaceHolder")}
                      text={Translate("shop", "slug")}
                      pattern="^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$"
                      type="text"
                      value={productForm.slug}
                      onKeyDown={handleSlugChange}
                      style={{ width: "100%" }}
                      stclass={
                        !slugRegex.test(productForm.slug) ? "borderError" : ""
                      }
                      required
                    />
                    <div className="StAbs slug-slash">/</div>
                  </div>
                </div>
                <div className="marginTop1">
                  <RichTextEditor
                    title={Translate("shop", "description")}
                    id="description"
                    value={productForm.description}
                    setValue={(content) =>
                      handleRichTextChange(content, "description")
                    }
                    isError={!productForm.description}
                  />
                  <SectionList
                    sections={productForm.template_details[0].custom_sections}
                    addSection={addSection}
                    deleteSection={deleteSection}
                    editSectionContent={editSectionContent}
                    editSectionTitle={editSectionTitle}
                  />
                </div>
                <ImagesList
                  images={productForm.images}
                  addImage={addImage}
                  removeImage={removeImage}
                  changePosition={handleImagePositionChange}
                  progress={progress}
                  shopLoading={shopLoading}
                />
                <div className="rate">
                  <Select
                    label={Translate("shop", "rate")}
                    Options={[["5.00"], ["4.50"], ["4.00"], ["3.50"], ["3.00"]]}
                    fieldShow={0}
                    name="rate"
                    value={[productForm.rate]}
                    onChange={handleRate}
                    maxSize="200px"
                  />
                </div>
              </ContainerPage>
              <ContainerPage
                subContainer
                page_title={Translate("shop", "productPricingOptions")}
                small_title={true}
                withOvrl
                componentClass="landingPage-container hAuto"
              >
                {productForm.options.length > 1 ? (
                  <ProductPricingOptionsFields
                    options={productForm.options}
                    handleChange={handleChange}
                    handleDeleteOption={handleDeleteOption}
                    handleAddOption={handleAddOption}
                  />
                ) : (
                  <>
                    <div className="grid grid-1-1 RlPs marginTopSmall OptionsField">
                      <GroupeForm
                        id={`price-option-${0}`}
                        name="price"
                        text={Translate("shop", "price")}
                        type="number"
                        placeholder={Translate("shop", "price")}
                        min={0}
                        value={productForm.options[0].price}
                        workfun={(
                          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                          handleChange(e, 0);
                        }}
                        inputStyle={
                          productForm.options[0].reduced_price
                            ? { textDecoration: "line-through" }
                            : {}
                        }
                        stclass={
                          !priceChecker(
                            productForm.options[0].price,
                            productForm.options[0].reduced_price
                          )
                            ? "borderError"
                            : ""
                        }
                        required
                      />
                      <GroupeForm
                        id={`reduced_price-option-${0}`}
                        name="reduced_price"
                        text={Translate("shop", "reducedPrice")}
                        type="number"
                        placeholder={Translate("shop", "reducedPrice")}
                        min={0}
                        value={productForm.options[0].reduced_price}
                        workfun={(
                          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                          handleChange(e, 0);
                        }}
                      />
                    </div>
                    <p className="pricing-text">
                      {Translate("shop", "productPricingOptionText")}
                    </p>
                    <div
                      className="addOptionButton-main"
                      onClick={handleAddOption}
                    >
                      + {Translate("shop", "addOption")}
                    </div>
                  </>
                )}
              </ContainerPage>
            </div>
            <div>
              <div>
                <ContainerPage
                  subContainer
                  page_title={Translate("shop", "deliveryDetails")}
                  small_title={true}
                  withOvrl
                  componentClass="landingPage-container hAuto"
                >
                  <Select
                    label={Translate("shop", "choosePricingOption")}
                    Options={deliveryPricingOption}
                    fieldShow={1}
                    name="pricing_option"
                    value={deliveryPricingOptionValue}
                    onChange={handlePricingOption}
                    maxSize="200px"
                  />
                </ContainerPage>
                <ContainerPage
                  subContainer
                  page_title="SEO"
                  small_title={true}
                  withOvrl
                  componentClass="landingPage-container hAuto"
                >
                  <TagInput
                    text={Translate("shop", "productCategories")}
                    placeholder={Translate("shop", "categoryPlaceholder")}
                    name="categories"
                    values={productForm.categories}
                    handleChange={handleChangeCategories}
                    optional
                  />
                </ContainerPage>
                <ContainerPage
                  subContainer
                  page_title={Translate("shop", "landingPage")}
                  small_title={true}
                  withOvrl
                  componentClass="landingPage-container hAuto"
                >
                  <Select
                    label={Translate("shop", "chooseLanguage")}
                    Options={languages}
                    fieldShow={1}
                    name="lang"
                    value={languages.find((arr) => arr[0] === productForm.lang)}
                    onChange={handleLang}
                    maxSize="200px"
                  />
                  <div className="is-visible-toggle">
                    <Switch
                      checked={productForm.published}
                      onClick={handleIsVisible}
                    />
                    <p>{Translate("shop", "makeProductVisible")}</p>
                  </div>
                </ContainerPage>
              </div>
            </div>
          </div>
        </>
      )}
    </ContainerPage>
  );
};

function mapState(state: any) {
  const {
    dataShop,
    shopError,
    shopLoading,
    productShopPhoto,
    deletedShopPhoto,
  } = state.shop;
  const { dtStoreinfo } = state.user;
  const { ProductFinded, data } = state.product;
  return {
    dtStoreinfo,
    ProductFinded,
    data,
    dataShop,
    shopError,
    shopLoading,
    productShopPhoto,
    deletedShopPhoto,
  };
}

const actionCreators = {
  GetAllProduct: productActions.GetAllProduct,
  GetShopInfoWithPricing: shopActions.GetShopInfoWithPricing,
  ModifyProductShop: shopActions.ModifyProductShop,
  CreateProductShop: shopActions.CreateProductShop,
  DeletePhotoProductShop: shopActions.DeletePhotoProductShop,
  UploadPhotoProductShop: shopActions.UploadPhotoProductShop,
  SendAlert: alertActions.SendAlert,
};

export default connect(mapState, actionCreators)(LandingPageForm);

import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../../../actions";
import { Translate } from "../../../../utils/lang/translate";
import { FlechSvg } from "../../_Common/IconSvg";
import GroupeForm from "../../../_Common/GroupeForm";
import CheckBoxForm from "../../../_Common/CheckBoxForm";
import Button from "../../../_Common/Button";
import Loader from "../../../_Common/Loader";
import { Link } from "react-router-dom";
import { initGAPg, Event } from "../../_Common";
class AddTeam extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    let idteam = params.idteam;
    var state = {
      team_name: "",
      view_order: false,
      add_order: false,
      change_order: false,
      view_stock: false,
      view_payment: false,
      view_issue_store: false,
      view_pickup: false,
      titlepage: Translate("settings", "addteam"),
      ttbtn: Translate("settings", "addtm"),
      idTeam: "new",
      backto: "/settings/team/home",
    };
    if (idteam !== "new") {
      const { GetingDTeam, dataDListTeam } = this.props;

      if (GetingDTeam === "1") {
        if (dataDListTeam.team && dataDListTeam.team.id === Number(idteam)) {
          state.team_name = dataDListTeam.team.name;
          state.idTeam = dataDListTeam.team.id;
          state.titlepage = Translate("settings", "editteam");
          state.ttbtn = Translate("orders", "save");
          Array.isArray(dataDListTeam.team.permissions) &&
            dataDListTeam.team.permissions.map((prem) => {
              state[prem.codename] = true;
            });
        } else {
          this.props.history.push("/settings/team/home");
        }
      } else {
        this.props.history.push("/settings/team/home");
      }
    }
    this.state = state;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangecheck = this.handleChangecheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const { UpdatingTeam, CreatingTeam } = nextProps;
    if (this.props.CreatingTeam === "0" && CreatingTeam === "1") {
      this.props.SendAlert("41", Translate("alert", "teamAddsuc"));
      this.props.history.push("/settings/team/home");
    }
    if (this.props.UpdatingTeam === "0" && UpdatingTeam === "1") {
      this.props.SendAlert("41", Translate("alert", "teamUpdSucc"));
      window.history.back();
    }
    if (this.props.GetingDTeam === "0" && CreatingTeam === "2") {
      this.props.SendAlert("50", Translate("alert", "failTeamAdd"));
    }
    if (this.props.UpdatingTeam === "0" && UpdatingTeam === "2") {
      this.props.SendAlert("50", Translate("alert", "failTeamUpd"));
    }
    this.props.GetProfileUser();
  }
  componentDidMount() {
    initGAPg();
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleChangecheck(e) {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  }
  handleSubmit(e) {
    e.preventDefault();
    let prem = [];
    if (this.state.view_order) {
      prem.push({ codename: "view_order" });
    }
    if (this.state.add_order) {
      prem.push({ codename: "add_order" });
    }
    if (this.state.view_stock) {
      prem.push({ codename: "view_stock" });
    }
    if (this.state.view_issue_store) {
      prem.push({ codename: "view_issue_store" });
    }
    if (this.state.change_order) {
      prem.push({ codename: "change_order" });
    }
    if (this.state.view_payment) {
      prem.push({ codename: "view_payment" });
    }
    if (this.state.view_pickup) {
      prem.push({ codename: "view_pickup" });
    }
    let team = { name: this.state.team_name, permissions: prem };
    if (this.state.idTeam === "new") {
      Event("STORE_TEAM", "CREAT_NEW_TEAM", "SUBMIT_EVENT");
      this.props.CreateTeam(team);
    } else {
      Event("STORE_TEAM", "EDIT_EXIST_TEAM", "SUBMIT_EVENT");
      this.props.UpdateTeam(team, this.state.idTeam);
    }
  }
  render() {
    const { CreatingTeam, UpdatingTeam } = this.props;
    return (
      <div>
        {(CreatingTeam === "0" || UpdatingTeam === "0") && <Loader />}
        <div className="stbd2">
          <div className="InFlx flxDrc StDrcIn">
            <div className="StWdDst StPaddingVal CrsPoi RtVl">
              <Link to={this.state.backto}>{FlechSvg}</Link>
            </div>
            <div className="StdirLn">
              <h3 className="DlMg">{this.state.titlepage}</h3>
              <p className="DlMg">{Translate("settings", "txtteam")}</p>
            </div>
          </div>
        </div>
        <div className="MrAot Hlwd StMrtp responsive-team">
          <form method="post" onSubmit={this.handleSubmit}>
            <GroupeForm
              id={"team_name"}
              name={"team_name"}
              placeholder={Translate("settings", "teamname")}
              text={Translate("settings", "teamname")}
              type={"text"}
              value={this.state.team_name}
              workfun={this.handleChange}
            />
            <div className="stbd2">
              <div>
                <span className="DsBlc">
                  {Translate("settings", "permmision")}
                </span>
              </div>
              <div className="StdirLn StDrcIn">
                <CheckBoxForm
                  id={"view_order"}
                  name={"view_order"}
                  text={Translate("settings", "view_order")}
                  workfun={this.handleChangecheck}
                  check={this.state.view_order}
                />
                <CheckBoxForm
                  id={"add_order"}
                  name={"add_order"}
                  text={Translate("settings", "add_order")}
                  workfun={this.handleChangecheck}
                  check={this.state.add_order}
                />
                <CheckBoxForm
                  id={"change_order"}
                  name={"change_order"}
                  text={Translate("settings", "change_order")}
                  workfun={this.handleChangecheck}
                  check={this.state.change_order}
                />
                <CheckBoxForm
                  id={"view_stock"}
                  name={"view_stock"}
                  text={Translate("settings", "view_stock")}
                  workfun={this.handleChangecheck}
                  check={this.state.view_stock}
                />
                <CheckBoxForm
                  id={"view_payment"}
                  name={"view_payment"}
                  text={Translate("settings", "view_payment")}
                  workfun={this.handleChangecheck}
                  check={this.state.view_payment}
                />
                {!this.props.dtStoreinfo?.stock_managed && (
                  <CheckBoxForm
                    id={"view_pickup"}
                    name={"view_pickup"}
                    text={Translate("settings", "view_pickup")}
                    workfun={this.handleChangecheck}
                    check={this.state.view_pickup}
                  />
                )}
              </div>
            </div>
            <div className="MrAot StMarMx" style={{ width: "200px" }}>
              <Button BtnText={this.state.ttbtn} />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { dtStoreinfo } = state.user;
  const {
    GetingDTeam,
    dataDListTeam,
    UpdatingTeam,
    dataTeamUpdate,
    CreatingTeam,
    dataTeamCreate,
  } = state.team;
  return {
    GetingDTeam,
    dataDListTeam,
    UpdatingTeam,
    dataTeamUpdate,
    CreatingTeam,
    dataTeamCreate,
    dtStoreinfo,
  };
}
const actionCreators = {
  CreateTeam: userActions.CreateTeam,
  UpdateTeam: userActions.UpdateTeam,
  GetProfileUser: userActions.GetProfileUser,
  SendAlert: alertActions.SendAlert,
};

const connectedAddTeam = connect(mapState, actionCreators)(AddTeam);
export { connectedAddTeam as AddTeam };

import React from "react";
import { Translate } from "../../../utils/lang/translate";
import ReactTooltip from "react-tooltip";
export default function StatusElementTootlip(props) {
  let class_status =
    " TxtCn StPdsml sDfpnc sb4R " +
    (props.classes || "status") +
    "_" +
    props.status;

  //let wth = "calc(32px * " + props.data_btn.length + " )";

  return (
    <div className="StBrdRdSc MrAot" style={{ width: "auto" }}>
      {Array.isArray(props.data_btn) &&
        props.data_btn.map((elem, key) => {
          return (
            <div
              key={key}
              className={class_status + " " + (props.status_class || "")}
              data-tip
              data-for={`${elem.tooltip}-tooltip-${key}`}
              onClick={elem.action}
            >
              {Translate(props.category || "statusorder", elem.svgbtn)}
              {elem.tooltip && (
                <ReactTooltip id={`${elem.tooltip}-tooltip-${key}`}>
                  {elem.tooltip}
                </ReactTooltip>
              )}
            </div>
          );
        })}
    </div>
  );
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { commonActions, userActions } from "../../../actions";
import GroupeForm from "../../_Common/GroupeForm";
import SelectForm from "../../_Common/SelectForm";
import SelectFormv2 from "../../_Common/SelectFormv2";
import HeadLog from "../_Common/HeadLog";
import ContainerLog from "../_Common/ContainerLog";
import Button from "../../_Common/Button";
import { Translate } from "../../../utils/lang/translate";
import Loader from "../../_Common/Loader";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { Event, initGAPg } from "../../StorePages/_Common";
import SelectFormvWilaya from "../../_Common/SelectWiForm";
import SelectFormvCommune from "../../_Common/SelectFormComm";
class AddStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "adresse",
      country_id: "",
      wilaya: "",
      commune: "",
      country: "",
      stock_managed: true,
      submited: false,
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeV2 = this.handleChangeV2.bind(this);
    this.handleChangeV3 = this.handleChangeV3.bind(this);
    this.handleChangeV4 = this.handleChangeV4.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goToJoinStore = this.goToJoinStore.bind(this);
  }

  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "createstore");
    if (!this.props.user && !Cookies.get("temp-user-infos")) {
      this.props.history.push("/register");
    }
    if (this.props.user?.phone?.substr(0, 4) === "+213") {
      this.props.Getwilayas("1");
    } else if (this.props.user?.phone?.substr(0, 4) === "+216") {
      this.props.Getwilayas("2");
    }

    initGAPg();
    this.props.GetCountry();

    if (this.props.user?.phone?.substr(0, 4) === "+213") {
      this.setState({ country_id: "1" });
    } else if (this.props.user?.phone?.substr(0, 4) === "+216") {
      this.setState({ country_id: "2" });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.wilaya && prevState.wilaya !== this.state.wilaya) {
      this.props.Getcommunes(`?wilaya=${this.state.wilaya}`);
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChangeV2(e) {
    this.setState({ country_id: e.target.value });
  }

  handleChangeV3(e) {
    this.setState({ wilaya: e.target.value });
  }

  handleChangeV4(e) {
    this.setState({ commune: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { full_name, phone, user_name, email, password, confirmed_password } =
      this.props.user;
    const { name, address, stock_managed, country_id, wilaya, commune } =
      this.state;
    Event("REGISTER", "SUBMIT_STORE_INFOS", "CLICK_EVENT");
    let valid = true,
      err = {};
    ["name", "address", "stock_managed"].forEach((el) => {
      if (!this.state[el]) {
        err[el] = Translate("error", "reqfield");
        valid = false;
      }
    });
    this.setState({ errors: err });
    if (!valid) {
      Event(
        "REGISTER",
        "SUBMIT_STORE_INFOS_ERRORS",
        `[${Object.keys(err).join(",")}]`
      );
      return;
    }

    this.props.Register({
      full_name,
      phone,
      user_name,
      email,
      password,
      confirmed_password,
      name,
      address,
      stock_managed,
      country_id,
      wilaya,
      commune,
    });
  }
  goToJoinStore(e) {
    Event("REGISTER", "USE_JOIN_STORE_LINK", "CLICK_EVENT");
  }
  render() {
    const {
      Sended,
      registered,
      GetingPays,
      dataCountry,
      Getwilayas,
      dataWilaya,
      DataCommune,
    } = this.props;

    if (Sended !== "0" && Sended !== "1") {
      // return <Redirect to="/register"/>
    }
    if (registered === "2") {
      return <Redirect to="/register" />;
    }
    if (registered === "1") {
      return <Redirect to="/succes/register" />;
    }

    return (
      <ContainerLog>
        <HeadLog
          titleHead={Translate("auths", "store")}
          descHead={Translate("auths", "storetext")}
        />
        <form action="post" onSubmit={this.handleSubmit} className="StMarMx">
          <GroupeForm
            id={"store_name"}
            name={"name"}
            placeholder={Translate("auths", "storename")}
            text={Translate("auths", "storename")}
            type={"text"}
            value={this.state.name}
            workfun={this.handleChange}
          />

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "100%", marginRight: "10px" }}>
              <SelectFormvWilaya
                id={"store_location"}
                name={"address"}
                text={Translate("auths", "storelocation")}
                options={dataWilaya}
                valuee={this.state.wilaya}
                onChange={this.handleChangeV3}
              />
            </div>
            <div style={{ width: "100%", marginTop: "23px" }}>
              <SelectFormvCommune
                text={""}
                options={DataCommune}
                valuee={this.state.commune}
                onChange={this.handleChangeV4}
              />
            </div>
          </div>
          <SelectForm
            id={"stock_management"}
            name={"stock_managed"}
            option={[
              { text: Translate("auths", "managedby"), val: true },
              { text: Translate("auths", "managedbyme"), val: false },
            ]}
            text={Translate("auths", "stockmanagement")}
            workfun={this.handleChange}
          />
          <div className="SmtGrp MrAot StMarMx">
            <Button BtnText={Translate("auths", "finish")} />
          </div>
        </form>
        <div className="StOpcVal">
          <div className="StMrg">
            <span className="StWdDst">{Translate("auths", "havntstore")}</span>
            <Link
              className="StBle stbold StWdDst"
              to="/store/join"
              onClick={this.goToJoinStore}
            >
              {Translate("auths", "joinstore")}
            </Link>
          </div>
        </div>
        {registered === "3" && <Loader styl={{ borderRadius: "24px" }} />}
      </ContainerLog>
    );
  }
}
function mapState(state) {
  const { Sended, user, data, registered } = state.registration;
  const {
    dataCountry,
    GetingPays,
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
  } = state.common;
  return {
    Sended,
    user,
    data,
    registered,
    dataCountry,
    GetingPays,
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
  };
}

const actionCreators = {
  Register: userActions.Register,
  GetCountry: commonActions.GetCountry,
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
};

const connectedLoginPage = connect(mapState, actionCreators)(AddStore);
export { connectedLoginPage as AddStore };

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { shopActions } from "../../../../actions";
import { DataShop } from "../../../../types";
import { Translate } from "../../../../utils/lang/translate";
import Button from "../../../_Common/Button";
import { shopPublishedSvg } from "../../_Common/IconSvg";
import TableLoader from "../../_Common/TableLoader";
import { Event } from "../../_Common";
import ContainerPage from "../../_Common/ContainerPage";

interface Props {
  dataShop: DataShop;
  shopLoading: boolean;
  GetShopInfo: () => void;
  history: any;
}

const ShopPublishedSuccess: React.FC<Props> = ({
  dataShop,
  shopLoading,
  GetShopInfo,
  history,
}) => {
  useEffect(() => {
    GetShopInfo();
  }, []);

  useEffect(() => {
    if (dataShop && !dataShop.published) history.push("/shop/settings");
  }, [dataShop]);

  return (
    <ContainerPage
      page_title={
        <div>
          <span className="StOpcVal">{Translate("titles", "shop")}</span>
          <div className="Inlflx StWdDst StOpcVal">
            {Translate("orders", "flech")}
          </div>
          <Link to="/shop/settings">
            <span className="StOpcVal">{Translate("titles", "settings")}</span>
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="CrsPoi">{Translate("shop", "setupShop")}</span>
        </div>
      }
    >
      <div className="InFlx flxDrc responsFlx FlWd JstfCnt">
        <div className="StVl2dt ">
          {shopLoading ? (
            <div className="RlPs" style={{ paddingTop: "60px" }}>
              <TableLoader />
            </div>
          ) : (
            <div className="ShopPublishedSuccess">
              {shopPublishedSvg}
              <h1>{Translate("shop", "congratulation")}</h1>
              <h2>{Translate("shop", "shopPublishedMessage")}</h2>
              <Link to="/shop/landing-page">
                <Button
                  BtnText={Translate("shop", "startAddProduct")}
                  onClick={() => {
                    Event("SHOP", "SHOP_START_ADDING_PRODUCTS", "CLICK_EVENT");
                  }}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </ContainerPage>
  );
};

function mapState(state: any) {
  const { shopLoading, dataShop } = state.shop;
  return { shopLoading, dataShop };
}

const actionCreators = {
  GetShopInfo: shopActions.GetShopInfo,
};

export default connect(mapState, actionCreators)(ShopPublishedSuccess);

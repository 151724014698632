import React, { Component } from "react";
import BtnLg from "./BtnLg";
import { Link } from "react-router-dom";
class EmptyScreen extends Component {
  render() {
    return (
      <div className="FlWd StTpNl StlfNl">
        <div className="AlgnItm JstfCnt InFlx" style={{ margin: "5% 0" }}>
          <div className="TxtCn">
            <img
              src={
                this.props.PictShow
                  ? this.props.PictShow
                  : "/assets/img/EmptySc.png"
              }
              alt="under_dev_img"
              width="350"
            />
            <div className="TxtCn">
              <h2>{this.props.titleGlb}</h2>
              <p>{this.props.TextShow}</p>
            </div>
            {this.props.TxtBtn && (
              <Link
                onClick={this.props.FuncCall}
                to={
                  typeof this.props.FuncCall === "string" && this.props.FuncCall
                }
              >
                <div className="TxtCn StMarMx">
                  <BtnLg
                    style={{
                      width: "80%",
                      padding: "8px 22px",
                      margin: "0 auto",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    text={this.props.TxtBtn}
                  />
                </div>
              </Link>
            )}
            {this.props.actionBtns &&
              this.props.actionBtns.length &&
              this.props.actionBtns.map((Btn) =>
                Btn.to ? (
                  <Link
                    to={Btn.to}
                    onClick={() => {
                      if (Btn.event) Btn.event();
                    }}
                  >
                    <div className="TxtCn StMarMx">
                      <BtnLg
                        style={{
                          width: "80%",
                          padding: "8px 22px",
                          margin: "0 auto",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        text={Btn.text}
                        classSet={Btn.className}
                      />
                    </div>
                  </Link>
                ) : (
                  <div className="TxtCn StMarMx CrsPoi" onClick={Btn.onClick}>
                    <BtnLg
                      style={{
                        width: "80%",
                        padding: "8px 22px",
                        margin: "0 auto",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      text={Btn.text}
                      classSet={Btn.className}
                    />
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    );
  }
}
export { EmptyScreen };

import React from "react";
import { Event } from "../_Common";
import { PricingOption } from "../../../types";
import { DeletSvg, EditSvg } from "../_Common/IconSvg";
import { DEFAULT_PRICING_OPTION } from "../../../constants";
import { Translate } from "../../../utils/lang/translate";

const DeliveryPricingOptionSelector: React.FC<{
  pricingOptions: PricingOption[];
  selectedId: string | null;
  handleSelectorClick: (id: string) => void;
  handleDeleteButton: (id: string) => void;
  handleModifyButton: (pr: PricingOption) => void;
}> = ({
  pricingOptions,
  selectedId,
  handleSelectorClick,
  handleDeleteButton,
  handleModifyButton,
}) => {
  return (
    <div className="DeliveryPricingOptionSelector">
      {pricingOptions.map((pr) => {
        const isSelected = pr.id === selectedId;
        const selectedClassName = isSelected ? "bgselectstock" : "";
        const isDefault = pr.id === DEFAULT_PRICING_OPTION;
        return (
          <div
            key={pr.id}
            className={`groupPricing ${selectedClassName}`}
            onClick={() => handleSelectorClick(pr.id)}
          >
            <div>
              {isDefault ? Translate("shop", "defaultPricingOption") : pr.name}
            </div>
            {!isDefault && (
              <div className="InFlx">
                <div
                  className="InFlx AlgnItm"
                  onClick={() => handleModifyButton(pr)}
                >
                  {EditSvg}
                </div>
                <div
                  className="InFlx AlgnItm"
                  onClick={() => {
                    Event(
                      "SHOP",
                      "DELETE_PRICING_OPTION_BUTTON",
                      "CLICK_EVENT"
                    );
                    handleDeleteButton(pr.id);
                  }}
                >
                  {DeletSvg}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DeliveryPricingOptionSelector;

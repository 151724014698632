import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  productActions,
  ordersActions,
  commonActions,
  alertActions,
} from "../../../actions";
import ContainerPage from "../_Common/ContainerPage";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { Translate } from "../../../utils/lang/translate";
import GroupeForm from "../../_Common/GroupeForm";
import SelectForm from "../../_Common/SelectForm";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import Button from "../../_Common/Button";
import Loader from "../../_Common/Loader";
import { Link, Redirect } from "react-router-dom";
import { initGAPg, Event, Select } from "../_Common";
import { CloseSvg } from "../_Common/IconSvg";
import {
  checkPhoneNumberValidity,
  checkUserCanCreateOrderWithoutProduct,
  getParamsFromUrl,
} from "../../../utils/helpers";
import Cookies from "js-cookie";
import {
  CommuneInterface,
  ErrorInterface,
  OrderProductInterface,
  ProductInterface,
  SubmitOrderInterface,
  wilayaInterface,
} from "../../../types";

const lang = Cookies.get("lang");

interface AllowedEditionsInterface {
  edit_products?: boolean;
  edit_customer_infos?: boolean;
  edit_delivery_infos?: boolean;
}

const handleAllowedEditions = (
  status: number,
  isPremium: boolean,
  allAllowed = false
): AllowedEditionsInterface => {
  if (allAllowed)
    return {
      edit_products: true,
      edit_customer_infos: true,
      edit_delivery_infos: true,
    };
  let allowed = {
    edit_products: false,
    edit_customer_infos: true,
    edit_delivery_infos: false,
  };
  if (isPremium) {
    if ([8, 9, 10, 11, 12, 15, 22, 50].indexOf(status) > -1) {
      allowed.edit_products = true;
      if (status !== 8) allowed.edit_delivery_infos = true;
    }
  } else {
    if ([4, 5, 6].indexOf(status) > -1) {
      allowed.edit_products = true;
      allowed.edit_delivery_infos = true;
    }
  }

  return allowed;
};

const INITIAL_PRODUCT: OrderProductInterface = {
  product_id: "",
  quantity: 1,
  logistical_description: "",
};

const AddOrderPage = ({
  data,
  OrdersList,
  dataAddOrder,
  dataDelivery,
  dataProfile,
  dataUpInfOrd,
  DataUpdateOrder,
  DataCommune,
  dataInfOrd,
  dtStoreinfo,
  DataSearch,
  history,
  match,
  ProductFinded,
  GetingDelivery,
  GetinunfOrd,
  Getcommunes,
  GetOrders,
  GetAllProduct,
  OrderGeting,
  Searching,
  OrderAdded,
  UpdateUnfOrder,
  AddOrder,
  UpdateOrder,
  UpdinunfOrd,
  OrderUpdating,
  SendAlert,
  ClearStore,
  GetCountry,
  GetDelivery,
  Getwilayas,
  GetUnfOrders,
  GetingWilaya,
  dataWilaya,
  GetigCommune,
}: any) => {
  const [orderId, setOrderId] = useState<string | null>(null);
  const [products, setProducts] = useState([INITIAL_PRODUCT]);
  const [customerName, setCustomerName] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [commune, setCommune] = useState<CommuneInterface | null>(null);
  const [wilaya, setWilaya] = useState<(number | string)[] | null>(null);
  const [orderPrice, setOrderPrice] = useState(0);
  const [noteToDriver, setNoteToDriver] = useState("");
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [error, setError] = useState<ErrorInterface>({});
  const [isExpressDelivery, setIsExpressDelivery] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [productList, setProductList] = useState<ProductInterface[]>([]);
  const [filterSource, setFilterSource] = useState(1);
  const [pageTitle, setPageTitle] = useState(
    Translate("orders", "addneworder")
  );
  const [buttonTitle, setButtonTitle] = useState(
    Translate("sidebar", "addorder")
  );
  const [allowedEditions, setAllowedEditions] =
    useState<AllowedEditionsInterface>({});
  const [externalId, setExternalId] = useState("");
  const [isShopOrder, setIsShopOrder] = useState(false);
  const [externalWilaya, setExternalWilaya] = useState<string | null>(null);
  const [externalCommune, setExternalCommune] = useState<
    string | number | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<number | null>(null);
  const [alertMessage, setAlertMessage] = useState("");
  const hasAddPermission = dataProfile.is_store || dataProfile.add_order;
  const hasEditPermission = dataProfile.is_store || dataProfile.change_order;

  const source = useMemo(
    () => getParamsFromUrl("source"),
    [window.location.href]
  );

  useEffect(() => {
    document.title = "Maystro Delivrey - " + Translate("titles", "order");
    GetCountry();
    if (source === "order") GetOrders("");
    if (source === "unfOrder") GetUnfOrders("");
    return () => {
      if (UpdinunfOrd === "3") ClearStore("CLEAR_UNF_ORD");
      ClearStore("CLEAR_COMMON");
    };
  }, []);

  useEffect(() => {
    if (dtStoreinfo?.country?.id) Getwilayas(dtStoreinfo.country.id);
  }, [dtStoreinfo]);

  useEffect(() => {
    if (ProductFinded === "2") {
      setProductList(data.list);
    }
  }, [ProductFinded]);

  useEffect(() => {
    if (GetingDelivery === "2") {
      setDeliveryPrice(dataDelivery.delivery_price);
      setOrderPrice(totalPrice - dataDelivery.delivery_price);
    }
  }, [GetingDelivery]);

  useEffect(() => {
    if (
      (OrderAdded === "3" || UpdinunfOrd === "3" || OrderUpdating === "3") &&
      isSubmitting
    ) {
      document.getElementById("Container-root")?.scrollTo(0, 0);
      if (dataAddOrder?.response?.data?.detail)
        setAlertMessage(Translate("error", dataAddOrder.response.data.detail));
      if (dataUpInfOrd.response.data.detail)
        setAlertMessage(Translate("error", dataUpInfOrd.response.data.detail));
      if (DataUpdateOrder?.response?.data?.detail)
        setAlertMessage(
          Translate("error", DataUpdateOrder.response.data.detail)
        );
      setIsSubmitting(false);
      setError({});
      SendAlert("50", Translate("alert", "failaddorde"), "a02");
    }
    if (OrderAdded === "2") {
      GetOrders("");
      SendAlert("41", Translate("alert", "ordadd"), "a01");
      history.push("/orders");
    }
    if (UpdinunfOrd === "2") {
      GetOrders("");
      SendAlert("41", Translate("alert", "ordadd"), "a03");
      history.push("/unfulfilled/order" + filterSource);
    }
    if (OrderUpdating === "2") {
      SendAlert("41", Translate("alert", "orupd"), "a01");
      history.push((source === "order" ? "/orders" : "/search") + filterSource);
    }
  }, [OrderAdded, UpdinunfOrd, OrderUpdating]);

  useEffect(() => {}, [OrderUpdating]);

  useEffect(() => {
    if (externalCommune && Array.isArray(DataCommune)) {
      const commune = DataCommune.find(
        (elem) => elem.id === externalCommune || elem.name === externalCommune
      );
      handleCommuneSelect(commune);
      setExternalCommune(null);
    }
  }, [externalCommune, DataCommune]);

  useEffect(() => {
    if (externalWilaya && GetingWilaya === "1" && Array.isArray(dataWilaya)) {
      const wilaya = dataWilaya.find((wilaya) => wilaya[1] === externalWilaya);
      handleWilayaSelect(wilaya);
      setExternalWilaya(null);
    }
  }, [externalWilaya, GetingWilaya]);

  useEffect(() => {
    const { idorder } = match.params;
    if (idorder !== "new") {
      let orderData: any = null;
      let externalTitle, filterSource;
      let newCommune: number | string;
      if (source === "order") {
        if (OrderGeting === "2") {
          orderData = OrdersList.ressuc.list.results.find(
            (order: any) => order.display_id.toString() === idorder
          );
          if (!orderData) history.push("/orders");
          else {
            filterSource = OrdersList.filter;
            newCommune = orderData.commune;
            externalTitle = (
              <div className="StDrc">
                <Link className="StOpcVal" to={"/orders" + filterSource}>
                  {Translate("titles", "order")}
                </Link>
                <div className="Inlflx StWdDst">
                  {Translate("orders", "flech")}
                </div>
                <div className="Inlflx">{Translate("orders", "editorder")}</div>
                <div className="Inlflx StWdDst">#</div>
                <div className="Inlflx">{orderData.display_id}</div>
              </div>
            );
          }
        }
      } else if (source === "unfOrder") {
        if (GetinunfOrd === "2") {
          orderData = dataInfOrd.ressuc.results.find(
            (order: any) => order.display_id.toString() === idorder
          );
          filterSource = dataInfOrd.filter;
          newCommune = orderData.commune;
          externalTitle = (
            <div className="StDrc">
              <Link
                className="StOpcVal"
                to={"/unfulfilled/order" + filterSource}
              >
                {Translate("titles", "unfulfilledorder")}
              </Link>
              <div className="Inlflx StWdDst">
                {Translate("orders", "flech")}
              </div>
              <div className="Inlflx">{Translate("orders", "acceptorder")}</div>
              <div className="Inlflx StWdDst">#</div>
              <div className="Inlflx">{orderData.display_id}</div>
            </div>
          );
        }
      } else if (source === "search") {
        if (Searching !== "1") {
          history.push("/search");
        } else {
          orderData = DataSearch.ressuc.results.find(
            (order: any) => order.display_id.toString() === idorder
          );
          filterSource = DataSearch.filter;
          newCommune = orderData.commune_name;
          externalTitle = (
            <div className="StDrc">
              <Link className="StOpcVal" to={"/search" + filterSource}>
                {Translate("titles", "search")}
              </Link>
              <div className="Inlflx StWdDst">
                {Translate("orders", "flech")}
              </div>
              <div className="Inlflx">{Translate("orders", "editorder")}</div>
              <div className="Inlflx StWdDst">#</div>
              <div className="Inlflx">{orderData.display_id}</div>
            </div>
          );
        }
      }
      if (orderData) {
        setExternalWilaya(orderData.wilaya);
        setExternalCommune(newCommune!);
        setOrderId(orderData.id);
        setExternalId(orderData.external_order_id);
        setProducts(orderData.products);
        setCustomerName(orderData.customer_name);
        setCustomerPhoneNumber(orderData.customer_phone);
        setAddress(orderData.destination_text);
        setOrderPrice(orderData.product_price);
        setNoteToDriver(orderData.note_to_driver);
        setDeliveryPrice(orderData.price ?? 0);
        setTotalPrice((orderData.price ?? 0) + orderData.product_price);
        setIsExpressDelivery(orderData.express);
        setIsNotFound(false);
        setStatus(orderData.status);
        setPageTitle(externalTitle);
        setButtonTitle(Translate("orders", "save"));
        setFilterSource(filterSource);
        setAllowedEditions(
          handleAllowedEditions(
            orderData.status,
            dtStoreinfo.stock_managed,
            orderData.source === "Maystro_Shop" ? true : false
          )
        );
        setIsShopOrder(orderData.source === "Maystro_Shop");
      }
    } else {
      // If it's a new order to be created, all edition are allowed of course
      setAllowedEditions(handleAllowedEditions(0, false, true));
      setWilaya(null);
      setCommune(null);
      setOrderId(null);
      setExternalId("");
      setProducts([INITIAL_PRODUCT]);
      setCustomerName("");
      setCustomerPhoneNumber("");
      setAddress("");
      setOrderPrice(0);
      setNoteToDriver("");
      setDeliveryPrice(0);
      setTotalPrice(0);
      setIsExpressDelivery(false);
      setIsNotFound(false);
      setStatus(0);
      setPageTitle(Translate("orders", "addneworder"));
      setButtonTitle(Translate("sidebar", "addorder"));
      setFilterSource(0);
    }
    Getwilayas(dtStoreinfo?.country?.id);
    if (ProductFinded !== "2") {
      GetAllProduct();
    }
  }, [OrderGeting, GetinunfOrd, window.location.href]);

  useEffect(() => {
    if (wilaya) Getcommunes("?wilaya=" + wilaya[0]);
  }, [wilaya]);

  const handleChange = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (name === "express_delivery") {
      if (commune?.id) {
        GetDelivery(commune.id, value);
      }
      setIsExpressDelivery(value === "true");
    }
    if (name === "orderprice") {
      const prix = value === "" ? 0 : parseInt(value.replace(/\D/g, ""));
      setOrderPrice(prix >= deliveryPrice ? prix - deliveryPrice : 0);
      setTotalPrice(prix);
    }
  };

  const handleChangeProduct = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    let updatesProducts = [...products];
    const tabn = name.split("_");
    const index = parseInt(tabn[1]);

    if (tabn[0] !== "productname")
      updatesProducts[index - 1].quantity = parseInt(value);
    else {
      const theProduct = productList.find(
        (prod) => prod.logistical_description === value
      );
      if (theProduct) {
        updatesProducts[index - 1].product_id = theProduct.id;
        setIsNotFound(false);
      } else {
        updatesProducts[index - 1].product_id = "";
        setIsNotFound(true);
      }
      updatesProducts[index - 1].logistical_description = value;
    }
    setProducts(updatesProducts);
  };

  const handleDescriptionWithoutProductId = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    let updatesProducts = [...products];
    const tabn = name.split("_");
    const index = parseInt(tabn[1]);

    updatesProducts[index - 1].logistical_description = value;
    updatesProducts[index - 1].product_id = "";
    setProducts(updatesProducts);
    setIsNotFound(false);
  };

  const handleCommuneSelect = (newCommune: CommuneInterface) => {
    if (newCommune) GetDelivery(newCommune.id, isExpressDelivery);
    setCommune(newCommune);
  };

  const handleWilayaSelect = (newWilaya: wilayaInterface) => {
    if (newWilaya && (!wilaya || wilaya[0] !== newWilaya[0])) {
      if (!externalWilaya) setCommune(null);
      setWilaya(newWilaya);
      Getcommunes("?wilaya=" + newWilaya[0]);
    }
    if (!newWilaya) {
      if (!externalWilaya) setCommune(null);
      setWilaya(null);
    }
  };

  const AddFieldProduct = () => {
    Event("ADD_ORDER", "USE_ADD_PRODUCT_BUTTON", "CLICK_EVENT");
    setProducts([
      ...products,
      { product_id: "", quantity: 1, logistical_description: "" },
    ]);
  };

  const RemoveFieldProduct = (index: number) => {
    Event("ADD_ORDER", "USE_REMOVE_PRODUCT_BUTTON", "CLICK_EVENT");
    if (products.length > 1) {
      let updatedProducts = [...products];
      updatedProducts.splice(index, 1);
      setProducts(updatedProducts);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    Event("ADD_EXCHANGE", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
    let isError = false;
    let order: SubmitOrderInterface = {},
      newError: ErrorInterface = {};
    if (wilaya && typeof wilaya[0] === "number") {
      order.wilaya = wilaya[0];
    } else {
      newError.wilaya = Translate("error", "eo1");
      isError = true;
    }
    if (commune?.id) {
      order.commune = commune.id;
    } else {
      newError.commune = Translate("error", "eo2");
      isError = true;
    }
    if (address) {
      order.destination_text = address;
    } else {
      newError.address = Translate("error", "eo7");
      isError = true;
    }
    if (customerPhoneNumber) {
      order.customer_phone = customerPhoneNumber;
    } else {
      newError.phoneNumber = Translate("error", "eo5");
      isError = true;
    }
    if (customerName) {
      order.customer_name = customerName;
    } else {
      newError.name = Translate("error", "eo4");
      isError = true;
    }
    if (totalPrice >= 0) {
      order.product_price = totalPrice;
    } else {
      newError.price = Translate("error", "eo6");
      isError = true;
    }
    if (externalId) {
      order.external_order_id = externalId;
    }
    if (isError) {
      Event(
        "ADD_ORDER",
        "ERROR_INFORMATION_ENTER",
        `[${Object.keys(newError).join(",")}]`
      );
      setError(newError);
      return;
    }

    if (!isNotFound) {
      setIsSubmitting(true);
      order.source = 1;
      order.express = isExpressDelivery;
      if (status === 50) order.status = 11;
      order.note_to_driver = noteToDriver;
      order.products = products;
      if (hasEditPermission) {
        switch (source) {
          case "unfOrder":
            Event("UPDATE_ORDER", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
            if (isShopOrder)
              Event(
                "UPDATE_ORDER",
                "CONFIRM_SHOP_UNFULFILLED_ORDER",
                "CLICK_EVENT"
              );
            order.status = 1;
            UpdateUnfOrder(order, orderId);
            break;
          case "search":
          case "order":
            Event("UPDATE_ORDER", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
            UpdateOrder(order, orderId);
            break;
          default:
            break;
        }
      }
      if (hasAddPermission && !source) {
        Event("ADD_ORDER", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
        AddOrder(order);
      }
    }
  };

  if (!hasAddPermission && !source) {
    return (
      <ContainerPage page_title={Translate("orders", "addneworder")}>
        <FeatureLoked />
      </ContainerPage>
    );
  }
  if (!hasEditPermission && source) {
    return (
      <ContainerPage page_title={Translate("orders", "editorder")}>
        <FeatureLoked />
      </ContainerPage>
    );
  }

  const isLoading =
    OrderAdded === "1" ||
    OrderUpdating === "1" ||
    UpdinunfOrd === "1" ||
    ProductFinded !== "2";

  return (
    <ContainerPage page_title={pageTitle}>
      {isLoading && <Loader />}
      <div className="ClSidTh StBrdTb StBgbrds widthAdd">
        <div className="FlWd TxtCn StRedClr">{alertMessage}</div>
        <form method="post" onSubmit={handleSubmit} autoComplete="off">
          {allowedEditions.edit_products && (
            <ContainerPage
              subContainer
              page_title={Translate("orders", "orderinfo")}
              small_title={true}
            >
              {Array.isArray(products) &&
                products.map((product, key) => {
                  return (
                    <div key={key}>
                      <div className="FlWd InFlx responsFlx flxDrc">
                        <div className="Hlwd">
                          {checkUserCanCreateOrderWithoutProduct(
                            dtStoreinfo?.id
                          ) ? (
                            <GroupeForm
                              id={"productname_" + (key + 1)}
                              name={"productname_" + (key + 1)}
                              // index={"logistical_description"}
                              placeholder={
                                ProductFinded === "2"
                                  ? Translate("product", "productname")
                                  : Translate("alert", "load")
                              }
                              text={Translate("product", "productname")}
                              value={products[key].logistical_description}
                              workfun={handleDescriptionWithoutProductId}
                              style={{ width: "80%" }}
                              stclass={
                                !products[key].logistical_description
                                  ? "borderError"
                                  : ""
                              }
                            />
                          ) : (
                            <SelectFormSearch
                              id={"productname_" + (key + 1)}
                              name={"productname_" + (key + 1)}
                              index={"logistical_description"}
                              option={productList}
                              text={Translate("product", "productname")}
                              workfun={handleChangeProduct}
                              placeholder={
                                ProductFinded === "2"
                                  ? Translate("product", "productname")
                                  : Translate("alert", "load")
                              }
                              value={products[key].logistical_description}
                            />
                          )}
                        </div>
                        <div className="Hlwd Mrg2 RlPs">
                          <div className="Flwd InFlx flxDrc AlgnItm">
                            <GroupeForm
                              id={"quantity_" + (key + 1)}
                              name={"quantity_" + (key + 1)}
                              placeholder={Translate("orders", "quantity")}
                              text={Translate("orders", "quantity")}
                              type={"number"}
                              value={products[key].quantity}
                              min={1}
                              workfun={handleChangeProduct}
                              style={{ width: "80%" }}
                              stclass={
                                !products[key].quantity ? "borderError" : ""
                              }
                            />
                            {products.length > 1 ? (
                              <div
                                className="CrsPoi InFlx JstfCnt"
                                style={{
                                  alignItems: "flex-end",
                                  margin: "5% 0% 0% 2%",
                                }}
                                onClick={() => RemoveFieldProduct(key)}
                              >
                                {CloseSvg}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* <span>{error?.erproduct}</span> */}
              {isNotFound && (
                <div className="FlWd StdirLn">
                  <span className="CrsPoi">
                    {" " + Translate("product", "prdntfond")}
                  </span>
                  <Link to="/stock/products/add" className="CrsPoi StBle">
                    {" " + Translate("product", "addnewproduct")}
                  </Link>
                </div>
              )}
              <div className="FlWd StdirLn">
                <span className="StBle CrsPoi" onClick={AddFieldProduct}>
                  +{" " + Translate("orders", "addanotherproduct")}
                </span>
              </div>
            </ContainerPage>
          )}
          <ContainerPage subContainer>
            <div className="Hlwd">
              <GroupeForm
                id={"external_id"}
                name={"external_id"}
                placeholder={Translate("orders", "External ID")}
                text={Translate("orders", "External ID (optionnal)")}
                type={"text"}
                value={externalId}
                workfun={(e: React.ChangeEvent) => {
                  const { value } = e.target as HTMLInputElement;
                  setExternalId(value);
                }}
              />
            </div>
          </ContainerPage>
          <ContainerPage
            subContainer
            page_title={Translate("orders", "clientinfo")}
            small_title={true}
          >
            <div className="FlWd InFlx responsFlx flxDrc">
              <div className="Hlwd">
                <GroupeForm
                  id={"customername"}
                  name={"customername"}
                  placeholder={Translate("orders", "customername")}
                  text={Translate("orders", "customername")}
                  type={"text"}
                  value={customerName}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setCustomerName(value);
                  }}
                  error={error.name}
                  stclass={!customerName ? "borderError" : ""}
                />
              </div>
              <div className="Hlwd Mrg2">
                <GroupeForm
                  id={"customerphonenumber"}
                  name={"customerphonenumber"}
                  placeholder={Translate("orders", "customerphonenumber")}
                  text={Translate("orders", "customerphonenumber")}
                  type={"tel"}
                  pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
                  value={customerPhoneNumber}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setCustomerPhoneNumber(value);
                  }}
                  error={error.phoneNumber}
                  stclass={
                    !checkPhoneNumberValidity(customerPhoneNumber!)
                      ? "borderError"
                      : ""
                  }
                />
              </div>
            </div>
          </ContainerPage>
          <ContainerPage
            subContainer
            withOvrl={true}
            page_title={Translate("orders", "deliverydetails")}
            small_title={true}
          >
            <div
              className="FlWd InFlx responsFlx flxDrc"
              style={{ height: "100%" }}
            >
              <div className="Hlwd">
                <GroupeForm
                  id={"adresse"}
                  name={"adresse"}
                  placeholder={Translate("orders", "adresse")}
                  text={Translate("orders", "adresse")}
                  type={"text"}
                  value={address}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setAddress(value);
                  }}
                  error={error.address}
                  stclass={!address ? "borderError" : ""}
                />
                <div
                  className="FlWd InFlx responsFlx flxDrc"
                  style={{ margin: "28px 0 28px 0" }}
                >
                  <div className="Hlwd">
                    <Select
                      label={Translate("orders", "city")}
                      placholder={Translate("orders", "citySearch")}
                      search={true}
                      Options={dataWilaya}
                      fieldShow={1}
                      name="city"
                      loading={GetingWilaya === "0"}
                      value={wilaya}
                      onChange={
                        allowedEditions.edit_delivery_infos
                          ? handleWilayaSelect
                          : () => null
                      }
                      maxSize="200px"
                      readOnly={!allowedEditions.edit_delivery_infos}
                      disabled={true}
                      containerClass={!wilaya ? "borderError" : ""}
                    />
                  </div>
                  <div className="Hlwd Mrg2">
                    <Select
                      label={Translate("orders", "district")}
                      placholder={Translate("orders", "districtSearch")}
                      search={true}
                      Options={DataCommune}
                      fieldShow={"name"}
                      name="district"
                      loading={GetigCommune === "0"}
                      value={commune}
                      onChange={handleCommuneSelect}
                      maxSize="200px"
                      containerClass={!commune ? "borderError" : ""}
                    />
                  </div>
                </div>
                <GroupeForm
                  id={"orderprice"}
                  name={"orderprice"}
                  placeholder={Translate("orders", "totalprice")}
                  text={Translate("orders", "totalprice")}
                  type={"text"}
                  value={totalPrice}
                  workfun={handleChange}
                  error={error.price}
                  stclass={!totalPrice ? "borderError" : ""}
                />
              </div>
              <div className="Hlwd Mrg2">
                <GroupeForm
                  id={"notetodriver"}
                  name={"notetodriver"}
                  placeholder={Translate("orders", "notetodriver")}
                  text={Translate("orders", "notetodriver")}
                  type={"text"}
                  value={noteToDriver}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setNoteToDriver(value);
                  }}
                  textarea={true}
                />
                {wilaya && wilaya[0] === 16 ? (
                  <div className="SmMarS2">
                    <SelectForm
                      id={"expressdelivery"}
                      name={"express_delivery"}
                      option={[
                        { text: Translate("orders", "no"), val: false },
                        { text: Translate("orders", "yes"), val: true },
                      ]}
                      value={isExpressDelivery}
                      text={Translate("orders", "expressdelivery")}
                      workfun={handleChange}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </ContainerPage>
          <span
            className="DsBlc FlWd"
            style={{
              height: "1px",
              backgroundColor: "var(--fntClr)",
              opacity: ".2",
            }}
          ></span>
          <div className="FlWd TxtCn StRedClr">
            {commune &&
              !commune.id &&
              commune.name &&
              Translate("error", "Order out of our service")}
          </div>
          <div className="StMarMx stbold">
            <div className="PsinLan SmMarS">
              <span>{Translate("orders", "orderprice") + " :"}</span>
              <span className="Mrg2">
                {lang == "ar"
                  ? orderPrice + "   " + dtStoreinfo.country.ar_currency
                  : orderPrice + "   " + dtStoreinfo.country.lt_currency}
              </span>
            </div>
            <div className="PsinLan SmMarS">
              <span>{Translate("orders", "deliveryprice") + " :"}</span>
              <span className="Mrg2">
                {lang == "ar"
                  ? deliveryPrice + "   " + dtStoreinfo.country.ar_currency
                  : deliveryPrice + "   " + dtStoreinfo.country.lt_currency}
              </span>
            </div>
            <div className="PsinLan SmMarS">
              <span>{Translate("orders", "totalprice") + " :"}</span>
              <span className="Mrg2">
                {lang == "ar"
                  ? totalPrice + "   " + dtStoreinfo.country.ar_currency
                  : totalPrice + "   " + dtStoreinfo.country.lt_currency}
              </span>
            </div>
          </div>

          <div className="MrAot StMarMx responseDiv" style={{ width: "25%" }}>
            <Button BtnText={buttonTitle} />
          </div>
        </form>
      </div>
    </ContainerPage>
  );
};
function mapState(state: any) {
  const {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GetingDelivery,
    dataDelivery,
    dataCountry,
    GetingPays,
  } = state.common;
  const { ProductFinded, data } = state.product;
  const {
    OrderAdded,
    dataAddOrder,
    OrderGeting,
    OrdersList,
    OrderUpdating,
    DataUpdateOrder,
  } = state.orders;
  const { GetinunfOrd, dataInfOrd, UpdinunfOrd, dataUpInfOrd } =
    state.unfOrders;
  const { DataSearch, Searching } = state.search;
  const { dataProfile, dtStoreinfo } = state.user;
  return {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    data,
    ProductFinded,
    OrderAdded,
    dataAddOrder,
    GetingDelivery,
    dataDelivery,
    OrderGeting,
    OrdersList,
    OrderUpdating,
    DataUpdateOrder,
    GetinunfOrd,
    dataInfOrd,
    UpdinunfOrd,
    dataUpInfOrd,
    DataSearch,
    Searching,
    dataProfile,
    dtStoreinfo,
    dataCountry,
    GetingPays,
  };
}

const actionCreators = {
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetAllProduct: productActions.GetAllProduct,
  GetDelivery: commonActions.GetDelivery,
  AddOrder: ordersActions.AddOrder,
  UpdateOrder: ordersActions.UpdateOrder,
  GetOrders: ordersActions.GetOrders,
  GetUnfOrders: ordersActions.GetUnfOrders,
  UpdateUnfOrder: ordersActions.UpdateUnfOrder,
  SendAlert: alertActions.SendAlert,
  ClearStore: commonActions.ClearStore,
  GetCountry: commonActions.GetCountry,
};

const connectedAddOrderPage = connect(mapState, actionCreators)(AddOrderPage);
export { connectedAddOrderPage as AddOrderPage };

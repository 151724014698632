import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import {
  Translate,
  FormatCurrency,
  FormatDate,
} from "../../../utils/lang/translate";
import StatusElement from "../_Common/StatusElement";
import Table from "../_Common/Table";
import BtnLg from "../_Common/BtnLg";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import {
  FlechDwnSvg,
  FilterSvg,
  PlayListAddSvg,
  FileDownSvg,
  ExcelSvg,
  CloseSvg,
  RefreshSvg,
  FileUpSvg,
  HourglassSvg,
  CallSvg,
  PostpondSvg,
  DelivredSvg,
  CancledSvg,
  DoneSvg,
} from "../_Common/IconSvg";
import InlinedElement from "../_Common/InlinedElement";
import {
  userActions,
  ordersActions,
  alertActions,
  commonActions,
  productActions,
} from "../../../actions";
import TableLoader from "../_Common/TableLoader";
import Loader from "../../_Common/Loader";
import TabShow from "../_Common/TabShow";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import SmallText from "../_Common/SmallText";
import { EmptyScreen } from "../_Common/EmptyScreen";
import DropedList from "../_Common/DropedList";
import PaginationBar from "../_Common/PaginationBar";
import { FeatureLoked } from "../_Common/FeatureLoked";
import PropTypes from "prop-types";
import { addUrlProps, UrlQueryParamTypes, Serialize } from "react-url-query";
import { Link } from "react-router-dom";
import { initGAPg, Event } from "../_Common";
import ElmentStatus from "../DashboardPage/ElmentStatus";
import StatLoader from "../DashboardPage/StatLoader";
import moment from "moment";
import {
  CONFIRMATION_STATUS_LIST,
  CONFIRMATION_TABLE_HEADER,
} from "../../constants";
import { CONFIRMATION_HELPERS } from "../../helpers/functions";
import DatePicker from "../../_Common/DatePicker";
const DotSeparatedQueryParamType = {
  encode: (val) => Serialize.encodeArray(val, ","),
  decode: (val) => Serialize.decodeArray(val, ","),
};
const urlPropsQueryConfig = {
  status_in: { type: DotSeparatedQueryParamType, queryParam: "status__in" },
  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
  page: { type: UrlQueryParamTypes.number },
  wilaya: { type: DotSeparatedQueryParamType, queryParam: "wilaya" },
  commune: { type: DotSeparatedQueryParamType, queryParam: "commune" },
  products: { type: DotSeparatedQueryParamType, queryParam: "products" },
  display_id: { type: UrlQueryParamTypes.string },
};

class ConfirmationPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orders:
        this.props.GettingConfirmOrders === "1"
          ? this.props.ConfOrdersList.ressuc.results
          : [],
      nextpage:
        this.props.GettingConfirmOrders === "1"
          ? this.props.ConfOrdersList.ressuc.next
          : null,
      prevpage:
        this.props.GettingConfirmOrders === "1"
          ? this.props.ConfOrdersList.ressuc.previous
          : null,
      count:
        this.props.GettingConfirmOrders === "1"
          ? this.props.ConfOrdersList.ressuc.count
          : 0,
      data: [],
      statusList: CONFIRMATION_STATUS_LIST,
      alertdelet: null,
      premmision:
        this.props.dataProfile.is_store || this.props.dataProfile.view_order,
      premisionedit:
        this.props.dataProfile.is_store || this.props.dataProfile.change_order,
    };
    this.crntfilter =
      this.props.GettingConfirmOrders === "1"
        ? this.props.ConfOrdersList.filter
        : "";
    this.displayid = null;
    this.selectIdlist = [];
    this.submit = false;
    this.refrs = {
      Status_Container: React.createRef(),
      wilaya_Container: React.createRef(),
      commune_Container: React.createRef(),
      product_Container: React.createRef(),
    };
    this.evnfunc = {
      Status_Container: (e) => {
        if (
          this.refrs.Status_Container &&
          !this.refrs.Status_Container.current.contains(e.target)
        ) {
          this.ControlFilter("Status_Container");
        }
      },
      wilaya_Container: (e) => {
        if (
          this.refrs.wilaya_Container &&
          !this.refrs.wilaya_Container.current.contains(e.target)
        ) {
          this.ControlFilter("wilaya_Container");
        }
      },
      commune_Container: (e) => {
        if (
          this.refrs.commune_Container &&
          !this.refrs.commune_Container.current.contains(e.target)
        ) {
          this.ControlFilter("commune_Container");
        }
      },
      product_Container: (e) => {
        if (
          this.refrs.product_Container &&
          !this.refrs.product_Container.current.contains(e.target)
        ) {
          this.ControlFilter("product_Container");
        }
      },
    };
    this.FilterRef = React.createRef();
    this.ExpRef = React.createRef();
    this.resFilter = false;
    this.CallProps = false;
    this.Gotodetail = this.Gotodetail.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.RemoveOrder = this.RemoveOrder.bind(this);
    this.controlExport = this.controlExport.bind(this);
    this.controlMore = this.controlMore.bind(this);
    this.handleClickOutsideExpRef = this.handleClickOutsideExpRef.bind(this);
    this.ShowFilter = this.ShowFilter.bind(this);
    this.handleClickOutsideFilter = this.handleClickOutsideFilter.bind(this);
    this.setOptionChnage = this.setOptionChnage.bind(this);
    this.CallPropsS = this.CallPropsS.bind(this);
  }
  static defaultProps = {
    status_in: [],
    wilaya: [],
    commune: [],
    products: [],
    page: 1,
  };
  static propTypes = {
    status_in: PropTypes.array,
    began: PropTypes.string,
    end: PropTypes.string,
    page: PropTypes.number,
    display_id: PropTypes.string,
    products: PropTypes.array,
    wilaya: PropTypes.array,
    commune: PropTypes.array,
    /********************** */
    onChangeBegan: PropTypes.func,
    onChangeEnd: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangeStatus_in: PropTypes.func,
    onChangeWilaya: PropTypes.func,
    onChangeCommune: PropTypes.func,
    onChangeProducts: PropTypes.func,
    onChangeDisplay_id: PropTypes.func,
  };
  componentWillReceiveProps(nextProps) {
    const {
      GettingConfirmOrders,
      ConfOrdersList,
      page,
      GettingSummary,
      SummaryData,
    } = nextProps;

    if (this.resFilter || this.CallProps || this.props.page !== page) {
      this.resFilter = false;
      this.CallProps = false;

      this.exctuteFilterUrl(this.getFilterToState(nextProps));
    }
    if (
      this.props.GettingSummary === "0" &&
      GettingSummary === "1" &&
      SummaryData.ressuc
    ) {
      this.setState({
        data: [
          {
            title: Translate("confirmation", "orders10"),
            status: "10",
            val: SummaryData.ressuc.pending_orders,
            icon: HourglassSvg,
          },
          {
            title: Translate("confirmation", "orders40"),
            status: "40",
            val: SummaryData.ressuc.confirmed_orders_today,
            icon: DoneSvg,
          },
          {
            title: Translate("confirmation", "orders100"),
            status: "100",
            val: SummaryData.ressuc.cancelled_orders_today,
            icon: CancledSvg,
          },
          {
            title: Translate("confirmation", "ordersdelivered"),
            status: "40",
            val: SummaryData.ressuc.delivered_orders_today,
            icon: DelivredSvg,
          },
        ],
      });
    }

    if (
      this.props.GettingConfirmOrders === "0" &&
      GettingConfirmOrders === "1" &&
      ConfOrdersList &&
      ConfOrdersList.ressuc
    ) {
      document.getElementById("Container-root").scrollTo(0, 0);
      this.setState({
        orders: ConfOrdersList.ressuc.results,
        nextpage: ConfOrdersList.ressuc.next,
        prevpage: ConfOrdersList.ressuc.previous,
        count: ConfOrdersList.ressuc.count,
      });
    }
  }
  componentDidMount() {
    document.title =
      "Maystro Delivery - " + Translate("titles", "confirmation");
    initGAPg();
    // this.props.GetStoreInfo()
    this.props.GetSummary("");
    if (this.state.premmision) {
      let nextFilter = this.getFilterToState();
      this.exctuteFilterUrl(nextFilter, true);
    }
  }
  RemoveOrder(id) {
    let idremove = this.state.alertdelet;
    if (idremove) {
      this.props.UpdateStaOrder({ status: 50, abort_reason: 21 }, idremove);
      this.setState({
        alertdelet: null,
      });
    } else {
      this.setState({
        alertdelet: id,
      });
    }
  }

  showOpt(name) {
    let act = this.state[name];
    this.setState({
      [name]: !act,
    });
  }
  Gotodetail(id) {
    Event("CONFIRMATION", "GO_TO_ORDER_DETAILS", "CLICK_EVENT_FROM_LIST");
    this.props.history.push("/confirmation/detail/" + id);
  }
  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }
  exctuteFilterUrl(nextFilter, force) {
    let prevFilter = this.crntfilter;
    if (prevFilter !== nextFilter || force) {
      this.crntfilter = nextFilter;
      const str = nextFilter;
      const newStr = str.replace("began", "created_at__gte");
      const nextFilterNew = newStr.replace("end", "created_at__lte");
      this.props.GetOrders(nextFilterNew);
    }
  }

  ShowFilter() {
    Event("CONFIRMATION", "CLICK_FILTER", "CLICK_EVENT");
    let clsList = document.getElementById("Filter_container");
    if (clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", this.handleClickOutsideFilter);
    } else {
      clsList.classList.add("hidElem2");
      document.removeEventListener("mousedown", this.handleClickOutsideFilter);
    }
  }
  handleClickOutsideFilter(event) {
    if (
      this.FilterRef &&
      this.FilterRef.current &&
      !this.FilterRef.current.contains(event.target)
    ) {
      this.ShowFilter();
    }
  }

  ControlFilter(idTraget) {
    let clsList = document.getElementById(idTraget);
    if (clsList.classList.value.indexOf("hidElem3") !== -1) {
      Event("CONFIRMATION", "DROP_DOWN_FILTER_" + idTraget, "CLICK_EVENT");
      clsList.classList.remove("hidElem3");
      clsList.style.maxHeight = "400px";
      document.addEventListener("mousedown", this.evnfunc[idTraget]);
    } else {
      Event("CONFIRMATION", "SHIFT_UP_FILTER_" + idTraget, "CLICK_EVENT");
      clsList.style.maxHeight = "0px";
      clsList.classList.add("hidElem3");
      document.removeEventListener("mousedown", this.evnfunc[idTraget]);
    }
  }
  /***  manage query */
  manageQueryStatus(statu) {
    const { status_in, onChangeStatus_in } = this.props;
    let TmpTab = status_in;
    let TmpTab2 = [...status_in, statu];
    if (TmpTab.indexOf(statu) !== -1) {
      TmpTab2 = TmpTab2.filter((elem) => elem !== statu);
    }
    this.CallProps = true;
    onChangeStatus_in(TmpTab2);
  }
  setOptionChnage(name, value) {
    if (name === "products") {
      const { products, onChangeProducts } = this.props;
      let TmpTab = products;
      let TmpTab2 = [...products, value];
      if (TmpTab.indexOf(value) !== -1) {
        TmpTab2 = TmpTab2.filter((elem) => elem !== value);
      }
      this.CallProps = true;
      onChangeProducts(TmpTab2);
    }
  }
  /****** end query */
  RefreshOredr() {
    Event("CONFIRMATION", "CLICK_IN_REFRESH_BUTTON", "CLICK_EVENT");
    let clsList2 = document.getElementById("List-more-Ac").classList;
    if (clsList2.value.indexOf("hidElem2") === -1) {
      this.controlMore();
    }
    this.exctuteFilterUrl(this.getFilterToState(), true);
  }

  CallPropsS() {
    this.CallProps = true;
  }

  clearDate() {
    this.props.onChangeBegan(undefined);
    this.props.onChangeEnd(undefined);
    this.CallProps = true;
  }

  resetFilter() {
    Event("CONFIRMATION", "CLICK_IN_RESET_FILTERS", "CLICK_EVENT");
    const {
      onChangeStatus_in,
      onChangeWilaya,
      onChangeCommune,
      onChangeProducts,
      onChangeDisplay_id,
    } = this.props;
    onChangeDisplay_id(undefined);
    this.clearDate();
    onChangeStatus_in([]);
    onChangeWilaya([]);
    onChangeCommune([]);
    onChangeProducts([]);
    this.resFilter = true;
  }
  controlExport() {
    Event("CONFIRMATION", "DROP_EXPORT_LIST", "CLICK_EVENT");
    let clsList = document.getElementById("List_Export");
    let clsList2 = document.getElementById("List-more-Ac").classList;
    if (clsList2.value.indexOf("hidElem2") === -1) {
      this.controlMore();
    }
    if (clsList.classList.value.indexOf("hidElem2") === -1) {
      clsList.style.height = "0px";
      clsList.classList.add("hidElem2");
      document.removeEventListener("mousedown", this.handleClickOutsideExpRef);
    } else {
      clsList.classList.remove("hidElem2");
      clsList.style.height = "165px";
      document.addEventListener("mousedown", this.handleClickOutsideExpRef);
    }
  }
  controlMore() {
    Event("CONFIRMATION", "USE_IMPORT_BUTTON", "CLICK_EVENT");
    let clsList = document.getElementById("List-more-Ac");

    if (clsList.classList.value.indexOf("hidElem2") === -1) {
      clsList.style.height = "0px";
      clsList.classList.add("hidElem2");
    } else {
      clsList.classList.remove("hidElem2");
      clsList.style.height = "55px";
    }
  }
  handleClickOutsideExpRef(event) {
    if (this.ExpRef && !this.ExpRef.current.contains(event.target)) {
      this.controlExport();
    }
  }
  CallExportOrder(extention) {
    if (this.props.Exporting !== "0") {
      this.props.SendAlert("31", Translate("alert", "exportreqstsent"), "a04");
      this.props.ExportOrder(
        this.props.location.search
          .replaceAll(".", ",")
          .replace("prduord", "products"),
        extention
      );
    }
  }

  render() {
    if (this.state.premmision) {
      const { GettingConfirmOrders, OrderUpdSta, GettingSummary, page } =
        this.props;
      var bodytab = [];
      if (GettingConfirmOrders === "1") {
        bodytab = this.state.orders.map((elem, index) => {
          const {
            id,
            product_name,
            product_quantity,
            cancellation_reason,
            customer_name,
            updated_at,
            total_price,
            status,
            wilaya,
            commune,
            products,
            details,
          } = elem;
          let updated_time = (
            <div style={{ minWidth: "95px" }}>
              {moment(updated_at).fromNow()}
            </div>
          );
          // let btns=(<div className="InFlx AlgnItm">
          //         <GroupeBtn data_btn={[{type:"BDgInpc",action:() =>this.RemoveOrder(id),svgbtn:DeletSvg}]}/>
          //     </div>);

          let productsf = "";
          let blockindex = [0];
          if (Array.isArray(products) && products.length > 0) {
            if (products.length > 1) {
              let PrdctList = (
                <div
                  className="InFlx Stclmnf"
                  onClick={() => this.Gotodetail(id)}
                >
                  {products.map((elem, key) => {
                    return (
                      <div key={key} className="Inlflx TxTrnf Mrtpvl">
                        <strong className="StPaddingVal">
                          {" (" + elem.quantity + ") "}
                        </strong>
                        <SmallText>{elem.logistical_description}</SmallText>
                      </div>
                    );
                  })}
                </div>
              );
              productsf = (
                <DropedList
                  props={{ ...this.props }}
                  title={
                    <div className="InFlx AlgnItm RlPs">
                      <span>{products.length}</span>
                      <span className="StPaddingVal">
                        {Translate("titles", "product")}
                      </span>
                      <span className="DsPlCnt">{FlechDwnSvg}</span>
                    </div>
                  }
                  dataDrop={PrdctList}
                />
              );
              blockindex.push(2);
            } else {
              productsf = details?.map((ord, index) => (
                <div className="Inlflx TxTrnf">
                  <strong className="StPaddingVal">
                    {" (" + ord?.quantity + ") "}
                  </strong>
                  <SmallText>{ord?.logistical_description}</SmallText>
                </div>
              ));
            }
          } else {
            productsf = details?.map((ord, index) => (
              <p>{`(${ord?.quantity}) ${ord?.logistical_description}`}</p>
            ));
          }

          let statusDiv = (
            <div>
              <StatusElement
                category="statusconfirm"
                status_class={CONFIRMATION_HELPERS.getStatusIconColor(
                  CONFIRMATION_HELPERS.mapStatusToCode(status)
                )}
              >
                {CONFIRMATION_HELPERS.mapStatusToCode(status)}
              </StatusElement>
            </div>
          );
          let disrictCity = (
            <span>
              {commune} / {wilaya}
            </span>
          );
          return [
            statusDiv,
            productsf,
            cancellation_reason === null ? "/" : cancellation_reason,
            customer_name,
            disrictCity,
            updated_time,
            <div>
              {this.props?.dtStoreinfo?.country?.id === 1
                ? FormatCurrency.format(total_price)
                : total_price + "  " + "TND"}
            </div>,
            { type: "forindex", blocked: blockindex, idaction: id },
          ];
        });
      }
      return (
        <ContainerPage
          page_title={Translate("sidebar", "confirmation")}
          data_top={
            <div>
              <div className="">
                <DatePicker
                  forceUp={this.CallPropsS}
                  from={this.props.began}
                  to={this.props.end}
                  changeFrom={this.props.onChangeBegan}
                  changeTo={this.props.onChangeEnd}
                  chnageDate={this.props.onChangeDate}
                />
              </div>
            </div>
          }
        >
          {GettingConfirmOrders === "1" && this.state.count === 0 ? (
            <EmptyScreen
              titleGlb={Translate("orders", "noorder")}
              TextShow={Translate("orders", "youhavntcreat")}
              // TxtBtn={Translate('orders','addneworder')} FuncCall={"/order/add/new"}
            />
          ) : (
            <div>
              <div className="Inlflx FlWd StDrc">
                <h3 className="StOpcVal">
                  {Translate("confirmation", "todaysummary")}
                </h3>
              </div>
              <div className="Inlflx FlWd StDrc spctwB StFlxWrp">
                {GettingSummary !== "1" &&
                  [1, 2, 3, 4].map((el) => <StatLoader key={el} />)}
                {this.state.data.map((stat, key) => {
                  let elem = stat;
                  return (
                    <div
                      key={key}
                      onClick={() =>
                        Event(
                          "CONFIRMATION",
                          "Click_in_Stats_Card_" +
                            Translate("statusconfirm", "orders" + elem.status),
                          "STATUS_" + elem.status
                        )
                      }
                      style={{
                        width: "30%",
                        minWidth: "140px",
                        marginBottom: "2%",
                      }}
                      className="SpcMrgvl"
                    >
                      <ElmentStatus
                        dataText={elem.title}
                        dataNumber={elem.val}
                        SvgStatus={elem.icon}
                        // status={elem.status}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="Inlflx FlWd StDrc">
                <h3 className="StOpcVal">
                  {Translate("confirmation", "listing")}
                </h3>
              </div>
              <div
                className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc"
                style={{ height: "48px", padding: "7px 0", top: "-20px" }}
              >
                <div role="filter-data" className="FlHg InFlx StwdMaxC flxDrc">
                  <div
                    className="FlHg ClSidTh StBrdRdS StMrtg2"
                    ref={this.FilterRef}
                  >
                    <div className="CrsPoi FlHg" onClick={this.ShowFilter}>
                      <InlinedElement
                        secnd={<div className="IcnSiz">{FilterSvg}</div>}
                      />
                    </div>
                    <div
                      id="Filter_container"
                      className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2"
                      style={{ width: "350px" }}
                    >
                      <div id="Sub_Container_filter">
                        <div style={{ marginTop: "8px" }}>
                          <div className="RlPs">
                            <InlinedElement
                              secnd={
                                <strong>{Translate("orders", "filter")}</strong>
                              }
                              leftElm={
                                <div
                                  onClick={this.ShowFilter}
                                  className="InFlx CrsPoi IcnSizSm"
                                >
                                  {CloseSvg}
                                </div>
                              }
                              style={{ width: "100%", padding: "0px" }}
                            />
                          </div>
                          {this.props.location.search &&
                          (this.props.location.search.replace(
                            "?page=" + this.props.page,
                            ""
                          ) !== "" ||
                            this.props.location.search.replace(
                              "&page=" + this.props.page,
                              ""
                            )) !== "" ? (
                            <span
                              className="CrsPoi StBle DsBlc StdirLn"
                              onClick={this.resetFilter}
                            >
                              {Translate("orders", "resetfilter")}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          ref={this.refrs.Status_Container}
                          className="BdgClN StBrdRdS StPdsml SmMarS1"
                        >
                          <div
                            className="StHeivl RlPs CrsPoi"
                            onClick={() =>
                              this.ControlFilter("Status_Container")
                            }
                          >
                            <InlinedElement
                              secnd={
                                <strong>
                                  {Translate("orders", "orderstatus")}
                                </strong>
                              }
                              leftElm={
                                <div className="InFlx">{FlechDwnSvg}</div>
                              }
                              style={{ width: "100%", padding: "0px" }}
                            />
                          </div>
                          <div
                            id="Status_Container"
                            className="hidElem3 stTranEs"
                            style={{ overflowY: "scroll" }}
                          >
                            <div>
                              {this.state.statusList.map((elem, key) => {
                                return (
                                  <CheckBoxForm
                                    id={"S" + elem}
                                    name={"S" + elem}
                                    text={Translate("statusconfirm", elem)}
                                    workfun={() => this.manageQueryStatus(elem)}
                                    check={
                                      Array.isArray(this.props.status_in) &&
                                      this.props.status_in.indexOf(elem) !== -1
                                    }
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        {/* <div ref={this.refrs.product_Container} className="BdgClN StBrdRdS StPdsml SmMarS1">
                                                <div className="StHeivl RlPs CrsPoi" onClick={()=>this.ControlFilter("product_Container")}>
                                                    <InlinedElement 
                                                        secnd={<strong>{Translate('product','productname')}</strong>}
                                                        leftElm={<div className="InFlx">{FlechDwnSvg}</div>}
                                                        style={{width:"100%",padding:"0px"}}
                                                    />
                                                </div>
                                                <div id="product_Container" className="hidElem3 stTranEs">
                                                    <div>
                                                    <SearchInputType nameOption="products" NameAffect="display_id" NameShow="logistical_description" CheckedValues={this.props.products} options={data ? data.list : []} placeholder={Translate('product','productname')} onChangeSearch={this.setOptionChnage}/>
                                                    </div>
                                                </div>
                                            </div> */}
                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="StAutMr" ref={this.ExpRef}>
                  <div
                    className="Inlflx AlgnItm StDrc StFlxStr d-sm"
                    style={{ height: "48px" }}
                  >
                    <GroupeBtnSp
                      style={{ margin: "0", zoom: "1.2" }}
                      data_btn={[
                        {
                          type: "BDgInpc",
                          action: this.RefreshOredr.bind(this),
                          svgbtn: RefreshSvg,
                        },
                        false
                          ? {
                              type: "BDgInpc",
                              action: this.controlExport,
                              svgbtn: PlayListAddSvg,
                            }
                          : {},
                        false
                          ? {
                              type: "BDgInpc",
                              action: this.controlMore,
                              svgbtn: FileUpSvg,
                            }
                          : {},
                      ]}
                    />
                  </div>
                  <div
                    className="Inlflx AlgnItm StDrc StFlxStr FlWd d-large"
                    style={{ height: "48px" }}
                  >
                    <GroupeBtnSp
                      style={{ margin: "0", zoom: "1.2" }}
                      data_btn={[
                        {
                          type: "BDgInpc",
                          action: this.RefreshOredr.bind(this),
                          svgbtn: RefreshSvg,
                        },
                        false
                          ? {
                              type: "BDgInpc",
                              action: this.controlExport,
                              svgbtn: PlayListAddSvg,
                            }
                          : {},
                        false
                          ? {
                              type: "BDgInpc",
                              action: this.controlMore,
                              svgbtn: FileUpSvg,
                            }
                          : {},
                      ]}
                    />
                  </div>

                  <div
                    className="RlPs InFlx StDrc StFlxStr"
                    style={{ zIndex: "2" }}
                  >
                    <div
                      id="List-more-Ac"
                      className="hidElem2 StwdMaxC HdOvrfl ClSidTh StBgbrds stTranEs StBxSh1"
                      style={{ height: "0px" }}
                    >
                      <Link
                        to="/order/import"
                        className="CrsPoi"
                        style={{ height: "55px" }}
                      >
                        <InlinedElement
                          style={{ flexDirection: "row" }}
                          first={
                            <div className="IcnSiz" style={{ zoom: "1.2" }}>
                              {ExcelSvg}
                            </div>
                          }
                          secnd={
                            <div>{Translate("orders", "importorder")}</div>
                          }
                        />
                      </Link>
                    </div>
                    <div
                      id="List_Export"
                      className="hidElem2 StwdMaxC HdOvrfl ClSidTh StBgbrds stTranEs StBxSh1"
                      style={{ height: "0px" }}
                    >
                      <div
                        className="CrsPoi"
                        style={{ height: "55px" }}
                        onClick={() => this.CallExportOrder("csv")}
                      >
                        <InlinedElement
                          style={{ flexDirection: "row" }}
                          first={
                            <div className="IcnSiz" style={{ zoom: "1.2" }}>
                              {FileDownSvg}
                            </div>
                          }
                          secnd={
                            <div>{Translate("orders", "exportordcsv")}</div>
                          }
                        />
                      </div>
                      <div
                        className="CrsPoi"
                        style={{ height: "55px" }}
                        onClick={() => this.CallExportOrder("xlsx")}
                      >
                        <InlinedElement
                          style={{ flexDirection: "row" }}
                          first={
                            <div className="IcnSiz" style={{ zoom: "1.2" }}>
                              {ExcelSvg}
                            </div>
                          }
                          secnd={
                            <div>{Translate("orders", "exportordxsl2007")}</div>
                          }
                        />
                      </div>
                      <div
                        className="CrsPoi"
                        style={{ height: "55px" }}
                        onClick={() => this.CallExportOrder("xls")}
                      >
                        <InlinedElement
                          style={{ flexDirection: "row" }}
                          first={
                            <div className="IcnSiz" style={{ zoom: "1.2" }}>
                              {ExcelSvg}
                            </div>
                          }
                          secnd={
                            <div>{Translate("orders", "exportordxsl97")}</div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="InFlx flxDrc StmpB3 StOpcVal StFlxWrp2 FlwMax AlgnItm">
                {this.props.status_in.length > 0 && (
                  <Fragment>
                    <div className="stBdp StSmlS StwdMaxC">
                      {Translate("orders", "orderstatus")}
                    </div>
                    {this.props.status_in.map((elem, key) => {
                      return (
                        <div
                          key={key}
                          onClick={() => this.manageQueryStatus(elem)}
                          className="stBdp CrsPoi SthgMaxC"
                        >
                          <InlinedElement
                            style={{
                              border: "2px solid #3498DB",
                              padding: "3px 6px",
                              backgroundColor: "#3498DB35",
                              borderRadius: "5px",
                            }}
                            secnd={
                              <div className="IcnSiz" style={{ zoom: ".48" }}>
                                {CloseSvg}
                              </div>
                            }
                            first={
                              <div className="StSmlS StwdMaxC">
                                {Translate("statusconfirm", elem)}
                              </div>
                            }
                          />
                        </div>
                      );
                    })}
                  </Fragment>
                )}
              </div>
              {this.props.began && this.props.end && (
                <Fragment>
                  <div className="stBdp StSmlS StwdMaxC">
                    {Translate("datepicker", "daterange")}
                  </div>
                  <div
                    className="stBdp CrsPoi SthgMaxC"
                    onClick={() => this.clearDate()}
                  >
                    <InlinedElement
                      style={{
                        border: "2px solid #3498DB",
                        padding: "3px 6px",
                        backgroundColor: "#3498DB35",
                        borderRadius: "5px",
                      }}
                      secnd={
                        <div className="IcnSiz CrsPoi" style={{ zoom: ".48" }}>
                          {CloseSvg}
                        </div>
                      }
                      first={
                        <div className="StSmlS StwdMaxC">
                          {FormatDate(this.props.began, true) +
                            " => " +
                            FormatDate(this.props.end, true)}
                        </div>
                      }
                    />
                  </div>
                </Fragment>
              )}
              {OrderUpdSta === "0" && <Loader />}
              {GettingConfirmOrders === "0" ? (
                <TableLoader />
              ) : GettingConfirmOrders === "1" &&
                this.state.count === 0 &&
                (this.props.location.search.replace(
                  "?page=" + this.props.page,
                  ""
                ) ||
                  this.props.location.search.replace(
                    "&page=" + this.props.page,
                    ""
                  )) !== "" ? (
                <div className="RlPs">
                  <EmptyScreen
                    titleGlb={Translate("orders", "noorder")}
                    TextShow={Translate("orders", "filterempty")}
                    TxtBtn={Translate("orders", "resetfilter")}
                    FuncCall={this.resetFilter}
                  />
                </div>
              ) : (
                <div>
                  <Table
                    thead_elem={CONFIRMATION_TABLE_HEADER}
                    tbody_elem={bodytab}
                    stickySecnd={true}
                    goTo={this.Gotodetail}
                  />
                  <div role="pagination" className="lytWdp SmMarS1">
                    <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                      <span>
                        {Translate("orders", "showing") +
                          " " +
                          (page * 20 - 19) +
                          " - " +
                          (page * 20 < this.state.count
                            ? page * 20
                            : this.state.count) +
                          " " +
                          Translate("orders", "of") +
                          " " +
                          this.state.count}
                      </span>
                      <PaginationBar
                        className="StAutMr"
                        NbPage={Math.trunc(this.state.count / 20) + 1}
                        currentPage={parseInt(page)}
                        ChangePage={this.props.onChangePage}
                        blockNext={this.state.nextpage}
                        blockPrev={this.state.prevpage}
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.state.alertdelet && (
                <TabShow
                  tab_title={Translate("profile", "confirmaction")}
                  wdth="550px"
                  NotCls={false}
                >
                  <div className="MrAot" style={{ width: "94%" }}>
                    <p className="StdirLn FlWd DltTpMar">
                      {Translate("orders", "confrmdelete")}
                    </p>
                    <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                      <div
                        className="StwdMaxC CrsPoi StWdDst"
                        style={{ height: "30px" }}
                        onClick={() => this.showOpt("alertdelet")}
                      >
                        <BtnLg
                          text={Translate("profile", "cancel")}
                          classSet={""}
                        />
                      </div>
                      <div
                        className="StwdMaxC CrsPoi StWdDst"
                        style={{ height: "30px" }}
                        onClick={this.RemoveOrder}
                      >
                        <BtnLg
                          text={Translate("orders", "cancelorder")}
                          classSet={"TxDng"}
                        />
                      </div>
                    </div>
                  </div>
                </TabShow>
              )}
            </div>
          )}
        </ContainerPage>
      );
    } else {
      return (
        <ContainerPage page_title={Translate("titles", "confirmation")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
  }
}
function mapState(state) {
  const { GettingConfirmOrders, ConfOrdersList, GettingSummary, SummaryData } =
    state.confOrders;
  const { GetingWilaya, dataWilaya, GetigCommune, DataCommune } = state.common;
  const { ProductFinded, data } = state.product;
  const { gtStoreInf, dtStoreinfo, dataProfile } = state.user;
  return {
    GettingConfirmOrders,
    ConfOrdersList,
    GettingSummary,
    SummaryData,
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    ProductFinded,
    data,
    dataProfile,
    gtStoreInf,
    dtStoreinfo,
  };
}

const actionCreators = {
  GetOrders: ordersActions.GetConfirmationOrders,
  GetSummary: ordersActions.GetConfirmationSummary,
  UpdateStaOrder: ordersActions.UpdateStaOrder,
  ExportOrder: ordersActions.ExportOrder,
  SendAlert: alertActions.SendAlert,
  GetAllProduct: productActions.GetAllProduct,
  GetStoreInfo: userActions.GetStoreInfo,
  ClearStore: commonActions.ClearStore,
};
const connectedConfirmationPage = connect(
  mapState,
  actionCreators
)(ConfirmationPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(
  connectedConfirmationPage
);
export { QueryProps as ConfirmationPage };

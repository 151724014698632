import React from "react";
export default function ContainerPage(props) {
  return (
    <div
      id="Container-root"
      className={`StSlyT StTrns ${props.lineHeader ? "pt-0" : ""} ${
        props.componentClass
      }`}
    >
      <div>
        <div
          className={`InFlx flxDrc AlgnItm FlWd RlPs lytWdp ${
            props.lineHeader ? "line-header" : ""
          }`}
        >
          <div
            className={`InFlx ${props.columnTitles ? "Stclmnf" : "AlgnItm"}`}
          >
            {props.small_title ? (
              <h3 className="DlMg">{props.page_title}</h3>
            ) : (
              <h2 className="DlMg">{props.page_title}</h2>
            )}
            {props.secondTitle}
          </div>
          {props.data_top && (
            <div className={`StAutMr ${props.lineHeader ? "" : "FlHg"}`}>
              {props.data_top}
            </div>
          )}
        </div>
      </div>
      <div
        className="SmMarS2 FlWd RlPs lytWdp"
        id="Container-content"
        style={{ paddingBottom: props.subContainer ? "0" : "8vh" }}
      >
        {props.children}
      </div>
    </div>
  );
}

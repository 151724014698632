import React, { useState } from "react";

import Modal from "@mui/material/Modal";

import { closeIcon } from "../../../_Common/IconSvg";
import "./AddWebhook.scss";
import { Translate } from "../../../../../utils/lang/translate";
const AddWebhook = (props) => {
  const { closeModal } = props;
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleSubmit = () => {
    handleOpenModal();
  };
  return (
    <article className="addWebhook">
      <Modal open={openModal} onClose={handleCloseModal}>
        <AddWebhook closeModal={handleCloseModal} />
      </Modal>
      <header>
        <h1>{Translate("webhooks", "addnewwebhook")}</h1>
        <button onClick={closeModal}>{closeIcon}</button>
      </header>
      <main>
        <div className="FlWd TxtCn StRedClr">{props.Alertfail}</div>
        <form>
          <section>
            <label htmlFor="eventInput">{Translate("webhooks", "event")}</label>
            <select value={props.valuee} onChange={props.onChangeHook}>
              <option value="">{Translate("webhooks", "selectevent")}</option>

              {props.eventsMap}
            </select>
          </section>
          <section>
            <label htmlFor="callBackUrlInput">
              {Translate("webhooks", "callbackurl")}
            </label>
            <input
              value={props.value}
              onChange={props.onChange}
              type="text"
              id="callBackUrlInput"
            ></input>
          </section>

          <button type="submit" onClick={props.onClick}>
            {props.isLoading
              ? Translate("alert", "load")
              : Translate("shop", "add")}
          </button>
        </form>
      </main>
    </article>
  );
};

export default AddWebhook;

import React, { Component } from "react";
import { Translate } from "../../../utils/lang/translate";
import { FlechDwnSvg } from "./IconSvg";
class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      istoggle: false,
      textsearch: "",
    };
    this.refList = React.createRef();
    this.handleToggle = this.handleToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClickOutsideDrop = this.handleClickOutsideDrop.bind(this);
  }
  handleSearch(e) {
    const { value } = e.target;
    if (this.props.value) {
      this.setState({
        istoggle: true,
        textsearch: value,
      });
      this.props.onChange(null, this.props.name);
    } else {
      this.setState({
        textsearch: value,
        istoggle: true,
      });
    }
  }
  handleToggle() {
    var prevState = this.state.istoggle;
    if (prevState) {
      document.removeEventListener("mousedown", this.handleClickOutsideDrop);
    } else {
      document.addEventListener("mousedown", this.handleClickOutsideDrop);
    }
    this.setState({
      istoggle: !prevState,
    });
  }
  handleClickOutsideDrop(e) {
    if (
      this.refList &&
      this.refList.current &&
      !this.refList.current.contains(e.target)
    ) {
      this.handleToggle();
    }
  }
  handleChange(option) {
    this.handleToggle();
    this.props.onChange(option, this.props.name);
  }
  render() {
    const { name, loading, Options } = this.props;
    var options = [];
    if (!loading && Options) {
      options = Options;
      if (this.props.search && this.state.textsearch.length > 1) {
        options = options.filter(
          (option) =>
            option[this.props.fieldShow]
              .toLowerCase()
              .indexOf(this.state.textsearch.toLowerCase()) !== -1
        );
      }
    }

    return (
      <div className="RlPs FlWd">
        <label className="StmpB3 DsBlc">
          {this.props.label}
          {this.props.optional && (
            <span> ({Translate("shop", "optional")})</span>
          )}
        </label>
        <div
          className={
            "RlPs bdginpu_v StRad_tp " +
            (this.state.istoggle ? "" : "stPdn_btm ") +
            this.props.containerClass
          }
          ref={this.refList}
        >
          <div className="Pdn_select InFlx flxDrc spcBtwn">
            {this.props.search ? (
              <input
                type="text"
                name="textsearch"
                placeholder={
                  loading ? Translate("alert", "load") : this.props.placholder
                }
                value={
                  this.props.value
                    ? this.props.value[this.props.fieldShow]
                    : this.state.textsearch
                }
                onChange={this.handleSearch}
                autoComplete="off"
                readOnly={this.props.readOnly}
                className="stfSize FlWd"
              />
            ) : (
              <span>
                {this.props.value ? (
                  this.props.value[this.props.fieldShow]
                ) : (
                  <span className="StOpcVal">{this.props.placholder}</span>
                )}
              </span>
            )}
            <i
              className="InFlx CrsPoi rotateToggle"
              onClick={this.handleToggle}
              toggle={this.state.istoggle ? "down" : "up"}
            >
              {FlechDwnSvg}
            </i>
          </div>
          <div
            className={
              "StAbs FlWd StlfNl bdginpu_v OvfAtoY stPdn_btm Zindx toggleSide options-" +
              name
            }
            style={{ maxHeight: this.props.maxSize }}
            toggle={this.state.istoggle ? "down" : "up"}
          >
            {options.map((option, key) => {
              return (
                <div
                  key={key}
                  className="Pdn_select withHvr option"
                  dataOpt={option[this.props.fieldShow]}
                  onClick={() => this.handleChange(option)}
                >
                  {option[this.props.fieldShow]}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
export { Select };

import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import ImagesList from "./ImagesList";
import RichTextEditor from "./RichTextEditor";
import ContainerPage from "../_Common/ContainerPage";
import GroupeForm from "../../_Common/GroupeForm";
import { productActions, alertActions } from "../../../actions";
import { Event, Select } from "../_Common";
import Button from "../../_Common/Button";
import { Translate } from "../../../utils/lang/translate";
import "./ShopPage.scss";
import ProductPricingOptionsFields from "./ProductPricingOptionsFields";
import {
  DeletedShopPhoto,
  ProductShop,
  ProductShopOptions,
  ProductShopPhoto,
} from "../../../types";
import TagInput from "../../_Common/TagInput";
import { DataShop } from "../../../types";
import { Prompt } from "react-router-dom";
import useExitPrompt from "../../../utils/useExitPrompt";
import { Switch } from "@mui/material";
import TableLoader from "../_Common/TableLoader";
import { shopActions } from "../../../actions";
import {
  FlagArSvg,
  FlagEnSvg,
  FlagFrSvg,
  LoadingSmallSvg,
} from "../_Common/IconSvg";
import { DEFAULT_PRICING_OPTION } from "../../../constants";

const initialOption = {
  name: "",
  quantity: 1,
  price: 0,
  reduced_price: null,
  id: "",
};

const languages = [
  ["ar", Translate("profile", "ar")],
  ["fr", Translate("profile", "fr")],
  ["en", Translate("profile", "en")],
];

interface ImageToUpload {
  imageFile: File;
  id: string;
}

interface Props {
  dtStoreinfo: any;
  GetAllProduct: any;
  GetShopInfoWithPricing: () => void;
  dataShop: DataShop;
  shopError: boolean;
  shopLoading: boolean;
  productShopPhoto: ProductShopPhoto;
  deletedShopPhoto: DeletedShopPhoto;
  ModifyProductShop: (
    dataShop: DataShop,
    productShop: ProductShop,
    successCallback?: () => void
  ) => void;
  CreateProductShop: (
    productShop: ProductShop,
    newImages: File[],
    data: DataShop,
    setProgress?: (percentage: number | null) => void,
    successCallback?: () => void
  ) => void;
  DeletePhotoProductShop: (
    imageId: string,
    productId: string,
    successCallback?: () => void
  ) => void;
  UploadPhotoProductShop: (
    newImage: File,
    productId: string,
    setProgress?: (percentage: number | null) => void,
    successCallback?: () => void
  ) => void;
  data: any;
  match: any;
  ProductFinded: any;
  history: any;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const ShopProductForm: React.FC<Props> = ({
  GetAllProduct,
  GetShopInfoWithPricing,
  dataShop,
  shopLoading,
  productShopPhoto,
  deletedShopPhoto,
  ModifyProductShop,
  CreateProductShop,
  UploadPhotoProductShop,
  DeletePhotoProductShop,
  data,
  match,
  ProductFinded,
  history,
  SendAlert,
}) => {
  const { idprod } = match.params;
  const [productForm, setProductForm] = useState<ProductShop>({
    title: "",
    slug: "",
    options: [{ ...initialOption }],
    categories: [],
    description: "",
    how_to_use: "",
    images: [],
    specifications: "",
    product_id: "",
    display_id: idprod,
    published: true,
    id: "",
    landing_page: "",
    lang: "ar",
    rate: "5",
    pricing_option: DEFAULT_PRICING_OPTION,
    template_details: [],
  });
  const [imagesToUpload, setImagesToUpload] = useState<ImageToUpload[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isChange, setIsChange] = useExitPrompt(false);
  const [isModify, setIsModify] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);

  useEffect(() => {
    document.title =
      "Maystro Delivery - " + Translate("titles", "addProductShop");
    GetShopInfoWithPricing();
    return () => {
      setIsChange(false);
    };
  }, []);

  useEffect(() => {
    if (
      !shopLoading &&
      dataShop?.pricingOptions &&
      Array.isArray(dataShop.products)
    ) {
      if (!dataShop.published) history.push("/shop/settings/");
      const productDataShop = dataShop.products.find(
        (prod) => prod.display_id.toString() === idprod.toString()
      );
      if (productDataShop) {
        const pricing_option =
          productDataShop.pricing_option ?? DEFAULT_PRICING_OPTION;
        setProductForm({ ...productForm, ...productDataShop, pricing_option });
        setIsModify(true);
        setIsLoading(false);
      } else {
        GetAllProduct();
      }
    }
  }, [dataShop]);

  useEffect(() => {
    if (
      ProductFinded === "2" &&
      data &&
      data.list &&
      Array.isArray(data.list)
    ) {
      const filteredProduct = data.list.find(
        (prod: any) => prod.display_id + "" === idprod + ""
      );

      if (filteredProduct) {
        const slug = filteredProduct.logistical_description
          .toLowerCase()
          .replace(/[éèêë]/gm, "e")
          .replace(/[àâä]/gm, "a")
          .replace(/[ç]/gm, "c")
          .replace(/[ùûü]/gm, "u")
          .replace(/[ôö]/gm, "o")
          .replace(/[ïî]/gm, "i")
          .replace(/[_]/gm, " ")
          .replace(/[^\w ]+/g, "")
          .replace(/ +/g, "-");

        setProductForm({
          ...productForm,
          title: filteredProduct.logistical_description,
          product_id: filteredProduct.id,
          slug,
        });
        setIsLoading(false);
      } else {
        history.push("/stock/products");
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      productShopPhoto?.url &&
      productShopPhoto.productId === productForm.id
    ) {
      setProductForm({
        ...productForm,
        images: [
          ...productForm.images,
          {
            url: productShopPhoto.url,
            id: productShopPhoto.id,
            order: productForm.images.length,
          },
        ],
      });
    }
  }, [productShopPhoto]);

  useEffect(() => {
    if (
      deletedShopPhoto?.imageId &&
      deletedShopPhoto.productId === productForm.id
    ) {
      setProductForm({
        ...productForm,
        images: productForm.images
          .filter((img) => img.id !== deletedShopPhoto.imageId)
          .map((img, key) => {
            return { ...img, order: key };
          }),
      });
    }
  }, [deletedShopPhoto]);

  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    key?: number
  ) => {
    const { value, name } = event.target as HTMLInputElement;
    if (typeof key !== "number")
      setProductForm({ ...productForm, [name]: value });
    else
      setProductForm({
        ...productForm,
        options: productForm.options.map((option, i) => {
          if (i === key)
            return { ...option, [name]: value !== "" ? value : null };
          return option;
        }),
      });
    setIsChange(true);
  };

  const handleRichTextChange = (content: string, name: string) => {
    if (name in productForm) {
      setProductForm({ ...productForm, [name]: content });
      setIsChange(true);
    }
  };

  const handleSlugChange = (event: any) => {
    event.preventDefault();
    const { key } = event;
    if (key) {
      if (key === "Backspace" && productForm.slug.length > 0) {
        setProductForm({
          ...productForm,
          slug: productForm.slug.slice(0, productForm.slug.length - 1),
        });
        setIsChange(true);
      }
      if (/^[a-z0-9-]$/.test(key)) {
        setProductForm({ ...productForm, slug: productForm.slug.concat(key) });
        setIsChange(true);
      }
    }
  };

  const handleChangeCategories = (newCategories: string[], name: string) => {
    setProductForm({ ...productForm, categories: newCategories });
    setIsChange(true);
  };

  const handleAddOption: () => void = () => {
    if (productForm.options.length < 10) {
      setProductForm({
        ...productForm,
        options: [...productForm.options, { ...initialOption }],
      });
      setIsChange(true);
    }
  };

  const handleDeleteOption: (key: number) => void = (key) => {
    if (productForm.options.length > 1) {
      setProductForm({
        ...productForm,
        options: productForm.options.filter((option, i) => key !== i),
      });
      setIsChange(true);
    }
  };

  const addImage = (newImage: File) => {
    if (
      productForm.images.length <= 5 &&
      !productForm.images.some(
        (img) => img.id === newImage.name + newImage.size
      )
    ) {
      if (isModify) {
        UploadPhotoProductShop(newImage, productForm.id, setProgress, () => {
          setIsChange(true);
        });
      } else {
        setProductForm({
          ...productForm,
          images: [
            ...productForm.images,
            {
              url: URL.createObjectURL(newImage),
              id: newImage.name + newImage.size,
              order: productForm.images.length,
            },
          ],
        });
        setImagesToUpload([
          ...imagesToUpload,
          {
            imageFile: newImage,
            id: newImage.name + newImage.size,
          },
        ]);
        setIsChange(true);
      }
    }
  };

  const removeImage = (imageId: string) => {
    if (isModify) {
      DeletePhotoProductShop(imageId, productForm.id, () => {
        setIsChange(true);
      });
    } else {
      setProductForm({
        ...productForm,
        images: productForm.images
          .filter((img) => imageId !== img.id)
          .map((img, key) => {
            return { ...img, order: key };
          }),
      });
      setImagesToUpload(imagesToUpload.filter((img) => imageId !== img.id));
      setIsChange(true);
    }
  };

  const handleImagePositionChange = (
    position: number,
    direction: "left" | "right"
  ) => {
    if (direction === "left" && position > 0) {
      const imagesLeftPart = productForm.images.slice(0, position - 1);
      const imagesRightPart = productForm.images.slice(
        position + 1,
        productForm.images.length
      );
      setProductForm({
        ...productForm,
        images: [
          ...imagesLeftPart,
          productForm.images[position],
          productForm.images[position - 1],
          ...imagesRightPart,
        ].map((img, key) => {
          return { ...img, order: key };
        }),
      });
      setIsChange(true);
    }
    if (direction === "right" && position < productForm.images.length - 1) {
      const imagesLeftPart = productForm.images.slice(0, position);
      const imagesRightPart = productForm.images.slice(
        position + 2,
        productForm.images.length
      );
      setProductForm({
        ...productForm,
        images: [
          ...imagesLeftPart,
          productForm.images[position + 1],
          productForm.images[position],
          ...imagesRightPart,
        ].map((img, key) => {
          return { ...img, order: key };
        }),
      });
      setIsChange(true);
    }
  };

  const handleIsVisible = () => {
    setProductForm({ ...productForm, published: !productForm.published });
    setIsChange(true);
  };

  const handleLang = (option: any) => {
    const value = option[0];
    if (value === "ar" || value === "fr" || value === "en") {
      setProductForm({ ...productForm, lang: option[0] });
      setIsChange(true);
    }
  };

  const handleRate = (option: any) => {
    const value = option[0];
    if (value > 0 && value <= 5) {
      setProductForm({ ...productForm, rate: value });
      setIsChange(true);
    }
  };

  const handlePricingOption = (option: any) => {
    setProductForm({ ...productForm, pricing_option: option[0] });
    setIsChange(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const pricing_option =
      productForm.pricing_option !== DEFAULT_PRICING_OPTION
        ? productForm.pricing_option
        : null;
    const newProduct = { ...productForm, pricing_option };
    if (isModify) {
      Event("SHOP", "MODIFY_SHOP_PRODUCT", "CLICK_EVENT");
      ModifyProductShop(dataShop, newProduct, () => {
        setIsChange(false);
        SendAlert("41", Translate("shop", "changesSaved"));
        history.push("/stock/products");
      });
    } else {
      Event("SHOP", "CREATE_SHOP_PRODUCT", "CLICK_EVENT");
      const images = imagesToUpload.map((elem) => elem.imageFile);
      CreateProductShop(newProduct, images, dataShop, setProgress, () => {
        setImagesToUpload([]);
        setIsChange(false);
        history.push("/stock/products");
      });
    }
  };

  const deliveryPricingOption = useMemo(
    () =>
      dataShop?.pricingOptions?.map((pr) => [
        pr.id,
        pr.id === DEFAULT_PRICING_OPTION
          ? Translate("shop", "defaultPricingOption")
          : pr.name,
      ]),
    [dataShop]
  );

  const deliveryPricingOptionValue = useMemo(
    () =>
      deliveryPricingOption?.find(
        (arr) => arr[0] === productForm.pricing_option
      ),
    [deliveryPricingOption, productForm]
  );

  return (
    <ContainerPage page_title={Translate("titles", "addProductListing")}>
      {isLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : (
        <div className="ShopProductForm">
          <div className="container ClSidTh StBrdTb StBgbrds widthAdd">
            <form method="post" onSubmit={handleSubmit} autoComplete="off">
              <ContainerPage
                subContainer
                page_title={Translate("product", "productdetails")}
                small_title={true}
                withOvrl
              >
                <Prompt
                  when={isChange}
                  message={Translate("alert", "prompt")}
                />
                <div className="grid grid-1-1 marginBottom1">
                  <GroupeForm
                    id="title"
                    name="title"
                    placeholder={Translate("shop", "titlePlaceHolder")}
                    text={Translate("shop", "title")}
                    type="text"
                    value={productForm.title}
                    workfun={handleChange}
                    required
                  />
                  <div className="Flwd InFlx flxDrc AlgnItm RlPs">
                    <GroupeForm
                      id="slug"
                      name="slug"
                      placeholder={Translate("shop", "slugPlaceHolder")}
                      text={Translate("shop", "slug")}
                      pattern="^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$"
                      type="text"
                      value={productForm.slug}
                      onKeyDown={handleSlugChange}
                      style={{ width: "100%" }}
                      required
                    />
                    <div className="StAbs slug-slash">/</div>
                  </div>
                </div>
                <ProductPricingOptionsFields
                  options={productForm.options}
                  handleChange={handleChange}
                  handleDeleteOption={handleDeleteOption}
                  handleAddOption={handleAddOption}
                />
                <div className="marginTop1">
                  <RichTextEditor
                    title={Translate("shop", "description")}
                    id="description"
                    value={productForm.description}
                    setValue={(content) =>
                      handleRichTextChange(content, "description")
                    }
                  />
                </div>
                <RichTextEditor
                  title={Translate("shop", "guide")}
                  id="guide"
                  value={productForm.how_to_use}
                  setValue={(content) =>
                    handleRichTextChange(content, "how_to_use")
                  }
                  optional
                />
                <RichTextEditor
                  title={Translate("shop", "specifications")}
                  id="specifications"
                  value={productForm.specifications}
                  setValue={(content) =>
                    handleRichTextChange(content, "specifications")
                  }
                  optional
                />
                <TagInput
                  text={Translate("shop", "productCategories")}
                  placeholder={Translate("shop", "categoryPlaceholder")}
                  name="categories"
                  values={productForm.categories}
                  handleChange={handleChangeCategories}
                  optional
                />
                <ImagesList
                  images={productForm.images}
                  addImage={addImage}
                  removeImage={removeImage}
                  changePosition={handleImagePositionChange}
                  progress={progress}
                  shopLoading={shopLoading}
                />
                <div className="rate">
                  <Select
                    label={Translate("shop", "rate")}
                    Options={[["5.00"], ["4.50"], ["4.00"], ["3.50"], ["3.00"]]}
                    fieldShow={0}
                    name="rate"
                    value={[productForm.rate]}
                    onChange={handleRate}
                    maxSize="200px"
                  />
                </div>
                <div className="language">
                  <Select
                    label={Translate("shop", "chooseLanguage")}
                    Options={languages}
                    fieldShow={1}
                    name="lang"
                    value={languages.find((arr) => arr[0] === productForm.lang)}
                    onChange={handleLang}
                    maxSize="200px"
                  />
                </div>
                <div className="delivery-pricing">
                  <Select
                    label={Translate("shop", "choosePricingOption")}
                    Options={deliveryPricingOption}
                    fieldShow={1}
                    name="pricing_option"
                    value={deliveryPricingOptionValue}
                    onChange={handlePricingOption}
                    maxSize="200px"
                  />
                </div>
                <div className="is-visible-toggle">
                  <Switch
                    checked={productForm.published}
                    onClick={handleIsVisible}
                  />
                  <p>{Translate("shop", "makeProductVisible")}</p>
                </div>
                <div className="MrAot StMarMx responseDiv w-min-content">
                  <Button
                    style={{
                      width: "max-content",
                      padding: "10px 20px",
                      minWidth: "190px",
                    }}
                    BtnText={
                      shopLoading
                        ? LoadingSmallSvg
                        : isModify
                        ? Translate("shop", "modifyProduct")
                        : Translate("shop", "addProduct")
                    }
                    disabled={
                      shopLoading ||
                      !productForm.title ||
                      !productForm.slug ||
                      !productForm.options.every(
                        (option) =>
                          option.name &&
                          option.quantity &&
                          option.price &&
                          (!option.reduced_price ||
                            option.price > option.reduced_price ||
                            (typeof option.price === "string" &&
                              typeof option.reduced_price === "string" &&
                              parseInt(option.price) >
                                parseInt(option.reduced_price)))
                      ) ||
                      productForm.images.length < 1 ||
                      !productForm.description ||
                      !productForm.pricing_option ||
                      !isChange
                    }
                  />
                </div>
              </ContainerPage>
            </form>
          </div>
        </div>
      )}
    </ContainerPage>
  );
};

function mapState(state: any) {
  const {
    dataShop,
    shopError,
    shopLoading,
    productShopPhoto,
    deletedShopPhoto,
  } = state.shop;
  const { dtStoreinfo } = state.user;
  const { ProductFinded, data } = state.product;
  return {
    dtStoreinfo,
    ProductFinded,
    data,
    dataShop,
    shopError,
    shopLoading,
    productShopPhoto,
    deletedShopPhoto,
  };
}

const actionCreators = {
  GetAllProduct: productActions.GetAllProduct,
  GetShopInfoWithPricing: shopActions.GetShopInfoWithPricing,
  ModifyProductShop: shopActions.ModifyProductShop,
  CreateProductShop: shopActions.CreateProductShop,
  DeletePhotoProductShop: shopActions.DeletePhotoProductShop,
  UploadPhotoProductShop: shopActions.UploadPhotoProductShop,
  SendAlert: alertActions.SendAlert,
};

export default connect(mapState, actionCreators)(ShopProductForm);

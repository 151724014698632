import React, { useState, useEffect, useRef } from "react";
import GroupeForm from "../../../_Common/GroupeForm";
import Button from "../../../_Common/Button";
import { Translate } from "../../../../utils/lang/translate";
import { DataShop, UpdateDataShopSettings } from "../../../../types";
import { Prompt } from "react-router-dom";
import useExitPrompt from "../../../../utils/useExitPrompt";
import { SHOP_DOMAIN_URL } from "../../../../constants";
import { Event } from "../../_Common";
import ConfirmationAlert from "../../../_Common/ConfirmationAlert";

const subdomainRegex = new RegExp("^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$");
const domainRegex = new RegExp(
  `^https?:\/\/([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.)+[a-zA-Z0-9]{2,}(\/([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9])*)*$`
);
interface Props {
  dataShop: DataShop;
  history: any;
  UpdateDataShop: (
    data: DataShop,
    settings: UpdateDataShopSettings,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const SubdomainSettingsForm: React.FC<Props> = ({
  dataShop,
  history,
  UpdateDataShop,
  SendAlert,
}) => {
  const [subdomain_slug, setSubdomain] = useState("");
  const [domain, setDomain] = useState("");
  const [isChange, setIsChange] = useExitPrompt(false);
  const [isAddDomainVisible, setIsAddDomainVisible] = useState(false);
  const [isConnectDomainVisible, setIsConnectDomainVisible] = useState(false);
  const [hasShopDomain, setHasShopDomain] = useState(false);

  useEffect(() => {
    return () => {
      setIsChange(false);
    };
  }, []);

  useEffect(() => {
    if (typeof dataShop.subdomain_slug !== "undefined")
      setSubdomain(dataShop.subdomain_slug);
    if (dataShop.domain) {
      setDomain(dataShop.domain);
      setHasShopDomain(true);
    }
  }, [dataShop]);

  const handleKeyDown = (event: any) => {
    event.preventDefault();
    const { key } = event;
    if (key) {
      if (key === "Backspace" && subdomain_slug.length > 0) {
        setSubdomain(subdomain_slug.slice(0, subdomain_slug.length - 1));
        setIsChange(true);
      }
      if (/^[a-z0-9-]$/.test(key)) {
        setSubdomain(subdomain_slug.concat(key));
        setIsChange(true);
      }
    }
  };

  const handleDomain = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { value } = event.target as HTMLInputElement;
    setDomain(value);
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      Event("SHOP", "SUB_DOMAIN_SAVE", "CLICK_EVENT");
    }
    if (subdomainRegex.test(subdomain_slug)) {
      const domainObject =
        !e && domain.length > 0 ? { domain: `${domain}` } : {};
      UpdateDataShop(
        dataShop,
        {
          subdomain_slug,
          settings_stage:
            dataShop.settings_stage <= 2 ? 3 : dataShop.settings_stage,
          ...domainObject,
        },
        () => {
          setIsChange(false);
          history.push("/shop/settings/facebook");
        }
      );
    }
  };

  const handleRemoveCustomDomain = () => {
    Event("SHOP", "REMOVE_CUSTOM_DOMAIN", "CLICK_EVENT");
    UpdateDataShop(
      dataShop,
      { subdomain_slug, domain: null, settings_stage: dataShop.settings_stage },
      () => {
        setIsChange(false);
        history.push("/shop/settings/domain");
      }
    );
  };

  return (
    <>
      <Prompt when={isChange} message={Translate("alert", "prompt")} />
      <form
        className="SubdomainSettingsForm"
        onSubmit={handleSubmit}
        method="post"
        autoComplete="off"
      >
        {!hasShopDomain ? (
          <>
            <div className="subdomain-container">
              <h2>{Translate("shop", "maystroDomain")}</h2>
              <GroupeForm
                id={"subdomain_slug"}
                name={"subdomain_slug"}
                placeholder={Translate("shop", "domainPlaceholder")}
                pattern="^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$"
                type="text"
                value={subdomain_slug}
                onKeyDown={handleKeyDown}
                readOnly={dataShop.published}
                disabled={dataShop.published}
                inputStyle={{ fontSize: "16px" }}
              />
              <div className="subdomain-url">.{SHOP_DOMAIN_URL}</div>
            </div>
            <div className="subdomain-container">
              <h2>{Translate("shop", "customDomain")}</h2>
              <p>{Translate("shop", "customDomainText")}</p>
              <Button
                BtnText={Translate("shop", "connectDomain")}
                clName="connect-domain-button"
                style={{
                  backgroundColor: "transparent",
                  color: "#008aff",
                  border: "3px solid #008aff",
                }}
                type="button"
                onClick={() => {
                  setIsAddDomainVisible(true);
                  Event("SHOP", "CUSTOM_DOMAIN_BUTTON", "CLICK_EVENT");
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="subdomain-container">
              <h2>{Translate("shop", "shopSubDomainTitle")}</h2>
              <GroupeForm
                type="text"
                value={`${subdomain_slug}.${SHOP_DOMAIN_URL}`}
                inputStyle={{ fontSize: "16px", paddingRight: "42px" }}
                readOnly
                copyButton={() =>
                  SendAlert("41", Translate("shop", "copySuccess"))
                }
              />
            </div>
            <div className="subdomain-container">
              <h2>{Translate("shop", "connectedDomain")}</h2>
              <div className="InFlx spctwB">
                <p className="pointer-none">{domain}</p>
                <Button
                  BtnText={Translate("profile", "delete")}
                  clName="connect-domain-button"
                  style={{
                    backgroundColor: "var(--border-dark)",
                    color: "var(--fntClr)",
                  }}
                  type="button"
                  onClick={handleRemoveCustomDomain}
                />
              </div>
            </div>
          </>
        )}
        {dataShop && !dataShop.published && !hasShopDomain && (
          <div className="buttons">
            <Button
              BtnText={Translate("shop", "next")}
              type="submit"
              disabled={
                !subdomainRegex.test(subdomain_slug) || dataShop.published
              }
            />
          </div>
        )}
      </form>
      {isAddDomainVisible && (
        <ConfirmationAlert
          title={Translate("shop", "addDomainTitle")}
          content={
            <GroupeForm
              id={"domain"}
              name={"domain"}
              placeholder={Translate("shop", "linkPlaceholder")}
              type="text"
              text={Translate("shop", "addDomainText")}
              value={domain}
              workfun={handleDomain}
              inputStyle={{ fontSize: "16px" }}
            />
          }
          onConfirm={() => {
            setIsAddDomainVisible(false);
            setIsConnectDomainVisible(true);
            Event("SHOP", "ADD_DOMAIN_CONTINUE", "CLICK_EVENT");
          }}
          onClose={() => {
            setIsAddDomainVisible(false);
            Event("SHOP", "ADD_DOMAIN_CLOSE", "CLICK_EVENT");
          }}
          customConfirmText={Translate("shop", "add")}
          customCloseText={Translate("shop", "back")}
          disabled={!domainRegex.test(domain)}
        />
      )}
      {isConnectDomainVisible && (
        <ConfirmationAlert
          title={Translate("shop", "connectYourDomain")}
          content={
            <>
              <GroupeForm
                type="text"
                text={Translate("shop", "copyCName")}
                value={`${subdomain_slug}.${SHOP_DOMAIN_URL}`}
                inputStyle={{ fontSize: "16px", paddingRight: "42px" }}
                readOnly
                copyButton={() =>
                  SendAlert("41", Translate("shop", "copySuccess"))
                }
              />
              <div className="TxtLeft" style={{ fontSize: "15px" }}>
                <p>{Translate("shop", "connectCNameText1")}</p>
                <p>{Translate("shop", "connectCNameText2")}</p>
                <p>{Translate("shop", "connectCNameText3")}</p>
                <p>{Translate("shop", "connectCNameText4")}</p>
              </div>
            </>
          }
          onConfirm={() => {
            setIsConnectDomainVisible(false);
            Event("SHOP", "CONNECT_DOMAIN_CONFIRM", "CLICK_EVENT");
            handleSubmit();
          }}
          onClose={() => {
            setIsConnectDomainVisible(false);
            setIsAddDomainVisible(true);
            Event("SHOP", "CONNECT_DOMAIN_BACK", "CLICK_EVENT");
          }}
          customConfirmText={Translate("shop", "connect")}
          customCloseText={Translate("shop", "back")}
        />
      )}
    </>
  );
};

export default SubdomainSettingsForm;

import React, { useMemo, useState } from "react";
import { Prompt } from "react-router-dom";
import {
  DataShop,
  LandingPageSettings,
  CustomLandingPageColors,
} from "../../../../types";
import { Translate } from "../../../../utils/lang/translate";
import useExitPrompt from "../../../../utils/useExitPrompt";
import Button from "../../../_Common/Button";
import { Event } from "../../_Common";
import { SketchPicker } from "react-color";

interface Props {
  dataShop: DataShop;
  UpdateLandingPageSettings: (
    landingPageSettings: LandingPageSettings,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const LandingPageColors = ({
  dataShop,
  UpdateLandingPageSettings,
  SendAlert,
}: Props) => {
  const [custom_colors, setCustom_colors] = useState<
    CustomLandingPageColors | undefined
  >(dataShop.landingPageSettings?.custom_colors);
  const [colorId, setColorId] = useState<string | null>(null);
  const [isChange, setIsChange] = useExitPrompt(false);

  const handleColorChange = (color: any) => {
    const { hex } = color;
    if (
      custom_colors &&
      colorId &&
      colorId in custom_colors &&
      typeof hex === "string"
    ) {
      setCustom_colors({ ...custom_colors, [colorId]: hex });
      setIsChange(true);
    }
  };

  const handleSave = () => {
    const updatedLandingPageSettings: LandingPageSettings = {
      ...dataShop.landingPageSettings!,
      custom_colors,
    };
    UpdateLandingPageSettings(updatedLandingPageSettings, dataShop, () => {
      setIsChange(false);
      SendAlert("41", Translate("shop", "changesSaved"));
    });
  };

  let colorContainers: JSX.Element[] | undefined;
  if (custom_colors) {
    colorContainers = Object.entries(custom_colors).map(([key, value]) => (
      <ColorContainer
        key={key}
        colorId={key}
        color={value}
        handleIdChange={setColorId}
      />
    ));
  }

  return (
    <>
      <Prompt when={isChange} message={Translate("alert", "prompt")} />
      <div className="LandingPageColors">
        <h3>{Translate("shop", "customYourColors")}</h3>
        {colorContainers}
        {colorId && (
          <SketchPicker
            // @ts-ignore
            color={custom_colors[colorId]}
            onChangeComplete={handleColorChange}
          />
        )}
        <Button
          clName="button"
          BtnText={Translate("shop", "save")}
          onClick={() => {
            Event("SHOP", "SAVE_LANDING_PAGE_COLORS", "CLICK_EVENT");
            handleSave();
          }}
          style={{
            backgroundColor: "#008AFF",
            color: "white",
            fontSize: "16px",
            width: "140px",
          }}
          disabled={!isChange}
        />
      </div>
    </>
  );
};

const ColorContainer = ({
  colorId,
  color,
  handleIdChange,
}: {
  colorId: string;
  color: string;
  handleIdChange: (key: string) => void;
}) => {
  return useMemo(
    () => (
      <div className="color-container">
        <div className="color-title">
          {Translate("shop", colorId)}
          <div className="color-gif">
            <img
              src={`/assets/img/${colorId}.gif`}
              alt={Translate("shop", colorId)}
            />
          </div>
        </div>

        <div
          className="color-box"
          style={
            color ? { backgroundColor: color } : { border: "2px solid black" }
          }
          onClick={() => {
            handleIdChange(colorId);
          }}
        />
        <p>{color ? color : "#"}</p>
      </div>
    ),
    [color]
  );
};

export default LandingPageColors;

import React, { useRef } from "react";
import { AddSvg, LoadingSmallSvg } from "../_Common/IconSvg";
import { Translate } from "../../../utils/lang/translate";
import { DeletSvg, NextSvg, PrevSvg } from "../_Common/IconSvg";

interface Props {
  images: {
    url: string;
    id: string;
    order: number;
  }[];
  progress: number | null;
  shopLoading: boolean;
  optional?: boolean;
  addImage: (newImage: File) => void;
  removeImage: (imageId: string) => void;
  changePosition: (position: number, direction: "left" | "right") => void;
}

const ImagesList: React.FC<Props> = ({
  images,
  addImage,
  removeImage,
  changePosition,
  progress,
  shopLoading,
  optional,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event
  ) => {
    if (event.target.files) addImage(event.target.files[0]);
  };

  const handleClick = () => {
    if (inputRef.current) inputRef.current.value = "";
  };

  return (
    <div className="ImagesList">
      <p>
        {Translate("shop", "photo")}
        {optional && <span> ({Translate("shop", "optional")})</span>}
      </p>
      <div className="flex-wrap">
        {images
          .sort((a, b) => a.order - b.order)
          .map((image, key) => (
            <div className="image-container overlay" key={image.id}>
              <img src={image.url} alt={image.id} />
              {images.length > 1 && (
                <div
                  className="button-absolute delete-image"
                  onClick={() => removeImage(image.id)}
                >
                  {DeletSvg}
                </div>
              )}
              {images.length > 1 && key > 0 && (
                <div
                  className="button-absolute left"
                  onClick={() => changePosition(key, "left")}
                >
                  {PrevSvg}
                </div>
              )}
              {images.length > 1 && key < images.length - 1 && (
                <div
                  className="button-absolute right"
                  onClick={() => changePosition(key, "right")}
                >
                  {NextSvg}
                </div>
              )}
            </div>
          ))}
        {(images.length <= 5 || progress) && (
          <div
            className={`image-container ${shopLoading ? "loading" : ""} ${
              images.length === 0 ? "isError" : ""
            }`}
            onClick={() => {
              if (inputRef.current) inputRef.current.click();
            }}
          >
            {progress ? `${progress}%` : AddSvg}
            <input
              ref={inputRef}
              type="file"
              accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
              onChange={handleChange}
              onClick={handleClick}
              disabled={progress !== null}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImagesList;

import { exchangeConstants } from "../constants";
import { RequestService, FunctionService } from "../services";
import Cookies from "js-cookie";
const token = Cookies.get("auth");
export const exchangeActions = {
  AddExchange,
  GetExchanges,
  AddRefund,
};
function AddRefund(order, isPremium) {
  return (dispatch) => {
    dispatch(FunctionService.request(exchangeConstants.ADD_REFUND_REQUEST));
    const url = isPremium ? "exchange_refund/" : "exchange_refund_starter/";
    RequestService.PostRequest("stores/" + url, order, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, exchangeConstants.ADD_REFUND_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, exchangeConstants.ADD_REFUND_FAILURE)
        );
      });
  };
}

function GetExchanges(filter) {
  return (dispatch) => {
    dispatch(FunctionService.request(exchangeConstants.GET_EXCHANGE_REQUEST));
    RequestService.GetRequest("stores/exchange_refund/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data, filter: filter },
            exchangeConstants.GET_EXCHANGE_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            exchangeConstants.GET_EXCHANGE_FAILURE
          )
        );
      });
  };
}

function AddExchange(order, isPremium) {
  return (dispatch) => {
    const url = isPremium ? "exchange_refund/" : "exchange_refund_starter/";
    dispatch(FunctionService.request(exchangeConstants.ADD_EXCHANGE_REQUEST));
    RequestService.PostRequest("stores/" + url, order, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, exchangeConstants.ADD_EXCHANGE_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, exchangeConstants.ADD_EXCHANGE_FAILURE)
        );
      });
  };
}

import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import {
  DataShop,
  LandingPageSettings,
  UpdateDataShopSettings,
} from "../../../../types";
import { Translate } from "../../../../utils/lang/translate";
import useExitPrompt from "../../../../utils/useExitPrompt";
import Button from "../../../_Common/Button";
import GroupeForm from "../../../_Common/GroupeForm";
import { Event } from "../../_Common";

const facebookRegex = new RegExp(
  /^https:\/\/(?:www\.)?(mbasic.facebook|web\.facebook|m\.facebook|facebook|fb)\.(com|me)\/.+$/
);

const instagramRegex = new RegExp(/^https:\/\/(?:www\.)?instagram\.com\/.+$/);

const tiktokRegex = new RegExp(/^https:\/\/(?:(www|m|vt)\.)?tiktok\.com\/.+$/);

interface Props {
  dataShop: DataShop;
  UpdateLandingPageSettings: (
    landingPageSettings: LandingPageSettings,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const LandingPageFooter = ({
  dataShop,
  UpdateLandingPageSettings,
  SendAlert,
}: Props) => {
  const [facebook, setFacebook] = useState<string | undefined>(
    dataShop.landingPageSettings?.social_media_url?.facebook
  );
  const [instagram, setInstagram] = useState<string | undefined>(
    dataShop.landingPageSettings?.social_media_url?.instagram
  );
  const [tiktok, setTiktok] = useState<string | undefined>(
    dataShop.landingPageSettings?.social_media_url?.tiktok
  );
  const [isChange, setIsChange] = useExitPrompt(false);

  const handleFacebookChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { value } = event.target as HTMLInputElement;
    setFacebook(value);
    setIsChange(true);
  };

  const handleInstagramChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { value } = event.target as HTMLInputElement;
    setInstagram(value);
    setIsChange(true);
  };

  const handleTiktokChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { value } = event.target as HTMLInputElement;
    setTiktok(value);
    setIsChange(true);
  };

  const handleSave = () => {
    const updatedLandingPageSettings: LandingPageSettings = {
      ...dataShop.landingPageSettings!,
      social_media_url: {
        facebook,
        instagram,
        tiktok,
      },
    };
    UpdateLandingPageSettings(updatedLandingPageSettings, dataShop, () => {
      setIsChange(false);
      SendAlert("41", Translate("shop", "changesSaved"));
    });
  };

  return (
    <>
      <Prompt when={isChange} message={Translate("alert", "prompt")} />
      <div className="LandingPageFooter">
        <h3>{Translate("shop", "customYourFooter")}</h3>
        <GroupeForm
          id="facebook"
          name="facebook"
          text="Facebook"
          type="text"
          value={facebook}
          workfun={handleFacebookChange}
          style={{ marginTop: "20px" }}
          stclass={
            facebook && !facebookRegex.test(facebook) ? "borderError" : ""
          }
        />
        <GroupeForm
          id="instagram"
          name="instagram"
          text="Instagram"
          type="text"
          value={instagram}
          workfun={handleInstagramChange}
          style={{ marginTop: "20px" }}
          stclass={
            instagram && !instagramRegex.test(instagram) ? "borderError" : ""
          }
        />
        <GroupeForm
          id="tiktok"
          name="tiktok"
          text="Tiktok"
          type="text"
          value={tiktok}
          workfun={handleTiktokChange}
          style={{ marginTop: "20px" }}
          stclass={tiktok && !tiktokRegex.test(tiktok) ? "borderError" : ""}
        />
        <Button
          clName="button"
          BtnText={Translate("shop", "save")}
          onClick={() => {
            Event("SHOP", "SAVE_LANDING_PAGE_FOOTER", "CLICK_EVENT");
            handleSave();
          }}
          style={{
            backgroundColor: "#008AFF",
            color: "white",
            fontSize: "16px",
            width: "140px",
          }}
          disabled={
            !isChange ||
            (facebook && !facebookRegex.test(facebook)) ||
            (instagram && !instagramRegex.test(instagram)) ||
            (tiktok && !tiktokRegex.test(tiktok))
          }
        />
      </div>
    </>
  );
};

export default LandingPageFooter;

import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "../../utils/lang/translate";
import Button from "./Button";
import { CloseSvg, LoadingSmallSvg } from "../StorePages/_Common/IconSvg";
import TabShow from "../StorePages/_Common/TabShow";
import CSS from "csstype";

interface Props {
  title: string;
  text?: string;
  content?: React.ReactElement<any, any>;
  linkTo?: { link: string; callBack: () => void };
  onConfirm?: () => void;
  onClose: () => void;
  loading?: boolean;
  customConfirmText?: string;
  customCloseText?: string;
  disabled?: boolean;
  customConfirmStyle?: CSS.Properties;
  customCloseStyle?: CSS.Properties;
}

const ConfirmationAlert: React.FC<Props> = ({
  title,
  onClose,
  text,
  content,
  linkTo,
  onConfirm,
  loading,
  customConfirmText,
  customCloseText,
  disabled,
  customConfirmStyle,
  customCloseStyle,
}) => {
  const isDisabled = loading || disabled;
  return (
    <TabShow boxStyling={{ padding: "5px 40px", width: "auto" }}>
      <div className="FlWd Inlflx AlgnItm StDrc spcBtwn">
        <div className="StdirLn">
          <h3 className="DlMg">{title}</h3>
        </div>

        <div
          onClick={onClose}
          style={{
            cursor: "pointer",
            pointerEvents: loading ? "none" : "auto",
            opacity: loading ? "0.6" : "1",
            transform: "scale(0.7)",
          }}
        >
          {CloseSvg}
        </div>
      </div>
      <div className="SmMarS2">
        <div>{content ? content : <p className="stfSize">{text}</p>}</div>
        <div className="MrAot" style={{ width: "88%" }}>
          <div
            className="DsplGrd MrAot StMrtp"
            style={{
              gridTemplateColumns: "1fr 1fr",
              gridColumnGap: "30px",
              width: "80%",
            }}
          >
            <Button
              BtnText={
                customCloseText ? customCloseText : Translate("orders", "no")
              }
              danger
              onClick={onClose}
              disabled={loading}
              style={{
                background: "transparent",
                border: "1px solid var(--border-button)",
                color: "var(--fntClr)",
                ...customCloseStyle,
              }}
            />
            {linkTo && (
              <Link to={linkTo.link}>
                <Button
                  BtnText={
                    customConfirmText
                      ? customConfirmText
                      : Translate("orders", "yes")
                  }
                  onClick={linkTo.callBack}
                  style={customConfirmStyle}
                />
              </Link>
            )}
            {onConfirm && (
              <Button
                onClick={() => {
                  if (!isDisabled) onConfirm();
                }}
                BtnText={
                  loading
                    ? LoadingSmallSvg
                    : customConfirmText
                    ? customConfirmText
                    : Translate("orders", "yes")
                }
                disabled={isDisabled}
                style={customConfirmStyle}
              />
            )}
          </div>
        </div>
      </div>
    </TabShow>
  );
};

export default ConfirmationAlert;

import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import {
  DataShop,
  LandingPageSettings,
  UpdateDataShopSettings,
} from "../../../../types";
import { Translate } from "../../../../utils/lang/translate";
import useExitPrompt from "../../../../utils/useExitPrompt";
import Button from "../../../_Common/Button";
import GroupeForm from "../../../_Common/GroupeForm";
import { Event } from "../../_Common";

interface Props {
  dataShop: DataShop;
  UpdateLandingPageSettings: (
    landingPageSettings: LandingPageSettings,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const LandingPageHeader = ({
  dataShop,
  UpdateLandingPageSettings,
  SendAlert,
}: Props) => {
  const [text, setText] = useState<string | undefined>(
    dataShop.landingPageSettings?.header
  );
  const [isChange, setIsChange] = useExitPrompt(false);

  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { value } = event.target as HTMLInputElement;
    setText(value);
    setIsChange(true);
  };
  const handleSave = () => {
    const updatedLandingPageSettings: LandingPageSettings = {
      ...dataShop.landingPageSettings!,
      header: text,
    };
    UpdateLandingPageSettings(updatedLandingPageSettings, dataShop, () => {
      setIsChange(false);
      SendAlert("41", Translate("shop", "changesSaved"));
    });
  };

  return (
    <>
      <Prompt when={isChange} message={Translate("alert", "prompt")} />
      <div className="LandingPageHeader">
        <h3>{Translate("shop", "customYourHeader")}</h3>
        <GroupeForm
          id="text"
          name="text"
          text={Translate("shop", "yourText")}
          type="text"
          value={text}
          workfun={handleChange}
          style={{ marginTop: "20px" }}
        />
        <Button
          clName="button"
          BtnText={Translate("shop", "save")}
          onClick={() => {
            Event("SHOP", "SAVE_LANDING_PAGE_HEADER", "CLICK_EVENT");
            handleSave();
          }}
          style={{
            backgroundColor: "#008AFF",
            color: "white",
            fontSize: "16px",
            width: "140px",
          }}
          disabled={!isChange}
        />
      </div>
    </>
  );
};

export default LandingPageHeader;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { alertActions } from "../../../../actions";
import { DataShop, LandingPageSettings } from "../../../../types";
import "./ShopSettings.scss";
import TableLoader from "../../_Common/TableLoader";
import { shopActions } from "../../../../actions";
import ContainerPage from "../../_Common/ContainerPage";
import { Translate } from "../../../../utils/lang/translate";
import { Link } from "react-router-dom";
import { SimpleFlechRightSvg } from "../../_Common/IconSvg";
import { Event } from "../../_Common";

interface Props {
  history: any;
  location: any;
  dataShop: DataShop;
  shopLoading: boolean;
  GetLandingPageInfo: () => void;
  UpdateLandingPageSettings: (
    landingPageSettings: LandingPageSettings,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
  children: React.ReactElement<any>;
}

const LandingPageContainer: React.FC<Props> = ({
  history,
  location,
  dataShop,
  shopLoading,
  GetLandingPageInfo,
  UpdateLandingPageSettings,
  children,
  SendAlert,
}) => {
  useEffect(() => {
    GetLandingPageInfo();
  }, []);

  useEffect(() => {
    if (dataShop && !dataShop.published) history.push("/shop/settings");
  }, [dataShop]);

  return (
    <ContainerPage
      page_title={
        <div>
          <span className="StOpcVal">{Translate("titles", "shop")}</span>
          <div className="Inlflx StWdDst StOpcVal">
            {Translate("orders", "flech")}
          </div>
          <Link
            to="/shop/settings"
            onClick={() => {
              Event(
                "SHOP",
                `BREADCRUMB_FROM_LANDING_PAGE_SETTINGS_TO_MAIN_SETTINGS`,
                "CLICK_EVENT"
              );
            }}
          >
            <span className="StOpcVal">{Translate("titles", "settings")}</span>
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="CrsPoi">
            {Translate("shop", "landingPageSettings")}
          </span>
        </div>
      }
    >
      {shopLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : (
        dataShop && (
          <div className="LandingPageContainer">
            <div>
              <div className="landingPage-container links">
                <LandingPageLink
                  pathname={location.pathname}
                  to="/shop/settings/header"
                  title={Translate("shop", "header")}
                  eventName="HEADER"
                />
                <LandingPageLink
                  pathname={location.pathname}
                  to="/shop/settings/footer"
                  title={Translate("shop", "footer")}
                  eventName="FOOTER"
                />
                <LandingPageLink
                  pathname={location.pathname}
                  to="/shop/settings/colors"
                  title={Translate("shop", "colors")}
                  eventName="COLORS"
                />
                <LandingPageLink
                  pathname={location.pathname}
                  to="/shop/settings/congratulation"
                  title={Translate("shop", "congratulationMessage")}
                  eventName="CONGRATULATION_MESSAGE"
                />
              </div>
            </div>
            <div>
              <div className="landingPage-container">
                {children &&
                  React.cloneElement(children, {
                    dataShop,
                    UpdateLandingPageSettings,
                    SendAlert,
                  })}
              </div>
            </div>
          </div>
        )
      )}
    </ContainerPage>
  );
};

const LandingPageLink: React.FC<{
  pathname: string;
  to: string;
  title: string;
  eventName: string;
}> = ({ pathname, to, title, eventName }) => {
  const isActive = pathname === to;
  const linkTo = isActive ? "#" : to;
  const activeClass = isActive ? "active" : "";
  return (
    <Link
      to={linkTo}
      className={`link ${activeClass}`}
      onClick={() => {
        Event(
          "SHOP",
          `SIDEBAR_LANDING_PAGE_SETTINGS_${eventName}`,
          "CLICK_EVENT"
        );
      }}
    >
      <p>{title}</p>
      <div className="arrow">{SimpleFlechRightSvg}</div>
    </Link>
  );
};

function mapState(state: any) {
  const { shopLoading, dataShop } = state.shop;
  return { shopLoading, dataShop };
}

const actionCreators = {
  GetShopInfo: shopActions.GetShopInfo,
  GetLandingPageInfo: shopActions.GetLandingPageInfo,
  UpdateLandingPageSettings: shopActions.UpdateLandingPageSettings,
  SendAlert: alertActions.SendAlert,
};

export default connect(mapState, actionCreators)(LandingPageContainer);

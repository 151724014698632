import React, { useState } from "react";
import ElementSide from "../_Common/ElementSide";
import ElementSideDrop from "../_Common/ElementSideStockDropDown";
import {
  ShopSvg,
  BundleSvg,
  DeliveryPricingSvg,
  SettingsSvg,
  LandingPageSvg,
} from "./SideBarSvg";
import { Translate } from "../../../utils/lang/translate";
import { Event } from "../_Common";

interface Props {
  sideState: any;
  pathname: any;
}

const ShopSideBar: React.FC<Props> = ({ sideState, pathname }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  return (
    <div>
      <div
        onClick={() => {
          setIsDropDownOpen((curr) => !curr);
        }}
      >
        <ElementSideDrop
          icon={ShopSvg}
          select={isDropDownOpen}
          reduce={sideState}
          active={
            pathname.indexOf("/shop") != "-1" &&
            pathname.split("/")[2] !== "product"
          }
          text={Translate("sidebar", "shop")}
          new
        />
      </div>
      {isDropDownOpen && (
        <div style={{ marginLeft: "10px" }}>
          <ElementSide
            reduce={sideState}
            icon={LandingPageSvg}
            to={"/shop/landing-page"}
            active={
              pathname.split("/")[1] === "shop" &&
              (!pathname.split("/")[2] ||
                pathname.split("/")[2] === "landing-page")
            }
            text={Translate("shop", "landingPage")}
            clickEvent={() =>
              Event("SHOP", "LANDING_PAGE_SIDEBAR", "CLICK_EVENT")
            }
          />
          <ElementSide
            reduce={sideState}
            icon={BundleSvg}
            to={"/shop/bundle"}
            active={
              pathname.split("/")[1] === "shop" &&
              (!pathname.split("/")[2] || pathname.split("/")[2] === "bundle")
            }
            text={Translate("sidebar", "bundle")}
            clickEvent={() => Event("SHOP", "BUNDLE_SIDEBAR", "CLICK_EVENT")}
          />
          <ElementSide
            reduce={sideState}
            icon={DeliveryPricingSvg}
            to={"/shop/delivery-pricing"}
            active={
              pathname.split("/")[1] === "shop" &&
              pathname.split("/")[2] === "delivery-pricing"
            }
            text={Translate("shop", "deliveryPricing")}
            clickEvent={() =>
              Event("SHOP", "DELIVERY_PRICING_SIDEBAR", "CLICK_EVENT")
            }
          />
          <ElementSide
            icon={SettingsSvg}
            to="/shop/settings"
            active={
              pathname.split("/")[1] === "shop" &&
              pathname.split("/")[2] === "settings"
            }
            reduce={sideState}
            text={Translate("sidebar", "settings")}
            clickEvent={() => Event("SHOP", "SETTINGS_SIDEBAR", "CLICK_EVENT")}
          />
        </div>
      )}
    </div>
  );
};

export default ShopSideBar;

import React, { useState, Fragment, useEffect } from "react";

import Modal from "@mui/material/Modal";

import {
  addIcon,
  copyAndPasteIcon,
  sendIcon,
  CheckSvg,
  deleteIcon,
} from "../_Common/IconSvg";

import "./Webhooks.scss";

import SmallText from "../_Common/SmallText";
import GroupeBtn from "../_Common/GroupeBtn";
import TableLoader from "../_Common/TableLoader";
import { useWebhooksModals } from "./useWebhooksModals";
import AddWebhook from "./popups/AddWebhook/AddWebhook";
import TestWebhook from "./popups/TestWebhook/TestWebhook";
import DeleteWebhook from "./popups/DeleteWebhook/DeleteWebhook";

import Table from "../_Common/Table";

import { connect } from "react-redux";

import { WEBHOOK_HEADER } from "../../constants";

import { ordersActions } from "../../../actions";
import TabShow from "../_Common/TabShow";
import { Translate } from "../../../utils/lang/translate";
import BtnLg from "../_Common/BtnLg";
import { EmptyScreen } from "../_Common/EmptyScreen";

const Webhooks = ({
  GetWebHooks,
  datawebhook,
  gettingwebhook,
  senddatawebhook,
  TestWebHooks,
  postingwebhook,
  datawebhookTypes,
  GetHooksEvents,
  deletingwebhook,
  deletewebhook,
  testingwebhook,
  ...props
}) => {
  const { openModals, handleOpenModals, handleCloseModals } =
    useWebhooksModals();

  const [showPopup, setShowPopup] = useState(false);
  const [idwebhook, setIdwebhhok] = useState();
  const [endpoint, setEndpoint] = useState();

  const [trigger_type_id, setTrigger_type_id] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  useEffect(() => {
    GetHooksEvents();
    GetWebHooks();
  }, []);

  useEffect(() => {
    if (postingwebhook === "1") {
      setIsLoading(true);
    }
  }, []);

  useEffect(() => {
    if (postingwebhook === "3" && senddatawebhook?.response?.status == 400) {
      setIsLoading(false);
    }
  }, [postingwebhook, senddatawebhook]);

  useEffect(() => {
    if (deletingwebhook === "1") {
      setIsLoadingDelete(true);
    }
  }, [deletingwebhook]);

  useEffect(() => {
    if (deletingwebhook === "2") {
      setIsLoadingDelete(false);
    }
  }, [deletingwebhook]);

  const deleteWebhooksFunction = () => {
    props.DeleteWebHooks(idwebhook);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.PostWebHooks(endpoint, trigger_type_id);
    setIsLoading(true);
  };

  let bodytab = [];
  let eventsMap = [];

  eventsMap = !Array.isArray(datawebhookTypes?.results)
    ? []
    : datawebhookTypes?.results.map((elem) => {
        const { id, description, name } = elem;
        return (
          <option key={id} value={id}>
            {`${name}: ${description}`}
          </option>
        );
      });

  bodytab = datawebhook?.ressuc?.results.map((elem) => {
    let actionBtns = [];
    const { triggers, endpoint, id } = elem;

    actionBtns.push({
      type: "BDgInpc",
      action: () => {
        TestWebHooks();
      },
      svgbtn: testingwebhook === "2" ? CheckSvg : sendIcon,
    });
    actionBtns.push({
      type: "BDgInpc",
      action: () => {
        setShowPopup(true);
        if (gettingwebhook == "2") {
          setIdwebhhok(id);
        }
      },
      svgbtn: deleteIcon,
    });
    return [
      <SmallText lengthText={70}>{triggers[0].name}</SmallText>,
      <SmallText lengthText={70}>{endpoint}</SmallText>,
      <GroupeBtn data_btn={actionBtns} />,
    ];
  });

  let Alertfail = "";

  if (senddatawebhook) {
    if (
      senddatawebhook?.response?.status == 400 &&
      typeof senddatawebhook?.response?.data === "object"
    ) {
      Alertfail = Translate("webhooks", "error");
    }
  }

  if (postingwebhook === "2") {
    window.location.reload(false);
    setShowPopup(false);
    setIsLoading(false);
  }

  if (props.deletingwebhook === "1" && deletewebhook?.response?.status == 400) {
    setIsLoadingDelete(true);
  }

  if (deletingwebhook === "2") {
    window.location.reload(false);
  }

  return (
    <article className="webhooksSettings">
      <Modal
        open={openModals?.openAddWebhookModal}
        onClose={handleCloseModals?.handleCloseAddWebhook}
      >
        <AddWebhook
          closeModal={handleCloseModals?.handleCloseAddWebhook}
          value={endpoint}
          onChange={(e) => setEndpoint(e.target.value)}
          onClick={handleSubmit}
          Alertfail={Alertfail}
          isLoading={isLoading}
          eventsMap={eventsMap}
          valuee={trigger_type_id}
          onChangeHook={(e) => setTrigger_type_id(e.target.value)}
        />
      </Modal>
      <Modal
        open={openModals?.openTestWebhookModal}
        onClose={handleCloseModals?.handleCloseTestWebhook}
      >
        <TestWebhook closeModal={handleCloseModals?.handleCloseTestWebhook} />
      </Modal>
      <Modal
        open={openModals?.openDeleteWebhookModal}
        onClose={handleCloseModals?.handleCloseDeleteWebhook}
      >
        <DeleteWebhook
          closeModal={handleCloseModals?.handleCloseDeleteWebhook}
        />
      </Modal>

      <main>
        <article className="webhookTable_container">
          <header>
            <h3>Webhooks</h3>
            <button
              className="addButton"
              onClick={handleOpenModals?.handleOpenAddWebhook}
            >
              {addIcon}
            </button>
          </header>

          <Fragment>
            {testingwebhook === "1" ? (
              <TableLoader />
            ) : (
              <Table thead_elem={[...WEBHOOK_HEADER]} tbody_elem={bodytab} />
            )}
          </Fragment>
        </article>
      </main>
      {showPopup && (
        <TabShow
          tab_title={Translate("profile", "confirmaction")}
          wdth="550px"
          NotCls={false}
        >
          <div className="MrAot" style={{ width: "94%" }}>
            <p className="StdirLn FlWd DltTpMar">
              {Translate("webhooks", "suppWebhooks")}
            </p>
            <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
              <div
                onClick={() => setShowPopup(false)}
                className="StwdMaxC CrsPoi StWdDst"
                style={{ height: "30px" }}
              >
                <BtnLg text={Translate("profile", "cancel")} classSet={""} />
              </div>
              <div
                onClick={deleteWebhooksFunction}
                className="StwdMaxC CrsPoi StWdDst"
                style={{ height: "30px" }}
              >
                <BtnLg
                  text={
                    isLoadingDelete
                      ? Translate("alert", "load")
                      : Translate("webhooks", "suppbtn")
                  }
                  classSet={"TxDng"}
                />
              </div>
            </div>
          </div>
        </TabShow>
      )}
    </article>
  );
};

function mapState(state) {
  const {
    gettingwebhook,
    datawebhook,
    postingwebhook,
    senddatawebhook,
    deletingwebhook,
    deletewebhook,
    testingwebhook,
    sendtestwebhook,
    gettingwebhookTypes,
    datawebhookTypes,
  } = state.orders;

  return {
    gettingwebhook,
    datawebhook,
    postingwebhook,
    senddatawebhook,
    deletingwebhook,
    deletewebhook,
    testingwebhook,
    sendtestwebhook,
    gettingwebhookTypes,
    datawebhookTypes,
  };
}

const actionCreators = {
  GetWebHooks: ordersActions.GetWebHooks,
  DeleteWebHooks: ordersActions.DeleteWebHooks,
  PostWebHooks: ordersActions.PostWebHooks,
  TestWebHooks: ordersActions.TestWebHooks,
  GetHooksEvents: ordersActions.GetHooksEvents,
};

const connectedWebhooks = connect(mapState, actionCreators)(Webhooks);

export { connectedWebhooks as Webhooks };
